import { Component, Input, Output, EventEmitter, OnInit, OnChanges, OnDestroy } from '@angular/core';
import { NgIf, CurrencyPipe, NgFor } from '@angular/common';
import { Subscription, combineLatest } from 'rxjs';

import { IconComponent } from 'cmms-ui';
import { PartSearchItemCardComponent } from '../part-search-item-card/part-search-item-card.component';
import { ShippingDetailsComponent } from '../shipping-details/shipping-details.component';
import { faSparkles } from '@fortawesome/pro-solid-svg-icons';

import { ItemsService } from '../_services/items.service';
import { SearchService } from '../_services/search.service';
import { isMobile } from '../_util/mobile.util';
import { BadgeComponent } from '../badge/badge.component';
import { ItemGroupMatch, SelectedAttributeGroups } from '../_types/attributeGrouping';

@Component({
  selector: 'item-details',
  templateUrl: './item-details.component.html',
  styleUrls: ['./item-details.component.scss'],
  standalone: true,
  imports: [PartSearchItemCardComponent, ShippingDetailsComponent, IconComponent, CurrencyPipe, NgIf, NgFor, BadgeComponent]
})
export class ItemDetailsComponent implements OnInit, OnChanges, OnDestroy {
  public collapsed: boolean = false;
  public itemAttributes: any = [];
  public moreProductDetails: boolean = false;
  public descriptionClamped: boolean = false;
  public bestMatch: any | undefined = {};
  public mainGroupedItemId: number | undefined;
  public isMobile: boolean = false;
  public badgeLabel: string = '';
  public faSparkles = faSparkles;
  public selectedAttributeGroup: SelectedAttributeGroups | undefined;

  private _selectedItemMatch: ItemGroupMatch | undefined;

  public bestMatchSub?: Subscription;
  public mainGroupedItemSub?: Subscription;
  public searchResultsMetaSub?: Subscription;

  @Input() selectedItem;

  @Input()
  set selectedItemMatch(match: ItemGroupMatch | undefined) {
    this._selectedItemMatch = match;
  }
  get selectedItemMatch() {
    return this._selectedItemMatch;
  }

  @Output() itemDetailsChange: EventEmitter<string> = new EventEmitter<string>();

  constructor(public searchService: SearchService, public itemsService: ItemsService) {}

  ngOnInit() {
    this.isMobile = isMobile();

    this.moreItemAttributes();
    this.descriptionClamped = this.selectedItem?.description?.length > 800 ? true : false;
    //don't allow hiding the best match or the main grouped item as hiding either would break the grouping
    this.bestMatchSub = combineLatest([
      this.searchService.bestMatch$,
      this.searchService.selectedAttributeGroups$
    ]).subscribe(([item, attributeGroup]) => {
      this.bestMatch = item;
      this.selectedAttributeGroup = attributeGroup;
    });
    this.mainGroupedItemSub = this.searchService.selectedItem$.subscribe((item) => {
      this.mainGroupedItemId = item?.id;
    });
  }

  ngOnChanges(changes) {
    this.moreItemAttributes();
    if (!this.moreProductDetails) {
      this.collapsed = false;
    }
    this.descriptionClamped = this.selectedItem?.description?.length > 800 ? true : false;

    if (this.selectedAttributeGroup) {
      const group = Object.values(this.selectedAttributeGroup)[0];

      if (this.isMobile) {
        this.badgeLabel = `${this._selectedItemMatch ? this._selectedItemMatch.itemGroupValue : 'Not Found'}`;
      } else {
        this.badgeLabel = `${group.name} \u2022 ${
          this._selectedItemMatch ? this._selectedItemMatch.itemGroupValue : 'Not Found'
        }`;
      }
    }
  }

  ngOnDestroy(): void {
    this.bestMatchSub?.unsubscribe();
    this.mainGroupedItemSub?.unsubscribe();
    this.searchResultsMetaSub?.unsubscribe();
  }

  openItemSite(productUrl: any) {
    this.itemsService.trackClick(this.selectedItem.id, 'productLink').subscribe();
    window.open(productUrl, '_blank');
  }

  showMoreProductDetails() {
    this.collapsed = !this.collapsed;
    const heroSection = document.getElementById('hero-section');
    if (!this.collapsed) {
      if (heroSection) {
        heroSection.style.position = 'sticky';
      }
      return;
    }
    if (heroSection) {
      heroSection.style.position = 'static';
      heroSection.scrollIntoView();
    }
  }

  hideItem(e: Event, item: any) {
    e.stopPropagation();
    this.searchService.hideHeroItem(item);
  }

  moreItemAttributes() {
    this.itemAttributes = [];
    const neededAttributeKeys = {
      manufacturerPartNumber: 'Part Number',
      brandName: 'Brand',
      upc: 'UPC',
      sku: 'SKU',
      unspc: 'UNSPC'
    };
    const neededAttributes = Object.keys(neededAttributeKeys);
    for (let attribute in this.selectedItem) {
      if (
        neededAttributes.includes(attribute) &&
        this.selectedItem[attribute] !== null &&
        this.selectedItem[attribute] !== ''
      ) {
        this.itemAttributes.push({
          attributeName: neededAttributeKeys[attribute],
          attributeValue: this.selectedItem[attribute]
        });
      }
    }
    if (this.itemAttributes.length >= 2) {
      this.moreProductDetails = true;
    } else {
      this.moreProductDetails = false;
    }
  }
}
