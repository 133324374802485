import { NgClass, NgIf, NgTemplateOutlet } from "@angular/common";
import { Component, Input } from "@angular/core";

@Component({
   selector: "lim-ui-basic-button",
   templateUrl: "../templates/basic.html",
   styleUrls: ["../button-styles.scss"],
   standalone: true,
   imports: [NgIf, NgClass, NgTemplateOutlet]
})
export class BasicButtonComponent {
   @Input() public disabled: boolean = false;
   @Input() public href: string = "";
   @Input() public target: string = "";
   @Input() public classNames: string = "";
   @Input() public active: boolean = false;
   @Input() public dataLogLabel: string | undefined;
}
