import type { OnChanges, OnDestroy, SimpleChanges, TemplateRef } from "@angular/core";
import { Directive, ElementRef, HostListener, Input } from "@angular/core";
import { Placement } from "@floating-ui/dom";
import type { TooltipComponent } from "./tooltip.component";
import { TooltipService } from "./tooltip.service";

@Directive({
   selector: "[limUiTooltip]",
   standalone: true
})
export class TooltipDirective implements OnChanges, OnDestroy {
   @Input() limUiTooltip: string | TemplateRef<any> | undefined = "";
   @Input() placement: Placement = "top";
   public tooltip?: TooltipComponent | undefined;

   public constructor(
      private readonly tooltipService: TooltipService,
      private readonly elementRef: ElementRef<HTMLElement>
   ) {}

   public ngOnChanges(changes: SimpleChanges): void {
      if (changes.limUiTooltip) {
         this.limUiTooltip = changes.limUiTooltip.currentValue;
         if (this.tooltip) {
            this.tooltip.tooltip = changes.limUiTooltip.currentValue;
         }
      }
   }

   public ngOnDestroy(): void {
      this.removeTooltip();
   }

   @HostListener("mouseenter")
   public onMouseEnter(): void {
      this.showTooltip();
   }

   @HostListener("mouseleave")
   public onMouseLeave(): void {
      this.removeTooltip();
   }

   @HostListener("click")
   public onClick(): void {
      this.removeTooltip();
   }

   private removeTooltip(): void {
      const activeTooltip = this.tooltipService.getActiveTooltip();
      // In some exceptional cases, because this.tooltipService.activateTooltip is async
      // the tooltip instance may be undefined here. For example, clicking on a button on
      // mobile that has a tooltip. The tooltip is activated on mouseenter (tap start),
      // but then when the click finished (tap end) this function is called. If clicking
      // on the button happens to modify the dom in a way that causes the tooltip to be
      // hostless, then the tooltip instance will be undefined here. If it is undefined,
      // then the activeTooltip should be removed, to prevent sticky tooltips.
      if (this.tooltip === undefined && activeTooltip !== null) {
         this.tooltipService.removeActiveTooltip();
      }
      if (activeTooltip?.instance !== this.tooltip) return;
      if (this.tooltip === undefined || activeTooltip === null) return;
      this.tooltipService.removeActiveTooltip();
      this.tooltip = undefined;
   }

   private async showTooltip(): Promise<void> {
      this.tooltip = await this.tooltipService.activateTooltip(this.limUiTooltip, {
         host: this.elementRef.nativeElement,
         placement: this.placement
      });
   }
}
