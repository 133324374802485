import { Component, OnDestroy, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { NgIf, NgFor, AsyncPipe, NgClass } from '@angular/common';
import { Subscription, combineLatest } from 'rxjs';

import { SearchService } from '../_services/search.service';
import { NotificationService } from '../_services/notification.service';
import { isMobile } from '../_util/mobile.util';

import { LimUiModal, IconComponent, MinimalIconButtonComponent, Aliases } from 'cmms-ui';
import { ItemHiddenNotificationComponent } from '../custom-notifications/item-hidden/item-hidden-notification.component';
import { PartSearchItemCardComponent } from '../part-search-item-card/part-search-item-card.component';
import { FeedbackComponent } from '../feedback-hover/feedback-hover.component';
import { BestMatchResultsComponent } from '../best-match-results/best-match-results.component';
import { DeliveryLocationComponent } from '../delivery-location/delivery-location.component';

import { ItemLoadingAnimation } from '../animations/item-loading-animation/item-loading-animation';
import { SkeletonLoadingBarAnimation } from '../animations/skeleton-loading-bar-animation/skeleton-loading-bar-animation';
import { SkeletonSortAnimation } from '../animations/skeleton-sort-animation/skeleton-sort-animation';

@Component({
  selector: 'part-search-results-grouped',
  templateUrl: './part-search-results-grouped.component.html',
  styleUrls: ['./part-search-results-grouped.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    NgFor,
    AsyncPipe,
    ItemHiddenNotificationComponent,
    PartSearchItemCardComponent,
    FeedbackComponent,
    ItemLoadingAnimation,
    SkeletonLoadingBarAnimation,
    SkeletonSortAnimation,
    BestMatchResultsComponent,
    MinimalIconButtonComponent,
    IconComponent,
    DeliveryLocationComponent
  ]
})
export class PartSearchResultsGroupedComponent implements OnInit, OnDestroy {
  public math = Math;
  public lastQuery: string | null = '';
  public itemHiddenNotification: any | null = null;
  public collapsed: boolean = false;
  public collapsedIcon: Aliases = 'angleDownRegular';
  public removeBestMatch: boolean = false;
  public isMobile: boolean = false;

  public totalItems: number = 0;
  public partNumber: any = null;
  public relevantResultsPresent: boolean = true;
  public searchComplete: boolean = false;
  public partNumberFound: boolean = false;
  public showPartMissing: boolean = false;
  public displayZipCode: string = '';
  public slowVendors: number = 0;

  public hiddenItemNotificationSub?: Subscription;
  public querySub?: Subscription;
  public totalItemsSub?: Subscription;
  public searchResultsSub?: Subscription;
  public searchCompleteSub?: Subscription;
  public searchErrorSub?: Subscription;
  public partNumberFoundSub?: Subscription;
  public queryQualitySub?: Subscription;
  public searchResultsMetaSub?: Subscription;

  constructor(
    public searchService: SearchService,
    public modalService: LimUiModal,
    public notificationService: NotificationService,
    public router: Router
  ) {
    this.hiddenItemNotificationSub = this.notificationService.hiddenItemNotification$.subscribe((itemHiddenNotification) => {
      this.itemHiddenNotification = itemHiddenNotification;
    });

    this.querySub = this.searchService.query$.subscribe((query) => {
      this.lastQuery = query;
    });

    this.totalItemsSub = this.searchService.totalItems$.subscribe((totalItems) => {
      this.totalItems = totalItems;
    });

    this.searchCompleteSub = this.searchService.searchComplete$.subscribe((searchComplete) => {
      this.searchComplete = searchComplete;
    });

    this.partNumberFoundSub = this.searchService.partNumberFound$.subscribe((partNumberFound) => {
      this.partNumberFound = partNumberFound;
    });
  }

  ngOnInit(): void {
    this.isMobile = isMobile();

    this.searchResultsSub = this.searchService.searchResults$.subscribe((searchResults) => {
      const relevantResults = searchResults.filter((item) => item.score > 0);
      this.relevantResultsPresent = true;
      if (this.searchComplete && relevantResults.length === 0) {
        this.relevantResultsPresent = false;
        this.collapsed = true;
      }
    });

    this.searchErrorSub = this.searchService.error$.subscribe((error) => {
      if (error?.status === 'system' || error?.status === 'timeout') {
        this.router.navigate(['/error']);
      }
    });

    this.queryQualitySub = combineLatest([
      this.searchService.queryQuality$,
      this.searchService.partNumberFound$,
      this.searchService.isQueryExpanded$
    ]).subscribe((values) => {
      this.partNumber = values[0]?.facets?.part_number_value;

      let showPartMissing = false;

      if (!values[0]?.facets?.part_number) {
        showPartMissing = false;
      } else if (values[0]?.facets?.part_number && !values[1]) {
        showPartMissing = true;
      }

      // If the query was expanded, we don't want to show the part missing message
      // SKU-535
      if (values[2]) {
        showPartMissing = false;
      }

      this.showPartMissing = showPartMissing;
    });
    this.searchResultsMetaSub = this.searchService.searchResultsMeta$.subscribe((meta) => {
      if (Object.keys(meta).length === 0) return;
      if (this.slowVendors !== 0) return;

      if (meta && meta.vendorCounts) {
        const slowVendors = meta.vendorCounts.filter((vendor) => {
          return !vendor.isFast && vendor.userEnabled && (vendor.status === 'loading' || vendor.itemCount > 0);
        });
        this.slowVendors = slowVendors.length;
      }
    });
  }

  submitFeedback() {
    const instance = this.modalService.open(FeedbackComponent);
  }

  handleCollapserClick() {
    this.collapsed = !this.collapsed;
    this.collapsedIcon = this.collapsed ? 'angleUpRegular' : 'angleDownRegular';
  }

  showIrrelevantResults(collapsed: boolean) {
    this.collapsed = collapsed;
    if (collapsed) {
      this.removeBestMatch = true;
    } else {
      this.removeBestMatch = false;
    }
  }

  ngOnDestroy(): void {
    this.hiddenItemNotificationSub?.unsubscribe();
    this.querySub?.unsubscribe();
    this.totalItemsSub?.unsubscribe();
    this.searchResultsSub?.unsubscribe();
    this.searchCompleteSub?.unsubscribe();
    this.searchErrorSub?.unsubscribe();
    this.partNumberFoundSub?.unsubscribe();
    this.queryQualitySub?.unsubscribe();
    this.searchResultsMetaSub?.unsubscribe();
  }
}
