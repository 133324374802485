<div [ngClass]="{ 'dropdown-spacing': useDropdownStyling }" class="search-bar-wrapper">
   <div class="search-bar-container" [ngClass]="{ 'full-width': fullWidth }">
      <label for="lim-search-input" class="search-icon">
         <lim-ui-icon class="icon-button" icon="magnifyingGlass" iconSize="small" />
      </label>
      <input
         #limSearchInput
         type="textbox"
         ngDefaultControl
         placeholder="{{ placeholder }}"
         [ngModel]="searchVal"
         (ngModelChange)="writeValue($event)"
         (blur)="onTouchedCallback()"
      />
      <span class="clear-search" *ngIf="searchVal?.length" (click)="clearSearch($event)">
         {{ clearText }}
      </span>
   </div>
</div>
