import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from '../_services/auth.service';
import { NotificationService } from '../_services/notification.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { datadogRum } from '@datadog/browser-rum';
import { Aliases } from 'cmms-ui';
import { NgClass, NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { CONFIG } from '../../environments/environment';

@Component({
  selector: 'invite',
  templateUrl: './invite.component.html',
  styleUrls: ['./invite.component.scss'],
  standalone: true,
  imports: [FormsModule, NgClass, NgIf]
})
export class InviteComponent implements OnInit, OnDestroy {
  inviteForm: any = {
    inviteCode: null,
    email: null,
    passInitial: null,
    confirmPass: null
  };
  inviteError: boolean = false;
  errorMsg: string = '';

  activationError: boolean = false;
  passMismatch: boolean = false;
  private loggedInSubscriber?: Subscription;
  private authErrorSubscriber?: Subscription;
  protected eyeSlashIcon: Aliases = 'eyeSlashRegular';
  protected eyeIcon: Aliases = 'eyeRegular';
  constructor(private authService: AuthService, private notificationService: NotificationService, private router: Router) {}

  ngOnInit(): void {
    //Prefill the invite code and email form values if present in the url
    const urlParams = new URLSearchParams(window.location.search);
    const inviteCode = urlParams.get('inviteCode');
    const email = urlParams.get('email');
    if (inviteCode) {
      this.inviteForm.inviteCode = inviteCode;
    }
    if (email) {
      this.inviteForm.email = email;
    }
  }

  ngOnDestroy(): void {
    this.authErrorSubscriber?.unsubscribe();
    this.loggedInSubscriber?.unsubscribe();
  }

  activateAccount(): void {
    const { inviteCode, email, initialPass, confirmPass } = this.inviteForm;

    this.passMismatch = this.inviteForm.passInitial !== this.inviteForm.confirmPass;
    if (this.passMismatch) {
      return;
    }

    this.authService.activateAccount(inviteCode, email, initialPass, confirmPass).subscribe({
      next: (data) => {
        this.inviteError = false;
        this.router.navigate(['/activate']);
      },
      error: (err) => {
        this.inviteError = true;
        this.errorMsg = err.error.message;
      }
    });
  }
}
