<div class="bars">
   <div
      *ngIf="hasVerticalBar"
      class="bar bar_vertical"
      [class.bar_has-horizontal]="hasHorizontalBar"
   >
      <div
         class="thumb"
         [ngClass]="{ thin: thin }"
         draggable="vertical"
         [style.height.%]="verticalSize"
         [style.top.%]="verticalPosition"
         (dragged)="onVertical($event)"
      ></div>
   </div>
   <div
      *ngIf="hasHorizontalBar"
      class="bar bar_horizontal"
      [class.bar_has-vertical]="hasVerticalBar"
   >
      <div
         class="thumb"
         [ngClass]="{ thin: thin }"
         draggable="horizontal"
         [style.width.%]="horizontalSize"
         [style.left.%]="horizontalPosition"
         (dragged)="onHorizontal($event)"
      ></div>
   </div>
</div>
<div class="content" (waResizeObserver)="onScroll()">
   <ng-content></ng-content>
</div>
