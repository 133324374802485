<div class="order">
  <div class="order-header">
    <div class="date-ordered">
      <h3 class="label">Order Saved</h3>
      <div class="value">{{ order.createdAt | date : 'mediumDate' }}</div>
    </div>
    <div class="vendor">
      <h3 class="label">Vendor</h3>
      <div class="value">{{ order.vendor.name }}</div>
    </div>
    <div class="status">
      <label>Status</label>
      <lim-ui-form-dropdown-input [inputString]="orderService.getReadableStatus(order.status)">
        <div menu>
          <lim-ui-dropdown-text-item
            displayString="Open"
            [outputString]="OrderStatus.Open"
            (itemSelectedEvent)="setStatus(OrderStatus.Open)"
          ></lim-ui-dropdown-text-item>
          <lim-ui-dropdown-text-item
            displayString="In Progress"
            [outputString]="OrderStatus.InProgress"
            (itemSelectedEvent)="setStatus(OrderStatus.InProgress)"
          ></lim-ui-dropdown-text-item>
          <lim-ui-dropdown-text-item
            displayString="Closed"
            [outputString]="OrderStatus.Closed"
            (itemSelectedEvent)="setStatus(OrderStatus.Closed)"
          ></lim-ui-dropdown-text-item>
        </div>
      </lim-ui-form-dropdown-input>
    </div>
    <div class="total">
      <h3 class="label">Total</h3>
      <div class="value">{{ order.total | currency }}</div>
    </div>
  </div>
  <div class="order-body">
    <ol class="items">
      <li class="item" *ngFor="let orderItem of order.items">
        <figure>
          <img *ngIf="orderItem.item.mainImageUrl" [src]="orderItem.item.mainImageUrl" class="main-image" />
          <div class="qty">{{ orderItem.qty }}</div>
        </figure>
        <div class="name">
          <a [href]="orderItem.item.productUrl" target="_blank">{{ orderItem.item.title }}</a>
          <div *ngIf="orderItem.savings; else nosavings">
            <div class="price">
              <span class="good">{{ orderItem.total | currency }}</span>
            </div>
            <div class="price" [limUiTooltip]="orderItem.savings['note']">
              <span class="slashed">{{ orderItem.total + orderItem.savings['amount'] | currency }}</span>
              <span class="savings-percent">You saved {{ orderItem.savings['percent'] }}%!</span>
            </div>
          </div>
          <ng-template #nosavings>
            <div #nosavings>
              <div class="price">{{ orderItem.total | currency }}</div>
            </div>
          </ng-template>
        </div>
      </li>
    </ol>
  </div>
  <div class="order-actions">
    <a class="delete-order" (click)="delete()">Delete</a>
    <lim-ui-secondary-button
      (click)="openVendorWebsite()"
      [limUiTooltip]="
        'Open each product page on the vendors website in a separate browser tab. Please allow popups with your browser so all items open properly. 
      NOTE: Prices may have changed since this order was saved.'
      "
      >Open All Item's Vendor Details</lim-ui-secondary-button
    >
    <div class="right">
      <!--- <lim-ui-primary-button class="reorder">Re-order</lim-ui-primary-button> -->
    </div>
  </div>
</div>
