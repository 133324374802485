<ng-template #content>
   <ng-content></ng-content>
</ng-template>

<div *ngIf="href then anchorLayout; else buttonLayout"></div>

<ng-template #anchorLayout>
   <a
      class="lim-ui-button {{ classNames }}"
      [ngClass]="{'active': active}"
      [href]="href"
      [target]="target"
      [attr.data-log]="dataLogLabel"
   >
      <ng-container *ngTemplateOutlet="content" />
   </a>
</ng-template>

<ng-template #buttonLayout>
   <button
      class="lim-ui-button {{ classNames }}"
      [ngClass]="{'active': active}"
      [disabled]="disabled"
      [attr.data-log]="dataLogLabel"
   >
      <ng-container *ngTemplateOutlet="content" />
   </button>
</ng-template>
