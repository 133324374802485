import { CommonModule } from "@angular/common";
import {
   ChangeDetectionStrategy,
   Component,
   ElementRef,
   HostListener,
   Inject,
   Input
} from "@angular/core";
import { ResizeObserverModule } from "@ng-web-apis/resize-observer";
import { DraggableDirective } from "./draggable.directive";

@Component({
   standalone: true,
   imports: [CommonModule, ResizeObserverModule, DraggableDirective],
   selector: "lim-ui-scroll-container",
   templateUrl: "./scroll-container.component.html",
   styleUrls: ["./scroll-container.component.scss"],
   changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScrollContainerComponent {
   @Input() thin: boolean = false;

   public constructor(
      @Inject(ElementRef) public readonly elementRef: ElementRef<HTMLElement>
   ) {}

   protected get verticalScrolled(): number {
      const { scrollTop, scrollHeight, clientHeight } = this.elementRef.nativeElement;
      return scrollTop / (scrollHeight - clientHeight);
   }

   protected get horizontalScrolled(): number {
      const { scrollLeft, scrollWidth, clientWidth } = this.elementRef.nativeElement;
      return scrollLeft / (scrollWidth - clientWidth);
   }

   protected get verticalPosition(): number {
      return this.verticalScrolled * (100 - this.verticalSize);
   }

   protected get horizontalPosition(): number {
      return this.horizontalScrolled * (100 - this.horizontalSize);
   }

   protected get verticalSize(): number {
      const { clientHeight, scrollHeight } = this.elementRef.nativeElement;
      return Math.ceil((clientHeight / scrollHeight) * 100);
   }

   protected get horizontalSize(): number {
      const { clientWidth, scrollWidth } = this.elementRef.nativeElement;
      return Math.ceil((clientWidth / scrollWidth) * 100);
   }

   protected get hasVerticalBar(): boolean {
      return this.verticalSize < 100;
   }

   protected get hasHorizontalBar(): boolean {
      return this.horizontalSize < 100;
   }

   @HostListener("scroll")
   protected onScroll() {
      // This is just to trigger change detection.
   }

   protected onVertical(scrollTop: number) {
      this.elementRef.nativeElement.scrollTop = scrollTop;
   }

   protected onHorizontal(scrollLeft: number) {
      this.elementRef.nativeElement.scrollLeft = scrollLeft;
   }
}
