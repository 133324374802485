<lim-ui-dropdown container="body" [ngClass]="{ disabled: disabled }">
   <div
      class="form-dropdown"
      [ngClass]="{
         'disabled': disabled,
         'has-error': hasError
      }"
      button
   >
      <div
         class="dropdown-string-container"
         [ngClass]="{ 'required-string-container': required == true }"
      >
         <span class="text" [limbleHtml]="inputString"></span>
         <span
            [ngClass]="{
               'caret-and-required-icons': required === true,
               'caret-icon': required === false
            }"
            ><lim-ui-icon icon="angleDown" iconSize="small" /><span
               *ngIf="required == true"
               class="required-marker"
               >*</span
            ></span
         >
      </div>
   </div>
   <div menu>
      <ng-content select="[menu]"></ng-content>
   </div>
</lim-ui-dropdown>
