import { Injectable } from "@angular/core";
import type { Observable } from "rxjs";
import { ToggleElement } from "../../../util/toggleElement.util";

/**
 * This service is meant to be a global singleton that provides a way for components to hide the mobile app footer.
 *
 * @remarks
 * This service supports multiple components trying to hide the mobile app footer. It guarantees that the mobile app footer will only be shown when all components have called show().
 */
@Injectable({ providedIn: "root" })
export class LimUiMobileFooter {
   private readonly toggleElement: ToggleElement;

   public constructor() {
      this.toggleElement = new ToggleElement();
   }

   /**
    * Updates the internal observables value to false if it is not already false.
    *
    * @remarks
    * If no component has subscribed to the internal observable, then nothing will happen when this method is called.
    *
    * @param componentRef - The component reference that is hiding the mobile app footer.
    * @returns void
    */
   public hideFooter(componentRef: any): void {
      this.toggleElement.hide(componentRef);
   }

   /**
    * Updates the internal observable's value to true if no other component has hidden the mobile app footer.
    *
    * @remarks
    * If no component has subscribed to the internal observable, then nothing will happen when this method is called.
    *
    * @remarks
    * show() does not guarantee that the mobile app footer will be shown. It will only show the mobile app footer if there are no other components that have hidden the mobile app footer.
    *
    * @returns void
    */
   public showFooter(): void {
      this.toggleElement.show();
   }

   /**
    * Returns an observable that should be subscribed to for updates on when to show or hide the mobile app footer.
    *
    * @returns Observable<boolean>
    */
   public get footerObservable(): Observable<boolean> {
      return this.toggleElement.observable;
   }
}
