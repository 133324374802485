import { DOCUMENT } from "@angular/common";
import { Inject, Injectable } from "@angular/core";
import type { DropdownComponent } from "./dropdown.component";

@Injectable({ providedIn: "root" })
export class DropdownService {
   private readonly dropdownStack: DropdownComponent[] = [];

   public constructor(@Inject(DOCUMENT) private readonly document: Document) {
      this.document.addEventListener("click", this.handleDocumentClick.bind(this));
      this.document.addEventListener(
         "scroll",
         this.handleDocumentScroll.bind(this),
         true
      );
   }

   /**
    * Adds a dropdown to the stack of open dropdowns, and opens the dropdown.
    * @param dropdown the @link{DropdownComponent} to open.
    */
   public openDropdown(dropdown: DropdownComponent): void {
      if (dropdown?.open()) {
         // This is a hack to get the dropdown to open on the first click. Without this,
         // opening a dropdown will often lead to an immediate closing of the dropdown,
         // because the click event listener closes the dropdown immediately.
         //
         // An alternative solution would be to have the dropdown service keep some sort of
         // counter for each dropdown, and only close the dropdown if the dropdown has been
         // closed twice or something... but that's a lot more complicated and error prone.
         // This 5 millisecond delay should be imperceptible to the user.
         setTimeout(() => {
            this.addDropdownToStack(dropdown);
         }, 5);
      }
   }

   /**
    * This function can be called to close a dropdown and all of its children.
    *
    * @param dropdown the dropdown to close.
    * @returns void
    */
   public closeDropdownAndChildren(dropdown: DropdownComponent): void {
      const index = this.dropdownStack.indexOf(dropdown);
      if (index === -1) {
         return;
      }

      while (index < this.dropdownStack.length) {
         this.closeTopDropdown();
      }
   }

   private closeDropdownChildren(dropdown: DropdownComponent): void {
      const index = this.dropdownStack.indexOf(dropdown);
      if (index === -1) {
         return;
      }

      while (index < this.dropdownStack.length - 1) {
         this.closeTopDropdown();
      }
   }

   private closeDropdowns(): void {
      while (this.dropdownStack.length > 0) {
         this.dropdownStack.pop()?.close();
      }
   }

   private closeTopDropdown(): void {
      const topDropdown = this.dropdownStack.pop();
      if (topDropdown) {
         topDropdown.close();
      }
   }

   private addDropdownToStack(dropdown: DropdownComponent): void {
      this.dropdownStack.push(dropdown);
   }

   private handleDocumentClick(event): void {
      this.cleanUpDropdownStack();
      let clickedDropdown: DropdownComponent | undefined = undefined;
      for (const dropdown of this.dropdownStack) {
         if (dropdown.getDropdownMenuRef().nativeElement.contains(event.target)) {
            clickedDropdown = dropdown;
            break;
         }
      }

      // For a flowchart of the logic in this function, see the flowchart in the
      // Lim UI Dropdowns design doc:
      // https://docs.google.com/document/d/1blUmaY_vz3b3BR1qduMCeHUtvSlPlTac3kaACdqc-5s
      if (clickedDropdown === undefined) {
         // The click was not on a dropdown, close all the dropdowns.
         this.closeDropdowns();
      } else {
         // The click was on an open dropdown.

         if (event.target.closest("[close-dropdown-on-click]")) {
            this.closeDropdownAndChildren(clickedDropdown);
         } else {
            this.closeDropdownChildren(clickedDropdown);
         }
      }
   }

   private handleDocumentScroll(): void {
      this.cleanUpDropdownStack();
      for (const dropdown of this.dropdownStack) {
         dropdown.updatePosition();
      }
   }

   private cleanUpDropdownStack(): void {
      for (const dropdown of this.dropdownStack) {
         if (dropdown.getDropdownMenuRef()?.nativeElement === undefined) {
            // This DropdownComponent has no dropdown element, which means that it was
            // closed without this service knowing about it. This is bad and should not
            // happen. In this case, we should fail gracefully and remove the dropdown
            // from the stack.
            this.dropdownStack.splice(this.dropdownStack.indexOf(dropdown), 1);
         }
      }
   }
}
