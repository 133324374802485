<div class="notification" *ngIf="showMessage">
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9 15.75C6.48438 15.75 4.1875 14.4375 2.92969 12.25C1.67188 10.0898 1.67188 7.4375 2.92969 5.25C4.1875 3.08984 6.48438 1.75 9 1.75C11.4883 1.75 13.7852 3.08984 15.043 5.25C16.3008 7.4375 16.3008 10.0898 15.043 12.25C13.7852 14.4375 11.4883 15.75 9 15.75ZM7.90625 10.9375C7.52344 10.9375 7.25 11.2383 7.25 11.5938C7.25 11.9766 7.52344 12.25 7.90625 12.25H10.0938C10.4492 12.25 10.75 11.9766 10.75 11.5938C10.75 11.2383 10.4492 10.9375 10.0938 10.9375H9.875V8.53125C9.875 8.17578 9.57422 7.875 9.21875 7.875H7.90625C7.52344 7.875 7.25 8.17578 7.25 8.53125C7.25 8.91406 7.52344 9.1875 7.90625 9.1875H8.5625V10.9375H7.90625ZM9 5.25C8.50781 5.25 8.125 5.66016 8.125 6.125C8.125 6.61719 8.50781 7 9 7C9.46484 7 9.875 6.61719 9.875 6.125C9.875 5.66016 9.46484 5.25 9 5.25Z"
      fill="#289E49"
    />
  </svg>

  <p class="title-text">
    Loading completed:
    <span *ngIf="totalItems > 0">
      <strong class="total-items">{{ totalItems }}</strong> new relevant results found.
      <a class="view-now" (click)="selectAllVendors()">View Now</a>
    </span>
    <span *ngIf="totalItems == 0">No new relevant results found.</span>
  </p>

  <svg
    class="close-icon"
    (click)="close()"
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.49609 0.816406L6.5 5.84766L11.5039 0.84375C11.75 0.570312 12.1602 0.570312 12.4336 0.84375C12.6797 1.08984 12.6797 1.5 12.4336 1.74609L7.40234 6.77734L12.4062 11.7812C12.6797 12.0273 12.6797 12.4375 12.4062 12.6836C12.1602 12.957 11.75 12.957 11.5039 12.6836L6.47266 7.67969L1.46875 12.6836C1.22266 12.957 0.8125 12.957 0.566406 12.6836C0.292969 12.4375 0.292969 12.0273 0.566406 11.7539L5.57031 6.75L0.566406 1.74609C0.292969 1.5 0.292969 1.08984 0.566406 0.816406C0.8125 0.570312 1.22266 0.570312 1.49609 0.816406Z"
      fill="#313233"
    />
  </svg>
</div>
