import { Component, OnInit } from '@angular/core';
import { CartService } from '../_services/cart.service';
import { EventBusService } from '../_shared/event-bus.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { FormsModule } from '@angular/forms';
import { NotificationService } from '../_services/notification.service';
import { OrderService } from '../_services/order.service';
import { PrimaryButtonComponent, MinimalIconButtonComponent, IconComponent } from 'cmms-ui';
import { NgIf, AsyncPipe, CurrencyPipe, NgClass } from '@angular/common';
import { faXmarkLarge } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RegisterFormComponent } from '../register-form/register-form.component';
import { LoginFormComponent } from '../login-form/login-form.component';
import { AuthService } from '../_services/auth.service';

@Component({
  selector: 'signup-flyout',
  templateUrl: './signup-flyout.component.html',
  styleUrls: ['./signup-flyout.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    PrimaryButtonComponent,
    AsyncPipe,
    CurrencyPipe,
    MinimalIconButtonComponent,
    FormsModule,
    NgClass,
    IconComponent,
    FontAwesomeModule,
    RegisterFormComponent,
    LoginFormComponent
  ]
})
export class SignupFlyoutComponent implements OnInit {
  public visible: boolean = false;

  registerForm: any = {
    firstName: null,
    lastName: null,
    companyName: null,
    email: null,
    password: null,
    phoneNumber: null
  };

  public loading: boolean = false;
  public passwordVisible: boolean = false;
  public registrationError: boolean = false;
  public errorMsg: string = '';
  public faXmarkLarge: any = faXmarkLarge;
  public loggedInSubscriber?: Subscription;

  constructor(
    public cartService: CartService,
    public eventBusService: EventBusService,
    public notificationService: NotificationService,
    public orderService: OrderService,
    public router: Router,
    public authService: AuthService
  ) {}

  ngOnInit(): void {
    this.eventBusService.on('SignupFlyout.Show', () => {
      this.visible = true;
    });

    this.loggedInSubscriber = this.authService.isLoggedIn$.subscribe((isLoggedIn) => {
      if (isLoggedIn) {
        let user = this.authService.getUser();
        this.authService.initMonitoring(user);
        this.hide();
      }
    });
  }

  hideShowPassword() {
    this.passwordVisible = !this.passwordVisible;
  }

  hide() {
    this.visible = false;
  }

  show() {
    this.visible = true;
  }
}
