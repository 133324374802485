<section>
  <h1>Purchase History</h1>
  <header>
    <div class="search-container">
      <lim-ui-search-box
        placeholder="Search past orders"
        (searchValChange)="setSearchQuery($event)"
        (keydown.enter)="searchOrders()"
        (blur)="searchOrders()"
        clearText="Clear Search"
      ></lim-ui-search-box>
    </div>
    <div class="total-savings-container">
      <div
        class="total-savings"
        *ngIf="(orderService.totalSavings$ | async).allTime"
        [limUiTooltip]="(orderService.totalSavings$ | async).allTimeNote"
      >
        <h2>{{ (orderService.totalSavings$ | async).allTime | currency }}</h2>
        <h4>All time savings!</h4>
      </div>
      <div
        class="total-savings"
        *ngIf="(orderService.totalSavings$ | async).monthToDate"
        [limUiTooltip]="(orderService.totalSavings$ | async).monthToDateNote"
      >
        <h2>{{ (orderService.totalSavings$ | async).monthToDate | currency }}</h2>
        <h4>Saved this month!</h4>
      </div>
    </div>
  </header>
  <div class="container">
    <aside class="filters">
      <h2>Filters</h2>
      <div class="filter-container">
        <orders-filter *ngFor="let field of orderService.fields$ | async" [field]="field"></orders-filter>
      </div>
    </aside>
    <div class="orders-container">
      <div class="orders-header">
        <div *ngIf="orderService.page$ | async as page">
          {{ (orderService.page$ | async).total ?? 0 }} results ({{ page.pageNumber }} of
          {{ math.ceil(page.total / page.pageSize) }} pages)
        </div>
      </div>
      <div
        class="placeholder"
        *ngIf="
          (orderService.status$ | async) === OrderServiceStatus.Loading ||
            (orderService.status$ | async) === OrderServiceStatus.Ready;
          else loaded
        "
      >
        <img src="/assets/images/loading-clear.gif" />
        <h1>Loading orders...</h1>
      </div>
      <ng-template #loaded>
        <div class="orders" *ngIf="(orderService.orders$ | async).length > 0">
          <order *ngFor="let order of orderService.orders$ | async" [order]="order"></order>
        </div>
        <div *ngIf="(orderService.orders$ | async).length === 0">
          <h2>No orders found</h2>
        </div>
        <div
          *ngIf="orderService.page$ | async as page"
          [hidden]="(orderService.status$ | async) !== OrderServiceStatus.ResultsFound"
        >
          <nav class="pagination">
            <lim-ui-pagination
              [maxSize]="7"
              [rotate]="false"
              [page]="page.pageNumber"
              [pageSize]="page.pageSize"
              [collectionSize]="page.total"
              (pageChange)="setPage($event)"
            ></lim-ui-pagination>
            <lim-ui-form-dropdown-input [inputString]="page.pageSize">
              <div menu>
                <lim-ui-dropdown-text-item
                  displayString="10"
                  outputString="10"
                  (itemSelectedEvent)="setPageSize(10)"
                ></lim-ui-dropdown-text-item>
                <lim-ui-dropdown-text-item
                  displayString="25"
                  outputString="25"
                  (itemSelectedEvent)="setPageSize(25)"
                ></lim-ui-dropdown-text-item>
                <lim-ui-dropdown-text-item
                  displayString="50"
                  outputString="50"
                  (itemSelectedEvent)="setPageSize(50)"
                ></lim-ui-dropdown-text-item>
                <lim-ui-dropdown-text-item
                  displayString="100"
                  outputString="100"
                  (itemSelectedEvent)="setPageSize(100)"
                ></lim-ui-dropdown-text-item>
              </div>
            </lim-ui-form-dropdown-input>
            <span>items per page</span>
          </nav>
        </div>
      </ng-template>
    </div>
  </div>
</section>
