import { NgClass, NgIf, NgTemplateOutlet } from "@angular/common";
import { Component, Input } from "@angular/core";
import { IconComponent } from "../../icon/icon.component";
import type { Aliases, Colors } from "../../icon/icon.service";
import { Sizes } from "../../icon/icon.service";
import { BasicButtonComponent } from "../basic-button/basic-button.component";

@Component({
   selector: "lim-ui-minimal-icon-button",
   templateUrl: "../templates/icon.html",
   styleUrls: ["../button-styles.scss", "./minimal-icon-button.component.scss"],
   standalone: true,
   imports: [NgIf, IconComponent, NgClass, NgTemplateOutlet]
})
export class MinimalIconButtonComponent extends BasicButtonComponent {
   /** The name of the icon to display on this button. */
   @Input() public icon: Aliases | undefined;
   /* The size of the icon to display. Defaults to "small" */
   @Input() public iconSize: Sizes = "small";
   @Input() public iconColor: Colors | undefined;
   @Input() public superMinimal?: boolean = false;
   @Input() public darkHoverBackground?: boolean = false;
   @Input() public useSmallMobileTapTarget?: boolean = false;
}
