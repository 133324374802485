import { Component, OnDestroy, OnInit } from '@angular/core';
import { OrderService, OrderServiceStatus } from '../_services/order.service';
import { OrderComponent } from '../order/order.component';
import { OrdersFilterComponent } from '../orders-filter/orders-filter.component';
import { NgIf, NgFor, AsyncPipe, CurrencyPipe } from '@angular/common';
import {
  SearchBoxComponent,
  DropdownButtonComponent,
  SecondaryButtonComponent,
  PaginationComponent,
  DropdownTextItemComponent,
  FormDropdownInputComponent,
  TooltipDirective
} from 'cmms-ui';

@Component({
  selector: 'orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss'],
  standalone: true,
  imports: [
    SearchBoxComponent,
    NgIf,
    NgFor,
    OrdersFilterComponent,
    OrderComponent,
    DropdownButtonComponent,
    SecondaryButtonComponent,
    AsyncPipe,
    CurrencyPipe,
    PaginationComponent,
    DropdownTextItemComponent,
    FormDropdownInputComponent,
    TooltipDirective
  ]
})
export class OrdersComponent implements OnInit, OnDestroy {
  readonly OrderServiceStatus = OrderServiceStatus;
  readonly math = Math;

  constructor(public orderService: OrderService) {}

  ngOnInit(): void {}

  ngAfterContentInit(): void {
    this.orderService.getOrders();
  }

  ngOnDestroy(): void {}

  setPage(pageNumber: any) {
    this.orderService.setPageNumber(pageNumber);
    this.orderService.getOrders();
  }

  setPageSize(pageSize: any) {
    this.orderService.setPageSize(pageSize);
    this.orderService.getOrders();
  }

  setSearchQuery(query: any) {
    this.orderService.setQuery(query);
    if (!query) {
      this.orderService.getOrders();
    }
  }

  searchOrders() {
    this.orderService.getOrders();
  }
}
