import type { ComponentRef } from "@angular/core";
import type { Subscription } from "rxjs";
import { filter } from "rxjs";
import { assert } from "../../util/assert";
import type { LimUiModalComponent } from "./modal-wrapper.component";
import type { ModalEvents } from "./modalEvents.service";

export class LimUiModalRef<Component = unknown, Result = unknown> {
   public result: Promise<Result>;
   public modalCloseEvents: Subscription;
   public modalDismissEvents: Subscription;

   public constructor(
      public readonly componentRef: ComponentRef<LimUiModalComponent<Component>>,
      private readonly modalEvents: ModalEvents,
      public readonly indexInModalStack: number
   ) {
      let resolver: (value: Result | Promise<Result>) => void;
      let rejecter: (reason?: Error) => void;
      this.result = new Promise<Result>((resolve, reject) => {
         resolver = resolve;
         rejecter = reject;
      });
      this.result.then(null, () => {
         //if the promise errors, do nothing.
      });

      this.modalCloseEvents = this.modalEvents.closeEvents$
         .pipe(
            filter(({ modalRef }) => {
               return modalRef === this;
            })
         )
         .subscribe(({ result }) => {
            resolver(result);
         });

      this.modalDismissEvents = this.modalEvents.dismissEvents$
         .pipe(
            filter(({ modalRef }) => {
               return modalRef === this;
            })
         )
         .subscribe(({ reason }) => {
            rejecter(reason);
         });
   }

   public close(result?: Result) {
      this.modalEvents.close(this, result);
   }

   public dismiss(reason?: Error) {
      this.modalEvents.dismiss(this, reason);
   }

   public get componentInstance(): Component {
      assert(this.componentRef.instance.modalContent !== undefined);
      return this.componentRef.instance.modalContent?.instance;
   }
}
