import { Injectable, QueryList } from '@angular/core';
import { HttpClient, HttpHeaders, withInterceptorsFromDi } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { identifierName, isNgTemplate } from '@angular/compiler';
import { CONFIG } from '../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  withCredentials: true
};

const cartUrl = CONFIG.API_URL + 'cart';

@Injectable({
  providedIn: 'root'
})
export class CartService {
  private readonly _cart = new BehaviorSubject<any>(null);
  private readonly _error = new BehaviorSubject<any>(null);
  private readonly _orderCreated = new Subject<void>();

  readonly cart$ = this._cart.asObservable();
  readonly error$ = this._error.asObservable();
  readonly orderCreated$ = this._orderCreated.asObservable();

  constructor(private http: HttpClient) {}

  initCart(): void {
    this.http.post<any>(`${cartUrl}/init`, null).subscribe({
      next: (data) => {
        this._cart.next(data);
      },

      error: (err) => {}
    });
  }

  addItem(qty: Number, item: any): void {
    let cart = this._cart.getValue();

    let cartItem = {
      id: null,
      cartId: cart.id,
      item,
      price: item.price,
      subtotal: item.subtotal,
      total: item.total,
      qty
    };

    cart.items.push(cartItem);

    this._cart.next(cart);

    const requestBody = {
      qty: cartItem.qty,
      itemId: item.id
    };

    this.http.post<any>(`${cartUrl}/${cart.id}/items`, requestBody).subscribe({
      next: (data) => {
        this._cart.next(data);
      },

      error: (err) => {}
    });
  }

  addOrUpdateItem(qty: Number, item: any): void {
    let cart = this._cart.getValue();
    const cartId = cart.id;
    let cartItem = cart.items.find((row: any) => row.item.id == item.id);

    if (cartItem) {
      this.updateItem(cartItem.id, cartItem.qty + qty);
    } else {
      this.addItem(qty, item);
    }
  }

  updateItem(cartItemId: Number, qty: Number): void {
    const cartId = this._cart.getValue().id;
    const requestBody = {
      qty
    };

    this.http.post<any>(`${cartUrl}/${cartId}/items/${cartItemId}`, requestBody).subscribe({
      next: (data) => {
        this._cart.next(data);
      },

      error: (err) => {}
    });
  }

  removeItem(cartItemId: Number): void {
    const cartId = this._cart.getValue().id;

    this.http.delete<any>(`${cartUrl}/${cartId}/items/${cartItemId}`).subscribe({
      next: (data) => {
        this._cart.next(data);
      },

      error: (err) => {}
    });
  }

  removeVendor(vendorId: Number): void {
    const cartId = this._cart.getValue().id;

    this.http.delete<any>(`${cartUrl}/${cartId}/vendors/${vendorId}`).subscribe({
      next: (data) => {
        this._cart.next(data);
      },

      error: (err) => {}
    });
  }

  saveOrder(vendorId: Number): void {
    const cartId = this._cart.getValue().id;

    this.http
      .post<any>(`${cartUrl}/${cartId}/order`, {
        vendorId
      })
      .subscribe({
        next: (data) => {
          this._cart.next(data);
          this._orderCreated.next();
        },

        error: (err) => {}
      });
  }
}
