<div
   class="content-container scroll-height-inheritance"
   [ngClass]="{
      'modal-watermark': showWatermark,
      'completed-background': completed,
      'small': small,
      'extra-large': extraLarge,
      'single-component-full-width': singleComponent
   }"
>
   <ng-content></ng-content>
</div>
