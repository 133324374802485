<div class="main-container">
  <div class="child-container">
    <div class="login-cover-image">
      <div class="image-text">Confidently find the cheapest part in a fraction of the time</div>
    </div>
  </div>
  <div class="child-container">
    <div class="invite-form">
      <h1 class="invite-banner">Activate Account</h1>
      <form name="inviteForm" (ngSubmit)="f.form.valid && activateAccount()" #f="ngForm" novalidate>
        <div class="form-group">
          <label for="email" class="input-labels">Email Address</label>
          <input
            type="text"
            class="form-control"
            name="email"
            [(ngModel)]="inviteForm.email"
            required
            #email="ngModel"
            [ngClass]="{ 'is-invalid': f.submitted && email.errors }"
          />
          <div *ngIf="email.errors && f.submitted" class="invalid-feedback">Email is required!</div>
        </div>
        <div class="form-group">
          <label for="invitationCode">Enter Invitation Code</label>
          <input
            type="text"
            class="form-control"
            name="invitationCode"
            [(ngModel)]="inviteForm.inviteCode"
            required
            #inviteCode="ngModel"
            [ngClass]="{ 'is-invalid': f.submitted && inviteForm.errors }"
          />
          <div class="invalid-feedback" *ngIf="inviteCode.errors && f.submitted">
            <div *ngIf="inviteCode.errors">Invitation Code is required!</div>
          </div>
        </div>
        <div class="form-group">
          <label for="passInitial">Password</label>
          <input
            type="password"
            class="form-control"
            name="passInitial"
            [(ngModel)]="inviteForm.passInitial"
            required
            #passInitial="ngModel"
            pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_]).{8,}"
            [ngClass]="{ 'is-invalid': f.submitted && passInitial.errors }"
          />
          <div class="invalid-feedback" *ngIf="passInitial.errors && f.submitted">
            <div *ngIf="passInitial.errors['required']">Password is required!</div>
            <div *ngIf="passInitial.errors['pattern']">
              Password must be at least 8 characters, contain an uppercase, lowercase, number, and special character.
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="confirmPass">Confirm Password</label>
          <input
            type="password"
            class="form-control"
            name="confirmPass"
            [(ngModel)]="inviteForm.confirmPass"
            required
            #confirmPass="ngModel"
            pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_]).{8,}"
            [ngClass]="{ 'is-invalid': f.submitted && (confirmPass.errors || passMismatch) }"
          />
          <div class="invalid-feedback" *ngIf="(confirmPass.errors && f.submitted) || passMismatch">
            <div *ngIf="confirmPass.errors && confirmPass.errors['required']">Confirmation Password is required!</div>
            <div *ngIf="passMismatch">Passwords do not match!</div>
          </div>
        </div>
        <div class="error-container" *ngIf="inviteError">
          {{ errorMsg }}
        </div>
        <button type="submit" class="main-page-btn">Submit</button>
      </form>
    </div>
  </div>
</div>
