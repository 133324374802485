import { DecimalPipe, NgClass, NgFor, NgIf } from "@angular/common";
import type { OnChanges, OnInit } from "@angular/core";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { IconComponent } from "../icon/icon.component";

@Component({
   selector: "lim-ui-pagination",
   templateUrl: "./pagination.component.html",
   styleUrls: ["./pagination.component.scss"],
   standalone: true,
   imports: [NgIf, NgClass, IconComponent, NgFor, DecimalPipe]
})
export class PaginationComponent implements OnInit, OnChanges {
   /** The maximum number of pages to show. */
   @Input() public maxSize: number = 5;
   /** Whether to rotate pages when maxSize > number of pages. */
   @Input() public rotate: boolean = true;
   /** The current page. */
   @Input() public page: number = 1;
   /** The number of items per page. */
   @Input() public pageSize: number = 10;
   /** The numer of items in your paginated collection. */
   @Input() public collectionSize: number = 0;
   /** Optional class to control how the pagination is styled. */
   @Input() public paginationClass?: "tile-pagination" | "" = "";
   /** Event that's emitted on page change. */
   @Output() public readonly pageChange = new EventEmitter();

   public pages: number = 0;

   public ngOnInit() {
      this.computePages();
   }

   public ngOnChanges(): void {
      this.computePages();
   }

   public computePages() {
      this.pages = Math.ceil(this.collectionSize / this.pageSize);
   }

   public changePage(page: number) {
      this.page = Math.ceil(page);
      this.pageChange.emit(this.page);
   }

   public incrementPage() {
      if (this.page === this.pages) {
         this.page = 1;
      } else {
         this.page++;
      }
      this.pageChange.emit(this.page);
   }

   public decrementPage() {
      if (this.page === 1) {
         this.page = this.pages;
      } else {
         this.page--;
      }
      this.pageChange.emit(this.page);
   }
}
