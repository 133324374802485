import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { NgClass, NgIf, NgFor, AsyncPipe, NgStyle, Location } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { combineLatest, Subscription } from 'rxjs';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { SearchService } from '../_services/search.service';
import { isMobile } from '../_util/mobile.util';
import { faFilterList, faChevronDown, faChevronUp, faLockKeyhole } from '@fortawesome/pro-regular-svg-icons';
import { faSparkles } from '@fortawesome/pro-solid-svg-icons';

import { PartSearchResultsGroupedComponent } from '../part-search-results-grouped/part-search-results-grouped.component';
import { FilterComponent } from '../filter/filter.component';
import {
  SearchBoxComponent,
  CheckboxComponent,
  IconComponent,
  TooltipDirective,
  IconButtonComponent,
  MinimalIconButtonComponent,
  DropdownButtonComponent,
  DropdownTextItemComponent,
  SecondaryButtonComponent
} from 'cmms-ui';
import { RadioButtonComponent } from '../custom-lim-ui-components/radio-button/radio-button.component';
import { CustomPanelComponent } from '../custom-lim-ui-components/panel/panel.component';
import { PartSearchFieldComponent } from '../part-search-field/part-search-field.component';
import { DeliveryLocationComponent } from '../delivery-location/delivery-location.component';
import { SkeletonLoadingBarAnimation } from '../animations/skeleton-loading-bar-animation/skeleton-loading-bar-animation';
import { SkeletonSortAnimation } from '../animations/skeleton-sort-animation/skeleton-sort-animation';
import { DropdownComponent } from 'designSystems/cmms-ui/src/public-api';
import { EventBusService } from '../_shared/event-bus.service';
import { EventData } from '../_shared/event.class';
import { AuthService } from '../_services/auth.service';
import { FeatureService } from '../_services/feature.service';
import { AttributeGroup, SelectedAttributeGroups } from '../_types/attributeGrouping';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    NgIf,
    NgStyle,
    NgFor,
    AsyncPipe,
    SearchBoxComponent,
    FormsModule,
    FilterComponent,
    PartSearchResultsGroupedComponent,
    CheckboxComponent,
    IconComponent,
    TooltipDirective,
    IconButtonComponent,
    SkeletonLoadingBarAnimation,
    CustomPanelComponent,
    PartSearchFieldComponent,
    DeliveryLocationComponent,
    FontAwesomeModule,
    MinimalIconButtonComponent,
    DropdownButtonComponent,
    DropdownTextItemComponent,
    SkeletonSortAnimation,
    SecondaryButtonComponent,
    DropdownComponent,
    RadioButtonComponent
  ]
})
export class HomeComponent implements OnInit, OnDestroy {
  Math: any = Math;

  public isLoggedIn: boolean = false;
  public searchQuery: string = '';
  public fields: Array<any> = [];
  public searchedFields: Array<any> = [];
  public fieldSearchQuery: string = '';
  public totalItems: number = 0;
  public globalMinPrice: number | null = null;
  public globalMaxPrice: number | null = null;
  public minPrice: number | null = null;
  public maxPrice: number | null = null;
  public searchResultsMeta: object | any = {};
  public vendorCounts: Array<any> = [];
  public fastVendorCounts: Array<any> = [];
  public slowVendorCounts: Array<any> = [];
  public hiddenVendorCounts: Array<any> = [];
  public selectedVendors: any = {};
  public dataSourcesLoading: Array<any> = [];
  public allVendorsSelected: boolean = false;
  public percentComplete: number = 0;
  public showLoadingBar: boolean = false;
  public isMobile: boolean = false;
  public faFilterList = faFilterList;
  public faSparkles = faSparkles;
  public searchModalState: boolean = false;
  public showHiddenVendors: boolean = false;
  public isMarketingMode: boolean = false;
  public sortByLabels: any = {
    'price_per:asc': 'Price (Low to High)',
    'price_per:desc': 'Price (High to Low)',
    relevance: 'Relevance'
  };
  public sortBy: string = 'relevance';
  public showSortFilter: boolean = false;
  public showSignupBanner: boolean = true;
  public isItemsRestricted: boolean = false; // Used to hide/lock items when user is not logged in and in marketing mode

  public faLockKeyhole: any = faLockKeyhole;
  public faChevronDown = faChevronDown;
  public faChevronUp = faChevronUp;
  public showSmartGroupings: boolean = false;

  public attributeGroups: AttributeGroup[] = [];
  public selectedAttributeGroups?: SelectedAttributeGroups = {};

  public querySub?: Subscription;
  public percentCompleteSub?: Subscription;
  public searchCompleteSub?: Subscription;
  public totalItemsSub?: Subscription;
  public filtersSub?: Subscription;
  public searchResultsMetaSub?: Subscription;
  public filteredSearchResultsMetaSub?: Subscription;
  public selectedDetailsPageItemSub?: Subscription;
  public hiddenItemsSub?: Subscription;
  public sortBySub?: Subscription;
  public loggedInSub?: Subscription;
  public isMarketingSub?: Subscription;
  public isItemsRestrictedSub?: Subscription;
  public selectedAttributeGroupsSub?: Subscription;
  public featureSub?: Subscription;
  public routerSub?: Subscription;

  // This is called when the user clinks the View All link in the toast notification after a search is complete
  @HostListener('window:SelectAllVendors', ['$event'])
  selectAllVendorsHandler(event: any) {
    this.selectAllVendors();
  }

  constructor(
    public searchService: SearchService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    public eventBus: EventBusService,
    private authService: AuthService,
    private featureService: FeatureService,
    private location: Location
  ) {
    //deep-linking use case
    this.startSearchFromUrl();
  }

  clickSignupBanner() {
    if (this.isMobile) {
      this.router.navigate(['/register', { return: this.router.url }]);
    } else {
      this.eventBus.emit(new EventData('SignupFlyout.Show', {}));
    }
  }

  closeSignupBanner() {
    this.showSignupBanner = false;
  }

  ngOnInit(): void {
    this.isMobile = isMobile();

    combineLatest([this.authService.isLoggedIn$, this.route.params]).subscribe(([isLoggedIn, params]) => {
      this.isLoggedIn = isLoggedIn;
      if (typeof params.searchHistoryId !== 'undefined') {
        this.searchService._isPastResult.next(true);
        this.searchService.getLatestResults(params.searchHistoryId);
      }

      if (typeof params.searchSlug !== 'undefined') {
        this.searchService._isMarketingMode.next(true);
        this.searchService._isPastResult.next(true);
        this.searchService.getLatestResults(params.searchSlug);
      }

      if (this.searchService._isMarketingMode.getValue() && !this.isLoggedIn) {
        this.searchService._isItemsRestricted.next(true);
      } else {
        this.searchService._isItemsRestricted.next(false);
      }
    });

    this.loggedInSub = this.authService.isLoggedIn$.subscribe((isLoggedIn) => {
      this.isLoggedIn = isLoggedIn;

      if (!this.isLoggedIn) {
        this.searchService._isItemsRestricted.next(true);
      } else {
        this.searchService._isItemsRestricted.next(false);
      }

      if (isLoggedIn) {
        this.showSignupBanner = false;
      }
    });

    this.isItemsRestrictedSub = this.searchService._isItemsRestricted.subscribe((isItemsRestricted) => {
      this.isItemsRestricted = isItemsRestricted;
    });

    this.isMarketingSub = this.searchService._isMarketingMode.subscribe((isMarketingMode) => {
      this.isMarketingMode = isMarketingMode;

      if (!this.isLoggedIn && this.isMarketingMode) {
        this.searchService._isItemsRestricted.next(true);
      } else {
        this.searchService._isItemsRestricted.next(false);
      }
    });

    // get the current search query and add it to the url
    this.querySub = this.searchService.query$.subscribe((query) => {
      if (query === this.searchQuery) return;
      this.searchQuery = query;
      this.updateURL();
    });

    this.percentCompleteSub = this.searchService.percentComplete$.subscribe((percent) => {
      this.percentComplete = percent;
    });

    this.searchCompleteSub = this.searchService.searchComplete$.subscribe((searchComplete) => {
      if (searchComplete) {
        // Delay hiding the loading bar so we allow the css animation to fade out
        setTimeout(() => {
          this.showLoadingBar = false;
        }, 2000);
      } else {
        this.showLoadingBar = true;
      }

      this.checkAllVendorsSelected();
    });

    // get the total number of items in the current search results
    this.totalItemsSub = this.searchService.totalItems$.subscribe((totalItems) => {
      this.totalItems = totalItems;
    });

    this.filtersSub = this.searchService.filters$.subscribe((filters) => {
      if (filters && typeof filters.vendorId !== 'undefined') {
        this.selectedVendors = {};

        let selectedCount = 0;
        filters.vendorId.values.forEach((vendorId) => {
          this.selectedVendors[vendorId] = true;
          selectedCount++;
        });

        this.checkAllVendorsSelected();
      }
    });

    // get meta info about the current search results, use it to build local vendorCounts object
    this.searchResultsMetaSub = this.searchService.searchResultsMeta$.subscribe((meta) => {
      if (Object.keys(meta).length === 0) return;

      this.vendorCounts = meta.vendorCounts;
      this.attributeGroups = meta.attributeGroups;
      // the first record will always be the most populated. groups are ordered by count in descending order
      if (this.attributeGroups.length > 0) {
        this.searchService.selectedAttributeGroups.next({ [meta.attributeGroups[0].id]: meta.attributeGroups[0] });
      }
      this.fastVendorCounts = meta.vendorCounts.filter((vendor) => {
        return vendor.isFast && vendor.userEnabled && vendor.status !== 'loading' && vendor.itemCount > 0;
      });

      this.fastVendorCounts.sort((a, b) => a.vendorName.localeCompare(b.vendorName));

      this.slowVendorCounts = meta.vendorCounts.filter((vendor) => {
        return !vendor.isFast && vendor.userEnabled && (vendor.status === 'loading' || vendor.itemCount > 0);
      });

      this.slowVendorCounts.sort((a, b) => a.vendorName.localeCompare(b.vendorName));

      this.hiddenVendorCounts = meta.vendorCounts.filter((vendor) => {
        return !vendor.userEnabled && (vendor.status === 'loading' || vendor.itemCount > 0);
      });

      this.hiddenVendorCounts.sort((a, b) => a.vendorName.localeCompare(b.vendorName));

      this.globalMinPrice = meta.globalLowestPrice;
      this.globalMaxPrice = meta.globalHighestPrice;

      this.checkAllVendorsSelected();
    });

    this.selectedAttributeGroupsSub = this.searchService.selectedAttributeGroups$.subscribe((selectedAttributeGroups) => {
      this.selectedAttributeGroups = selectedAttributeGroups;
    });

    this.featureSub = this.featureService.features$.subscribe(() => {
      this.showSmartGroupings = this.featureService.enabled('smart-groupings');
    });

    // get meta info about the current FILTERED search results, use it to update local vendorCounts object
    this.filteredSearchResultsMetaSub = this.searchService.filteredSearchResultsMeta$.subscribe((filteredMeta) => {
      // update this.vendorCounts with count from filteredMeta
      if (this.searchService._isPreviewing.getValue() && filteredMeta.vendorCounts && filteredMeta.vendorCounts.length > 0) {
        this.vendorCounts.forEach((item) => {
          item.itemCount = filteredMeta.vendorCounts.find(
            (filteredItem: any) => filteredItem.vendorId == item.vendorId
          )?.itemCount;
        });
      }

      this.globalMinPrice = filteredMeta.globalLowestPrice;
      this.globalMaxPrice = filteredMeta.globalHighestPrice;
    });

    this.selectedDetailsPageItemSub = this.searchService.selectedDetailsPageItem$.subscribe((item) => {
      if (!item || typeof item.id === 'undefined' || !item.id) {
        return;
      }

      //scroll to item if navigating from details page
      const itemTarget = `item${item.id}`;
      setTimeout(() => {
        const itemRef = document.getElementById(itemTarget);
        itemRef?.scrollIntoView({ behavior: 'instant', block: 'nearest' });
      }, 700);
    });

    this.searchService.searchModalState$.subscribe((state) => {
      this.searchModalState = state;
    });

    this.sortBySub = this.searchService.sortBy$.subscribe((sortBy) => {
      this.sortBy = sortBy;
      this.updateURL();
    });
  }

  checkItemsViewed(): void {
    if (!this.searchService._searchComplete.getValue() && !this.searchService._isPreviewing.getValue()) {
      return;
    }

    // Track which items are being viewed
    // Delay the event to make sure item cards are all rendered
    setTimeout(() => {
      let event = new CustomEvent('itemView');
      window.dispatchEvent(event);
    }, 2000);
  }

  checkAllVendorsSelected(): void {
    let selectedVendors = Object.keys(this.selectedVendors).length;
    let availableVendors = this.vendorCounts.filter((vendor) => vendor.itemCount > 0 && vendor.userEnabled).length;
    this.allVendorsSelected = selectedVendors === availableVendors;
    this.checkItemsViewed();
  }

  ngOnDestroy(): void {
    this.querySub?.unsubscribe();
    this.percentCompleteSub?.unsubscribe();
    this.searchCompleteSub?.unsubscribe();
    this.totalItemsSub?.unsubscribe();
    this.filtersSub?.unsubscribe();
    this.searchResultsMetaSub?.unsubscribe();
    this.filteredSearchResultsMetaSub?.unsubscribe();
    this.selectedDetailsPageItemSub?.unsubscribe();
    this.hiddenItemsSub?.unsubscribe();
    this.sortBySub?.unsubscribe();
    this.isMarketingSub?.unsubscribe();
    this.loggedInSub?.unsubscribe();
    this.selectedAttributeGroupsSub?.unsubscribe();
    this.featureSub?.unsubscribe();
    this.routerSub?.unsubscribe();
  }

  startSearchFromUrl(): void {
    // If a search is already in progress, or we are viewing past results, do not start a new search
    if (this.searchService.getIsSearching() || this.searchService._isPastResult.getValue()) {
      return;
    }

    this.routerSub = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const currentNavigation = this.router.getCurrentNavigation();
        const previousNav = currentNavigation?.previousNavigation;
        const extractedUrl = currentNavigation?.extractedUrl;
        let queryPresent = false;
        let queryParams;
        if (extractedUrl && extractedUrl.queryParams) {
          const q = extractedUrl.queryParams.q;
          queryParams = extractedUrl.queryParams;
          if (q && q.length > 0) {
            queryPresent = true;
          }
        }
        if (previousNav === null && queryPresent) {
          const { q, sort, 'price-min': priceMin, 'price-max': priceMax } = queryParams;

          if (priceMin) {
            this.minPrice = Number(priceMin);
          }

          if (priceMax) {
            this.maxPrice = Number(priceMax);
          }

          if (priceMin || priceMax) {
            this.filterByPrice();
          }

          if (sort) {
            this.updateSortBy(sort);
          }

          this.searchService.setSearchQuery(q);
          this.router.navigate(['/searching'], { skipLocationChange: true, replaceUrl: true });
        }
      }
    });
  }

  forceInteger(event: any): void {
    event.target.value = parseInt(event.target.value, 10) || '';
  }

  updateSelectedVendors(id: number): void {
    if (this.isItemsRestricted) {
      this.clickSignupBanner();
      return;
    }

    const current = { ...this.selectedVendors };
    const idStr = id.toString();

    if (current.hasOwnProperty(idStr)) {
      current[idStr] = !current[idStr];
    } else {
      current[idStr] = true;
    }

    this.selectedVendors = current;
    this.filterByVendor();
  }

  updateSelectedAttributGroups(attributeGroup: AttributeGroup) {
    const updatedAttributeGroups: SelectedAttributeGroups = {
      [attributeGroup.id]: attributeGroup
    };

    this.searchService.selectedAttributeGroups.next(updatedAttributeGroups);
    this.searchService.processResults();
  }

  onlyVendor(id: number): void {
    if (this.isItemsRestricted) {
      this.clickSignupBanner();
      return;
    }
    this.selectedVendors = {};
    this.updateSelectedVendors(id);
  }

  selectAllVendors(): void {
    if (this.isItemsRestricted) {
      this.clickSignupBanner();
      return;
    }

    this.vendorCounts.forEach((vendor) => {
      if (vendor.userEnabled && vendor.status !== 'loading' && vendor.itemCount > 0) {
        this.selectedVendors[vendor.vendorId] = true;
      }
    });
    this.allVendorsSelected = true;
    this.filterByVendor();
  }

  deselectAllVendors(): void {
    if (this.isItemsRestricted) {
      this.clickSignupBanner();
      return;
    }

    this.vendorCounts.forEach((vendor) => {
      if (vendor.userEnabled && vendor.status !== 'loading' && vendor.itemCount > 0) {
        this.selectedVendors[vendor.vendorId] = false;
      }
    });
    this.allVendorsSelected = false;
    this.filterByVendor();
  }

  replaceSelectedVendors(id: number) {
    const current = { ...this.selectedVendors };
    for (let vendorId in current) {
      if (vendorId === id.toString()) {
        current[vendorId] = true;
      } else {
        current[vendorId] = false;
      }
    }
    this.selectedVendors = current;
    this.filterByVendor();
  }

  included(id: number) {
    return this.selectedVendors.includes(id);
  }

  async filterByVendor() {
    const vendorFilter = {
      field: 'vendorId',
      values: [] as Number[],
      operator: 'includes'
    };
    for (let vendorId in this.selectedVendors) {
      if (this.selectedVendors[vendorId]) {
        vendorFilter.values.push(Number(vendorId));
      }
    }
    await this.searchService.addFilter(vendorFilter);
    this.searchService.processResults();
  }

  updateURL(): void {
    if (this.searchService._isPastResult.getValue()) {
      return;
    }

    const queryParams: any = {
      q: this.searchQuery
    };

    if (this.minPrice) {
      queryParams['price-min'] = this.minPrice;
    }
    if (this.maxPrice) {
      queryParams['price-max'] = this.maxPrice;
    }

    queryParams['sort'] = this.sortBy;
    const queryParamsString = new URLSearchParams(queryParams).toString();
    this.location.replaceState('/results', queryParamsString);
  }

  async filterByPrice() {
    if (this.isItemsRestricted) {
      this.clickSignupBanner();
      return;
    }

    const priceFilter = {
      field: 'pricePer',
      values: [] as Number[],
      operator: 'range'
    };
    this.minPrice === null ? priceFilter.values.push(-Infinity) : priceFilter.values.push(this.minPrice);
    this.maxPrice === null ? priceFilter.values.push(Infinity) : priceFilter.values.push(this.maxPrice);
    this.updateURL();
    await this.searchService.addFilter(priceFilter);
    this.searchService.processResults();
  }

  updateSortBy(sortBy: string): void {
    if (this.isItemsRestricted) {
      this.clickSignupBanner();
      return;
    }

    this.searchService.setSortBy(sortBy);
    this.searchService.processResults();
  }
  getSortByLabel() {
    if (this.sortBy === null) {
      return this.sortByLabels['relevance'];
    }
    return this.sortByLabels[this.sortBy];
  }

  sortResults(value: any) {
    this.searchService.setSortBy(value);
    this.searchService.processResults();
  }

  toggleSortAndFilter() {
    if (this.isItemsRestricted) {
      this.clickSignupBanner();
      return;
    }
    this.showSortFilter = !this.showSortFilter;
  }
}
