import { Injectable } from "@angular/core";
import type { Observable } from "rxjs";
import { isMobile } from "../../../util/mobile.util";
import { ToggleElement } from "../../../util/toggleElement.util";

/**
 * This service is meant to be a global singleton that provides a way for components to hide the mobile app header.
 *
 * @remarks
 * This service supports multiple components trying to hide the mobile app header. It guarantees that the mobile app header will only be shown when all components have called show().
 */
@Injectable({ providedIn: "root" })
export class LimUiMobileHeader {
   private readonly toggleElement: ToggleElement;

   public constructor() {
      this.toggleElement = new ToggleElement();
   }

   /**
    * Updates the internal observables value to false if it is not already false and the user is on a mobile device.
    *
    * @remarks
    * If no component has subscribed to the internal observable, then nothing will happen when this method is called.
    *
    * @param componentRef - The component reference that is hiding the mobile app header.
    * @returns void
    */
   public hideHeader(componentRef: any): void {
      if (!isMobile()) {
         return;
      }

      this.toggleElement.hide(componentRef);
   }

   /**
    * Updates the internal observable's value to true if no other component has hidden the mobile app header.
    *
    * @remarks
    * If no component has subscribed to the internal observable, then nothing will happen when this method is called.
    *
    * @remarks
    * show() does not guarantee that the mobile app header will be shown. It will only show the mobile app header if there are no other components that have hidden the mobile app header.
    *
    * @returns void
    */
   public showHeader(): void {
      this.toggleElement.show();
   }

   /**
    * Returns an observable that should be subscribed to for updates on when to show or hide the mobile app header.
    *
    * @returns Observable<boolean>
    */
   public get headerObservable(): Observable<boolean> {
      return this.toggleElement.observable;
   }
}
