import { NgClass } from "@angular/common";
import type { TemplateRef } from "@angular/core";
import { Component } from "@angular/core";
import { LimbleHtmlDirective } from "../directives/limbleHtml.directive";

@Component({
   selector: "lim-ui-tooltip",
   templateUrl: "./tooltip.component.html",
   styleUrls: ["./tooltip.component.scss"],
   standalone: true,
   imports: [NgClass, LimbleHtmlDirective]
})
export class TooltipComponent {
   public tooltip: string | TemplateRef<any> | undefined = "";
   public show: boolean = false;

   public close() {
      this.show = false;
   }

   public open() {
      if (this.tooltip !== "") {
         this.show = true;
      }
   }
}
