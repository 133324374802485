import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { OrderService, OrderServiceStatus, OrderStatus } from '../_services/order.service';
import { NotificationService } from '../_services/notification.service';
import { NgFor, NgIf, CurrencyPipe, DatePipe } from '@angular/common';
import {
  SecondaryButtonComponent,
  PrimaryButtonComponent,
  FormDropdownInputComponent,
  DropdownTextItemComponent,
  TooltipDirective
} from 'cmms-ui';

@Component({
  selector: 'order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss'],
  standalone: true,
  imports: [
    SecondaryButtonComponent,
    NgFor,
    NgIf,
    PrimaryButtonComponent,
    CurrencyPipe,
    DatePipe,
    FormDropdownInputComponent,
    DropdownTextItemComponent,
    TooltipDirective
  ]
})
export class OrderComponent implements OnInit, OnDestroy {
  @Input() order: any;
  readonly OrderStatus = OrderStatus;

  constructor(public orderService: OrderService, public notificationService: NotificationService) {}

  ngOnInit(): void {}

  ngAfterContentInit(): void {
    //this.orderService.getOrders();
  }

  ngOnDestroy(): void {}

  openVendorWebsite(): void {
    let popupsBlocked = false;

    this.order.items.forEach((orderItem: any) => {
      const windowKey = 'vendor_item_' + orderItem.item.id;
      let tab = window.open(orderItem.item.productUrl, windowKey);

      if (!tab) {
        popupsBlocked = true;
      }
    });

    if (popupsBlocked) {
      alert('Unable to open all vendor item windows. Please enable popups for this website.');
    }
  }

  setStatus(status: string): void {
    this.orderService.updateOrderStatus(this.order.id, status).subscribe({
      next: (data) => {
        this.notificationService.success('Order status updated successfully.');
        this.orderService.getOrders();
      },

      error: (err) => {}
    });
  }

  delete(): void {
    this.orderService.deleteOrder(this.order.id);
  }
}
