import { Component, OnInit, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchService } from 'src/app/_services/search.service';
import { distinctUntilChanged } from 'rxjs';

@Component({
  selector: 'search-complete-notification',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './search-complete-notification.component.html',
  styleUrls: ['./search-complete-notification.component.scss']
})
export class SearchCompleteNotificationComponent implements OnInit, OnDestroy {
  private searchCompleteSub: any;
  public showMessage: boolean = false;
  public totalItems: number = 0;

  constructor(public searchService: SearchService) {}

  ngOnInit(): void {
    this.searchCompleteSub = this.searchService.searchComplete$.pipe(distinctUntilChanged()).subscribe((searchComplete) => {
      if (this.searchService._isPastResult.getValue() || !searchComplete || !this.searchService._totalItems.getValue()) {
        return;
      }
    });

    this.searchCompleteSub = this.searchService.searchComplete$.pipe(distinctUntilChanged()).subscribe((searchComplete) => {
      if (
        this.searchService._isPastResult.getValue() ||
        !searchComplete ||
        !this.searchService._totalItems.getValue() ||
        this.searchService._error.getValue()
      ) {
        return;
      }

      this.totalItems = this.searchService.getTotalUnseenRelevantItems() || 0;
      this.showMessage = true;

      setTimeout(() => {
        this.showMessage = false;
      }, 10000);
    });
  }

  ngOnDestroy(): void {
    if (this.searchCompleteSub) {
      this.searchCompleteSub.unsubscribe();
    }
  }

  close(): void {
    this.showMessage = false;
  }

  selectAllVendors(): void {
    window.dispatchEvent(new CustomEvent('SelectAllVendors'));
    this.showMessage = false;
  }
}
