import { NgClass, NgIf } from "@angular/common";
import type { OnDestroy, OnInit } from "@angular/core";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ReplaySubject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { LimbleHtmlDirective } from "../../directives/limbleHtml.directive";
import { IconComponent } from "../../icon/icon.component";
import { CheckboxListService } from "../../panels/selectionPanel/checkboxList.service";
import { TooltipDirective } from "../../tooltip/tooltip.directive";

@Component({
   selector: "lim-ui-checkbox",
   templateUrl: "./checkbox.component.html",
   styleUrls: ["./checkbox.component.scss"],
   standalone: true,
   imports: [NgClass, IconComponent, LimbleHtmlDirective, NgIf, TooltipDirective]
})
export class CheckboxComponent implements OnInit, OnDestroy {
   /** The label that will be displayed next to the checkbox. */
   @Input() label: string = "";

   /** The two way bound boolean that will be the data that this checkbox represents */
   @Input() model: boolean | number | string | undefined | null = false;
   @Output() readonly modelChange = new EventEmitter();

   /** Whether or not this checkbox is disabled.*/
   @Input() disabled: boolean = false;

   /** An optional string that will be used for the tooltip if present. */
   @Input() tooltip: string | undefined;

   /**
    * An optional string that identifies this checkbox as a part of a group.
    * All checkboxes that share this groupId will be affected by the
    * {@link CheckboxListService} select/deselectAll functionality.
    */
   @Input() groupId: string | undefined;
   @Input() labelNoWrap: boolean = false;

   /* Optional styling for checkbox labels */
   @Input() useSemiBoldLabel: boolean = false;

   @Input() useWarningColor: boolean = false;

   private readonly destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

   protected get isValueTruthy(): boolean {
      const valueType = typeof this.model;
      switch (valueType) {
         case "boolean":
            return this.model as boolean;
         case "number":
            return Number(this.model) > 0;
         case "string":
            return String(this.model).trim() != "" && this.model != "0";
         default:
            return false;
      }
   }

   public constructor(private readonly checkboxListService: CheckboxListService) {}

   public ngOnInit() {
      if (this.model === undefined || this.model === null) {
         this.model = false;
      }
      if (this.groupId && this.checkboxListService) {
         // These subscriptions allow the checkbox to be selected/deselected as part of a group.
         this.checkboxListService.selectAllCheckboxes$
            .pipe(takeUntil(this.destroyed$))
            .subscribe((id) => {
               if (this.groupId === id) {
                  this.selectCheckbox();
               }
            });
         this.checkboxListService.deselectAllCheckboxes$
            .pipe(takeUntil(this.destroyed$))
            .subscribe((id) => {
               if (this.groupId === id) {
                  this.deselectCheckbox();
               }
            });
      }

      // Some checkbox values can be a 2 due some old code
      if (typeof this.model !== "boolean" && Number(this.model) < 0) {
         throw new Error(
            `Invalid value supplied to checkbox component. Boolean or a number greater than 0 expected. Received: ${this.model}`
         );
      }
   }

   public ngOnDestroy(): void {
      // The destroyed$ observable will be listened to to trigger unsubscribing the
      // select/deselectAll observables, via the takeUntil rxjs operator function.
      this.destroyed$.next(true);
      this.destroyed$.complete();
   }

   public handleCheckboxToggle() {
      if (this.isValueTruthy) {
         this.deselectCheckbox();
      } else {
         this.selectCheckbox();
      }
   }

   private selectCheckbox(): void {
      if (this.disabled) {
         // Disabled checkboxes shouldn't respond to requested model state changes.
         return;
      }
      this.model = this.toggleValue(true);
      this.modelChange.emit(this.model);
   }

   private deselectCheckbox(): void {
      if (this.disabled) {
         // Disabled checkboxes shouldn't respond to requested model state changes.
         return;
      }
      this.model = this.toggleValue(false);
      this.modelChange.emit(this.model);
   }

   private toggleValue(isSelect: boolean = true): boolean | number | string {
      const valueType = typeof this.model;
      switch (valueType) {
         case "boolean":
            return isSelect;
         case "number":
            // The fancy logic around this.model here is to account for values greater than 1,
            // which we for some unknown historical reason have allowed in our data.
            return isSelect ? Math.max(Number(this.model), 1) : 0;
         case "string":
            return isSelect ? "1" : "0";
         default:
            return false;
      }
   }
}
