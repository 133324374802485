<ng-container *ngIf="scrollable; then scrollableTemplate; else fixedHeightTemplate" />

<ng-template #scrollableTemplate>
   <lim-ui-scroll-container limUiDragWhileScrolling>
      <ng-container *ngTemplateOutlet="content" />
   </lim-ui-scroll-container>
</ng-template>

<ng-template #fixedHeightTemplate>
   <ng-container *ngTemplateOutlet="content" />
</ng-template>

<ng-template #content>
   <div
      class="modal-body"
      [ngClass]="{ scrolled: scrollPosition > 24 }"
      #modalBody
      (scroll)="onContentScroll()"
   >
      <ng-content></ng-content>
   </div>
</ng-template>
