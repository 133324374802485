import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgIf, NgClass, AsyncPipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { Subscription } from 'rxjs';

import { AuthService } from '../_services/auth.service';
import { SearchService } from '../_services/search.service';
import { isMobile } from '../_util/mobile.util';

import { IconComponent } from 'cmms-ui';
import { LogoAnimation } from '../animations/logo-animation/logo-animation.component';

import { LoginFormComponent } from '../login-form/login-form.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  standalone: true,
  imports: [NgIf, FormsModule, NgClass, IconComponent, RouterLink, LogoAnimation, AsyncPipe, LoginFormComponent]
})
export class LoginComponent implements OnInit, OnDestroy {
  public isMobile: boolean = false;
  public loggedInSubscriber?: Subscription;
  public querySub?: Subscription;
  public query: string | undefined;

  constructor(public authService: AuthService, public router: Router, private searchService: SearchService) {}

  ngOnInit(): void {
    this.isMobile = isMobile();

    this.querySub = this.searchService.query$.subscribe((query) => {
      this.query = query;
    });

    this.loggedInSubscriber = this.authService.isLoggedIn$.subscribe((isLoggedIn) => {
      if (!isLoggedIn) {
        return;
      }

      if (this.query) {
        this.router.navigate(['/searching'], {
          skipLocationChange: true,
          replaceUrl: true
        });
      } else {
        this.router.navigate(['']);
      }
    });
  }

  ngOnDestroy(): void {
    this.loggedInSubscriber?.unsubscribe();
    this.querySub?.unsubscribe();
  }
}
