import { Component, EventEmitter, Input, Output, OnInit, OnDestroy } from '@angular/core';
import { NgIf, NgFor, NgClass, CurrencyPipe } from '@angular/common';

import {
  MinimalIconButtonComponent,
  PrimaryButtonComponent,
  SecondaryButtonComponent,
  DropdownButtonComponent,
  DropdownTextItemComponent,
  FormDropdownInputComponent
} from 'cmms-ui';
import { VendorGroupedComponent } from '../vendor-grouped-items/vendor-grouped-items';

import { ItemsService } from '../_services/items.service';
import { SearchService } from '../_services/search.service';
import { NotificationService } from '../_services/notification.service';
import { Subscription } from 'rxjs';
import { isMobile } from '../_util/mobile.util';
import { ItemGroupMatch } from '../_types/attributeGrouping';

@Component({
  selector: 'part-search-matches',
  templateUrl: './part-search-matches.component.html',
  styleUrls: ['./part-search-matches.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    PrimaryButtonComponent,
    NgFor,
    CurrencyPipe,
    SecondaryButtonComponent,
    MinimalIconButtonComponent,
    DropdownButtonComponent,
    DropdownTextItemComponent,
    FormDropdownInputComponent,
    NgClass,
    VendorGroupedComponent
  ]
})
export class PartSearchMatchesComponent implements OnInit, OnDestroy {
  public selectedVendorId: number = 0;
  public vendorGroupedItems: any = [];
  public vendorGroupedKeys: any = [];
  public items: any = [];
  public currentSelectedItem: any = {};
  public sortBy: string = 'price_asc';
  public sortByLabels: any = {
    price_asc: 'Total Price: Low to High',
    price_desc: 'Total Price: High to Low',
    price_per_asc: 'Unit Price: Low to High',
    price_per_desc: 'Unit Price: High to Low'
  };
  private _selectedItemMatch: ItemGroupMatch | undefined;
  public hiddenItems: any = [];
  public isMobile: boolean = false;

  public selectedItemSub?: Subscription;
  public notificationSub?: Subscription;
  public notificationTypeSub?: Subscription;
  public hiddenResultsSub?: Subscription;
  public hiddenItemSub?: Subscription;

  @Input()
  set selectedItemMatch(match: ItemGroupMatch | undefined) {
    this._selectedItemMatch = match;

    if (match) {
      this.items = match.items;
      this.selectedVendorId = match.items[0].vendorId;
    }
  }

  get selectedItem(): any {
    return this._selectedItemMatch;
  }
  @Output() onItemSelect = new EventEmitter();

  public unhiddenItem: any = null;
  constructor(
    public searchService: SearchService,
    public notificationService: NotificationService,
    public itemsService: ItemsService
  ) {
    this.notificationSub = this.notificationService.hiddenItemNotification$.subscribe((itemHiddenNotification) => {
      this.unhiddenItem = itemHiddenNotification;
    });

    this.notificationTypeSub = this.notificationService.hiddenItemNotificationType$.subscribe((notificationType) => {
      if (notificationType === 'groupedItem') {
        this.unhideItem(this.unhiddenItem);
      }
    });

    this.hiddenResultsSub = this.searchService.hiddenItems$.subscribe((hiddenItems) => {
      this.hiddenItems = hiddenItems;
    });

    this.selectedItemSub = this.searchService.selectedDetailsPageItem$.subscribe((item) => {
      this.currentSelectedItem = item;
      this.selectedVendorId = item?.vendorId;
    });
  }

  ngOnInit(): void {
    this.isMobile = isMobile();

    if (this.items) {
      this.sortItems(this.sortBy);
      this.selectedVendorId = this.currentSelectedItem?.vendorId;
      this.onItemSelect.emit(this.currentSelectedItem);
      document.getElementById('details')?.scrollIntoView(true);
    }

    this.hiddenItemSub = this.searchService.hideHeroItem$.subscribe((item) => {
      if (!item) return;
      item.user_hidden = true;

      const vendorGroupedIndex = this.vendorGroupedKeys.findIndex((i: any) => i == item.vendorId);
      const itemIndex = this.vendorGroupedItems[vendorGroupedIndex].findIndex((i: any) => i.id === item.id);
      if (itemIndex > -1) {
        this.vendorGroupedItems[vendorGroupedIndex].splice(itemIndex, 1);
        this.vendorGroupedItems[vendorGroupedIndex].push(item);
        let nextSelectedItem;
        if (this.vendorGroupedItems[vendorGroupedIndex].length === 1) {
          const nextVendorGroupIndex = vendorGroupedIndex === this.vendorGroupedKeys.length - 1 ? 0 : vendorGroupedIndex + 1;
          nextSelectedItem = this.vendorGroupedItems[nextVendorGroupIndex][0];
          this.selectedVendorId = nextSelectedItem.vendorId;
          this.onItemSelect.emit(nextSelectedItem);
        } else {
          const nextItemIndex = itemIndex === this.vendorGroupedItems[vendorGroupedIndex].length - 1 ? 0 : itemIndex;
          nextSelectedItem = this.vendorGroupedItems[vendorGroupedIndex][nextItemIndex];
          this.onItemSelect.emit(nextSelectedItem);
        }
        this.currentSelectedItem = nextSelectedItem;
      }
      item.groupedItem = true;
      this.hiddenItems.push(item);
      this.searchService.updateHiddenItems(this.hiddenItems);
      this.searchService.updateMeta();
      this.itemsService.updateItem(item.id, true).subscribe();

      this.searchService.hideHeroItem(null);
      this.sortItems(this.sortBy);
      this.notificationService.hideItem(item);
    });
  }

  unhideItem(item: any) {
    item.user_hidden = false;
    this.sortItems(this.sortBy);
    this.currentSelectedItem = item;
    this.onItemSelect.emit(this.currentSelectedItem);
    this.selectedVendorId = item.vendorId;
  }

  groupByVendor() {
    let grouped: any = {};

    this.items.forEach((item: any) => {
      if (!grouped[item.vendorId]) {
        grouped[item.vendorId] = [];
      }
      grouped[item.vendorId].push(item);
    });

    this.vendorGroupedItems = Object.values(grouped);
    this.sortByVendorGroups();
    //vendor keys based on the order of the sorted vendorGroupedItems
    this.vendorGroupedKeys = this.vendorGroupedItems.map((group: any) => group[0].vendorId);
  }

  getSortByLabel() {
    return this.sortByLabels[this.sortBy];
  }
  sortHiddenItems() {
    this.vendorGroupedItems.forEach((vendorGroup) => {
      vendorGroup.sort((a: any, b: any) => a.user_hidden - b.user_hidden);
    });
    this.vendorGroupedItems.sort((a: any, b: any) => a[0].user_hidden - b[0].user_hidden);
  }

  sortItems(sortBy: string) {
    this.sortBy = sortBy;
    if (this.sortBy === 'price_asc') {
      this.items.sort((a: any, b: any) => a.price - b.price);
    } else if (this.sortBy === 'price_desc') {
      this.items.sort((a: any, b: any) => b.price - a.price);
    } else if (this.sortBy === 'price_per_asc') {
      this.items.sort((a: any, b: any) => a.pricePer - b.pricePer);
    } else if (this.sortBy === 'price_per_desc') {
      this.items.sort((a: any, b: any) => b.pricePer - a.pricePer);
    }
    this.groupByVendor();
    this.sortHiddenItems();
  }

  sortByVendorGroups() {
    if (this.sortBy === 'price_asc') {
      this.vendorGroupedItems.sort((a: any, b: any) => a[0].price - b[0].price);
    } else if (this.sortBy === 'price_desc') {
      this.vendorGroupedItems.sort((a: any, b: any) => b[0].price - a[0].price);
    } else if (this.sortBy === 'price_per_asc') {
      this.vendorGroupedItems.sort((a: any, b: any) => a[0].pricePer - b[0].pricePer);
    } else if (this.sortBy === 'price_per_desc') {
      this.vendorGroupedItems.sort((a: any, b: any) => b[0].pricePer - a[0].pricePer);
    }
  }
  updateSelectedItem(event: any) {
    this.currentSelectedItem = event;
    this.selectedVendorId = event.vendorId;
    this.onItemSelect.emit(this.currentSelectedItem);
  }

  ngOnDestroy(): void {
    this.selectedItemSub?.unsubscribe();
    this.notificationSub?.unsubscribe();
    this.notificationTypeSub?.unsubscribe();
    this.hiddenItemSub?.unsubscribe();
    this.hiddenResultsSub?.unsubscribe();
  }
}
