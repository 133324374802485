<ng-container *ngIf="!isMobile; else responsiveHeroItem">
  <div class="hero-item-container" id="hero-section">
    <div class="badge-container">
      <search-ui-badge *ngIf="!!selectedAttributeGroup" [icon]="faSparkles" [label]="badgeLabel" />
      <search-ui-badge label="Best Match" color="#E9F5EC" />
    </div>
    <div class="primary-details">
      <div class="hero-item-image">
        <img
          [src]="
            selectedItem.mainImageUrl && selectedItem.mainImageUrl.length === 0
              ? '/assets/images/placeholder-img.png'
              : selectedItem.mainImageUrl
          "
          class="item-image"
          alt="Image Coming Soon"
          onerror="this.onerror=null; this.src='/assets/images/placeholder-img.png'"
        />
      </div>
      <div class="hero-item-details">
        <div class="hero-item-header">
          <img [src]="selectedItem.vendor.logoUrl" />
          <div class="controls">
            <svg
              *ngIf="selectedItem.id !== bestMatch.id && selectedItem.id !== mainGroupedItemId"
              width="18"
              height="18"
              viewBox="2 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              (click)="hideItem($event, selectedItem)"
            >
              <path
                d="M2.1875 1.1875L5.6875 3.90625C7.09375 2.84375 8.84375 2 11 2C13.5 2 15.5312 3.15625 17 4.53125C18.4688 5.875 19.4375 7.5 19.9062 8.625C20 8.875 20 9.15625 19.9062 9.40625C19.5 10.4062 18.6562 11.8438 17.4062 13.0938L20.6875 15.6875C21.0312 15.9375 21.0938 16.4062 20.8125 16.7188C20.5625 17.0625 20.0938 17.125 19.7812 16.8438L1.28125 2.34375C0.9375 2.09375 0.875 1.625 1.15625 1.3125C1.40625 0.96875 1.875 0.90625 2.1875 1.1875ZM6.90625 4.875L8.34375 6C9.0625 5.375 9.96875 5 11 5C13.1875 5 15 6.8125 15 9C15 9.6875 14.8438 10.3125 14.5312 10.8438L16.2188 12.1562C17.3125 11.0938 18.0625 9.875 18.4375 9C18.0312 8.0625 17.1875 6.75 15.9688 5.625C14.6875 4.4375 13.0312 3.5 11 3.5C9.40625 3.5 8.0625 4.0625 6.90625 4.875ZM13.3125 9.90625C13.4375 9.625 13.5 9.3125 13.5 9C13.5 7.625 12.375 6.5 11 6.5C10.9688 6.5 10.9375 6.5 10.9375 6.5C10.9688 6.6875 11 6.84375 11 7C11 7.34375 10.9062 7.625 10.7812 7.90625L13.3125 9.90625ZM13.625 13.9688L14.9375 15C13.7812 15.625 12.4688 16 11 16C8.46875 16 6.4375 14.875 4.96875 13.5C3.5 12.125 2.53125 10.5 2.0625 9.40625C1.96875 9.15625 1.96875 8.875 2.0625 8.625C2.375 7.90625 2.875 7 3.59375 6.0625L4.75 7C4.1875 7.71875 3.78125 8.4375 3.53125 9C3.96875 9.9375 4.78125 11.2812 6 12.4062C7.28125 13.5938 8.9375 14.5 11 14.5C11.9375 14.5 12.8125 14.3125 13.625 13.9688ZM7 9H6.96875C6.96875 8.9375 7 8.84375 7 8.75L8.75 10.125C9.0625 10.7812 9.6875 11.2812 10.4375 11.4375L12.1875 12.8438C11.8125 12.9375 11.4062 13 11 13C8.78125 13 7 11.2188 7 9Z"
                fill="#313233"
                stroke-width="16"
                font-size="16"
              />
            </svg>
          </div>
        </div>
        <div class="details">
          <div class="main-details">
            <div class="title">{{ selectedItem.title }}</div>
            <div class="item-price">
              <span class="total">{{ selectedItem.price | currency }}</span>
              <span class="per" *ngIf="selectedItem.pricePer !== selectedItem.price"
                >({{ selectedItem.pricePer | currency }}/count)</span
              >
            </div>
            <div class="visit-site">
              <button (click)="openItemSite(selectedItem['productUrl'])" class="main-page-btn">
                <span class="visit-site-badge">Visit Site</span>
              </button>
            </div>
          </div>
          <div class="line-break" *ngIf="this.itemAttributes.length > 0"></div>
          <div class="other-details">
            <div *ngIf="selectedItem.inStock || selectedItem.shipping_days !== null">
              <shipping-details [item]="selectedItem"></shipping-details>
            </div>
            <div>
              <div class="secondary-vendor" *ngIf="selectedItem.source !== selectedItem.vendor.name">
                {{ selectedItem.source }}
              </div>
              <div class="price-attributes">
                <div class="attributes">
                  <span *ngIf="selectedItem.brandName !== null">Brand</span>
                  <span *ngIf="selectedItem.manufacturerPartNumber !== null">Part Number</span>
                </div>
                <div class="attributes-value">
                  <span *ngIf="selectedItem.brandName !== null">{{ selectedItem.brandName }}</span>
                  <span *ngIf="selectedItem.manufacturerPartNumber !== null">{{ selectedItem.manufacturerPartNumber }}</span>
                </div>
              </div>
            </div>
            <div class="see-more-details" *ngIf="moreProductDetails" (click)="showMoreProductDetails()">
              <span *ngIf="!collapsed">See more product details</span
              ><span *ngIf="collapsed">Hide more product details</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="more-details-line-break" *ngIf="collapsed"></div>
    <div class="more-details" *ngIf="collapsed">
      <div class="item-attributes" *ngFor="let attribute of itemAttributes">
        <div class="attributes">
          <span>{{ attribute.attributeName }}</span>
          <span>{{ attribute.attributeValue }}</span>
        </div>
      </div>
      <div class="description" *ngIf="selectedItem.description">
        <span class="text-highlight">Description</span>
        <p id="item-description">
          {{ selectedItem.description }}
        </p>
        <div
          *ngIf="descriptionClamped"
          (click)="openItemSite(selectedItem['productUrl'])"
          class="see-more-details read-more"
        >
          <span class="text-underline">Read More</span><lim-ui-icon icon="arrowUpRightFromSquare" iconSize="small" />
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-template #responsiveHeroItem>
  <div class="responsive-hero-item-container" id="hero-section">
    <div class="badge-container">
      <search-ui-badge *ngIf="!!selectedAttributeGroup" [icon]="faSparkles" [label]="badgeLabel" />
      <search-ui-badge label="Best Match" color="#E9F5EC" />
    </div>
    <div class="primary-details">
      <div class="vendor-logo">
        <div class="hero-item-header">
          <img [src]="!!selectedItem && !!selectedItem.vendor ? selectedItem.vendor.logoUrl : ''" />
        </div>
        <div class="hero-item-image">
          <img
            [src]="
              selectedItem.mainImageUrl && selectedItem.mainImageUrl.length === 0
                ? '/assets/images/placeholder-img.png'
                : selectedItem.mainImageUrl
            "
            class="item-image"
            alt="Image Coming Soon"
            onerror="this.onerror=null; this.src='/assets/images/placeholder-img.png'"
          />
        </div>
      </div>
      <div class="main-details">
        <div class="title">{{ selectedItem.title }}</div>
        <div class="item-price">
          <span class="total">{{ selectedItem.price | currency }}</span>
          <span class="per" *ngIf="selectedItem.pricePer !== selectedItem.price"
            >({{ selectedItem.pricePer | currency }}/count)</span
          >
        </div>
        <div *ngIf="selectedItem.inStock || selectedItem.shipping_days !== null">
          <shipping-details [item]="selectedItem"></shipping-details>
        </div>
      </div>
    </div>
    <div class="other-details">
      <div class="visit-site">
        <button (click)="openItemSite(selectedItem['productUrl'])" class="main-page-btn">
          <span class="visit-site-badge">Visit Site</span>
        </button>
      </div>
      <div class="see-more-details" *ngIf="moreProductDetails" (click)="showMoreProductDetails()">
        <span *ngIf="!collapsed">See more product details</span><span *ngIf="collapsed">Hide more product details</span>
      </div>
      <div class="more-details-line-break" *ngIf="collapsed"></div>
      <div class="more-details" *ngIf="collapsed">
        <div class="item-attributes" *ngFor="let attribute of itemAttributes">
          <div class="attributes">
            <span>{{ attribute.attributeName }}</span>
            <span>{{ attribute.attributeValue }}</span>
          </div>
        </div>
        <div class="description" *ngIf="selectedItem.description">
          <span class="text-highlight">Description</span>
          <p id="item-description">
            {{ selectedItem.description }}
          </p>
          <div
            *ngIf="descriptionClamped"
            (click)="openItemSite(selectedItem['productUrl'])"
            class="see-more-details read-more"
          >
            <span class="text-underline">Read More</span><lim-ui-icon icon="arrowUpRightFromSquare" iconSize="small" />
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
