import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { OrderService } from '../_services/order.service';
import { FormsModule } from '@angular/forms';
import {
  PrimaryButtonComponent,
  SearchBoxComponent,
  CheckboxComponent,
  RadioButtonComponent,
  MinimalIconButtonComponent
} from 'cmms-ui';
import { NgIf, NgFor } from '@angular/common';

export type FieldValue = {
  name: any;
  enabled: boolean;
  count: number;
};

@Component({
  selector: 'orders-filter',
  templateUrl: './orders-filter.component.html',
  styleUrls: ['./orders-filter.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    PrimaryButtonComponent,
    SearchBoxComponent,
    FormsModule,
    NgFor,
    CheckboxComponent,
    RadioButtonComponent,
    MinimalIconButtonComponent
  ]
})
export class OrdersFilterComponent implements OnInit, OnDestroy {
  @Input() field = {
    name: null,
    field: '',
    order: 0,
    count: 0,
    values: Array<FieldValue>()
  };

  public multiSelect: boolean = true;
  public filteredValues: any[] = [];
  public activeFilterCount: number = 0;
  public searchFilter: string = '';
  public minimized: boolean = false;
  public showSearch: boolean = false;

  constructor(public orderService: OrderService) {}

  ngOnInit(): void {
    if (this.field.field == 'createdAt') {
      this.multiSelect = false;
    }

    this.filteredValues = this.field.values;
    this.activeFilterCount = this.field.values.filter((row) => row['enabled']).length;

    if (this.field.values.length > 8) {
      this.showSearch = true;
    }
  }

  ngOnDestroy(): void {}

  searchValues(event: any) {
    const value = this.searchFilter.toLowerCase();

    if (!value) {
      this.filteredValues = this.field.values;
      return;
    }
    this.filteredValues = this.field.values.filter((row) => row['name'].toLowerCase().includes(value));
  }

  toggleCheckboxValue(event: any, value: any) {
    let valueRow = this.field.values.find((row) => row['name'] == value);
    if (valueRow) {
      valueRow.enabled = !valueRow.enabled;
    }

    const enabledValues = this.field.values.filter((row) => row['enabled']);
    if (enabledValues.length == 0) {
      this.orderService.removeFilter(this.field.field);
    } else {
      this.orderService.addFilter(this.field);
    }

    this.orderService.getOrders();
  }

  toggleRadioValue(event: any, value: any) {
    // Set all field values to disabled
    this.field.values.forEach((row) => {
      row.enabled = false;
    });

    let valueRow = this.field.values.find((row) => row['name'] == value);
    if (valueRow) {
      valueRow.enabled = true;
    }

    this.orderService.addFilter(this.field);
    this.orderService.getOrders();
  }

  toggleMinimized() {
    this.minimized = !this.minimized;
  }
}
