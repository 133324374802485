type ScreenType = "desktop" | "tablet" | "mobile";

class LimUiManageDemo {
   public demo: any = false;
   public demoInstructions: any = {};
   private demoScreenType: ScreenType = "desktop";

   public setDemoScreenType(screenType: string): void {
      if (
         screenType !== "desktop" &&
         screenType !== "tablet" &&
         screenType !== "mobile"
      ) {
         this.demoScreenType = "desktop";

         return;
      }

      this.demoScreenType = screenType;
   }

   public getDemoScreenType(): ScreenType {
      return this.demoScreenType;
   }
}

export const manageDemo = new LimUiManageDemo();
