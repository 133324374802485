import { NgClass, NgIf, NgTemplateOutlet } from "@angular/common";
import { Component, Input } from "@angular/core";
import { IconComponent } from "../../icon/icon.component";
import type { Aliases, Colors } from "../../icon/icon.service";
import { Sizes } from "../../icon/icon.service";
import { BasicButtonComponent } from "../basic-button/basic-button.component";

@Component({
   selector: "lim-ui-icon-text-button",
   templateUrl: "../templates/icon-text.html",
   styleUrls: ["../button-styles.scss"],
   standalone: true,
   imports: [NgIf, IconComponent, NgClass, NgTemplateOutlet]
})
export class IconTextButtonComponent extends BasicButtonComponent {
   @Input() public icon: Aliases | undefined;
   @Input() public secondaryIcon: Aliases | undefined;
   @Input() public iconSize: Sizes = "small";
   @Input() public secondaryIconSize: Sizes = "small";
   @Input() public iconColor: Colors | undefined;
   /** Specifies whether or not this button should be styled as a login button */
   @Input() public isLoginButton: boolean | undefined;
   @Input() public fixedWidthTableButton: boolean | undefined;
   public isPrimaryButton: boolean = false;
}
