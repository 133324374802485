import { Injectable, QueryList } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, withInterceptorsFromDi } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { identifierName, isNgTemplate } from '@angular/compiler';
import { CONFIG } from '../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  withCredentials: true
};

const ordersUrl = CONFIG.API_URL + 'orders';

export enum OrderServiceStatus {
  Ready,
  Loading,
  ResultsFound,
  NoResultsFound,
  Error
}

export enum OrderStatus {
  Open = 'open',
  Closed = 'closed',
  InProgress = 'in_progress'
}

@Injectable({
  providedIn: 'root'
})
export class OrderService {
  private readonly _query = new BehaviorSubject<any>(null);
  private readonly _orders = new BehaviorSubject<any>(null);
  private readonly _error = new BehaviorSubject<any>(null);
  private readonly _fields = new BehaviorSubject<any>(null);
  private readonly _filters = new BehaviorSubject<any>({});
  private readonly _status = new BehaviorSubject<OrderServiceStatus>(OrderServiceStatus.Ready);
  private readonly _page = new BehaviorSubject<any>({ pageNumber: 1, pageSize: 10, total: 0 });
  private readonly _totalSavings = new BehaviorSubject<any>({
    monthToDate: 0,
    allTime: 0
  });

  readonly query$ = this._query.asObservable();
  readonly orders$ = this._orders.asObservable();
  readonly error$ = this._error.asObservable();
  readonly status$ = this._status.asObservable();
  readonly fields$ = this._fields.asObservable();
  readonly filters$ = this._filters.asObservable();
  readonly page$ = this._page.asObservable();
  readonly totalSavings$ = this._totalSavings.asObservable();

  constructor(private http: HttpClient) {}

  getReadableStatus(status: string): string {
    switch (status) {
      case OrderStatus.Open:
        return 'Open';
      case OrderStatus.Closed:
        return 'Closed';
      case OrderStatus.InProgress:
        return 'In Progress';
      default:
        return '';
    }
  }

  setPageNumber(pageNumber: Number): void {
    const page = this._page.getValue();
    page.pageNumber = pageNumber;
    this._page.next(page);
  }

  setPageSize(pageSize: Number): void {
    const page = this._page.getValue();
    page.pageSize = pageSize;
    this._page.next(page);
  }

  setQuery(query: any): void {
    this._query.next(query);
  }

  getPage(): any {
    return this._page.getValue();
  }

  updateOrderStatus(id: number, status: string): Observable<any> {
    return this.http.post<any>(`${ordersUrl}/${id}`, {
      status
    });
  }

  getOrders(): void {
    this._status.next(OrderServiceStatus.Loading);

    let params = new HttpParams();
    const filters = this._filters.getValue();

    if (filters.status) {
      let statuses = filters.status.values
        .filter((value: any) => value.enabled)
        .map((value: any) => {
          params = params.append('status', value.value);
          return value.value;
        });
    }

    if (filters.createdAt) {
      let dates = filters.createdAt.values
        .filter((value: any) => {
          return value.enabled && value.value && value.value.length > 0;
        })
        .map((value: any) => {
          params = params.append('createdAt', value.value.join(','));
          return value.value;
        });
    }

    if (filters.vendorId) {
      let vendorIds = filters.vendorId.values
        .filter((value: any) => value.enabled)
        .map((value: any) => {
          params = params.append('vendorId', value.value);
          return value.value;
        });
    }

    if (this._query.getValue()) {
      params = params.set('q', this._query.getValue());
    } else {
      params = params.delete('q');
    }

    let page = this._page.getValue();
    if (page) {
      params = params.set('pageNumber', page.pageNumber);
      params = params.set('pageSize', page.pageSize);
    }

    this.http
      .get<any>(`${ordersUrl}`, {
        params
      })
      .subscribe({
        next: (data) => {
          this._orders.next(data.orders);
          this._fields.next(data.facets);
          this._page.next(data.page);
          this._totalSavings.next(data.savings);

          if (data.orders.length === 0) {
            this._status.next(OrderServiceStatus.NoResultsFound);
          } else {
            this._status.next(OrderServiceStatus.ResultsFound);
          }
        },

        error: (err) => {
          this._error.next(err);
          this._status.next(OrderServiceStatus.Error);
        }
      });
  }

  deleteOrder(id: number): void {
    this.http.delete<any>(`${ordersUrl}/${id}`).subscribe({
      next: (data) => {
        this.getOrders();
      },

      error: (err) => {}
    });
  }

  addFilter(filter: any): void {
    const currentFilters = this._filters.getValue();

    // Add object with field, operator, and values to filters observable object, keyed by field
    this._filters.next({
      ...currentFilters,
      [filter.field]: filter
    });
  }

  removeFilter(field: string): void {
    let filters = this._filters.getValue();
    delete filters[field];
    this._filters.next(filters);
  }
}
