<div
   *ngIf="collectionSize && collectionSize > pageSize"
   [ngClass]="paginationClass ? paginationClass : ''"
   class="pagination-container"
>
   <div class="icon-box" (click)="decrementPage()">
      <lim-ui-icon icon="angleLeft" iconSize="small" />
   </div>
   <ng-container *ngIf="pages <= maxSize + 2">
      <div
         *ngFor="let i of [].constructor(pages); let idx = index"
         class="pagination-page"
         [ngClass]="idx + 1 === page ? 'active' : ''"
         (click)="changePage(idx + 1)"
      >
         {{ idx + 1 }}
      </div>
   </ng-container>
   <ng-container *ngIf="pages > maxSize + 2">
      <ng-container *ngIf="page < 1 + maxSize / 2">
         <div
            *ngFor="let i of [].constructor(maxSize); let idx = index"
            class="pagination-page"
            [ngClass]="idx + 1 === page ? 'active' : ''"
            (click)="changePage(idx + 1)"
         >
            {{ idx + 1 }}
         </div>
         <div class="ellipsis">...</div>
         <div class="pagination-page" (click)="changePage(pages)">{{ pages }}</div>
      </ng-container>
      <ng-container *ngIf="page > 1 + maxSize / 2 && page < 2 + maxSize / 2">
         <div
            *ngFor="let i of [].constructor(maxSize + 1); let idx = index"
            class="pagination-page"
            [ngClass]="idx + 1 === page ? 'active' : ''"
            (click)="changePage(idx + 1)"
         >
            {{ idx + 1 }}
         </div>
         <div class="ellipsis">...</div>
         <div class="pagination-page" (click)="changePage(pages)">{{ pages }}</div>
      </ng-container>
      <ng-container *ngIf="page > 2 + maxSize / 2 && page < pages - 1 - maxSize / 2">
         <div class="pagination-page" (click)="changePage(1)">1</div>
         <div class="ellipsis">...</div>
         <div
            *ngFor="let i of [].constructor(maxSize); let idx = index"
            class="pagination-page"
            [ngClass]="
               page + idx - maxSize / 2 < page && page + idx + 1 - maxSize / 2 > page
                  ? 'active'
                  : ''
            "
            (click)="changePage(page + idx - maxSize / 2)"
         >
            {{ page + idx - maxSize / 2 | number : "1.0-0" }}
         </div>
         <div class="ellipsis">...</div>
         <div class="pagination-page" (click)="changePage(pages)">{{ pages }}</div>
      </ng-container>
      <ng-container *ngIf="page > pages - 1 - maxSize / 2 && page < pages - maxSize / 2">
         <div class="pagination-page" (click)="changePage(1)">1</div>
         <div class="ellipsis">...</div>
         <div
            *ngFor="let i of [].constructor(maxSize + 1); let idx = index"
            class="pagination-page"
            [ngClass]="pages - maxSize + idx === page ? 'active' : ''"
            (click)="changePage(pages - maxSize + idx)"
         >
            {{ pages - maxSize + idx }}
         </div>
      </ng-container>
      <ng-container *ngIf="page > pages - maxSize / 2">
         <div class="pagination-page" (click)="changePage(1)">1</div>
         <div class="ellipsis">...</div>
         <div
            *ngFor="let i of [].constructor(maxSize); let idx = index"
            class="pagination-page"
            [ngClass]="pages - maxSize + idx + 1 === page ? 'active' : ''"
            (click)="changePage(pages - maxSize + idx + 1)"
         >
            {{ pages - maxSize + idx + 1 }}
         </div>
      </ng-container>
   </ng-container>
   <div class="icon-box" (click)="incrementPage()">
      <lim-ui-icon icon="angleRight" iconSize="small" />
   </div>
</div>
