<section class="filter">
  <header (click)="toggleMinimized()">
    <div class="filter-name">
      <span>{{ field.name }}</span>
      <span *ngIf="activeFilterCount > 0" class="active-filter-count">({{ activeFilterCount }})</span>
    </div>
    <lim-ui-minimal-icon-button [icon]="minimized ? 'chevronDown' : 'chevronUp'"></lim-ui-minimal-icon-button>
  </header>
  <div [hidden]="minimized">
    <div class="search-container" *ngIf="showSearch">
      <lim-ui-search-box placeholder="Search" [(ngModel)]="searchFilter" (keyup)="searchValues($event)"></lim-ui-search-box>
    </div>
    <ul class="filter-values" *ngIf="filteredValues && filteredValues.length > 0">
      <li class="filter-value" *ngFor="let row of filteredValues">
        <lim-ui-checkbox
          *ngIf="multiSelect"
          [model]="row['enabled']"
          [label]="row['name']"
          (modelChange)="toggleCheckboxValue($event, row['name'])"
        ></lim-ui-checkbox>
        <lim-ui-radio-button
          *ngIf="!multiSelect"
          [label]="row['name']"
          name="createdAt"
          [inputID]="row['name']"
          (change)="toggleRadioValue($event, row['name'])"
          [checked]="row['enabled']"
        ></lim-ui-radio-button>
        <span class="item-count">({{ row['count'] }})</span>
      </li>
    </ul>
  </div>
</section>
