import { NgClass, NgIf, NgTemplateOutlet } from "@angular/common";
import type { OnInit } from "@angular/core";
import { Component, Input } from "@angular/core";
import { IconComponent } from "../../icon/icon.component";
import { IconTextButtonComponent } from "../icon-text-button/icon-text-button.component";

@Component({
   selector: "lim-ui-secondary-button",
   templateUrl: "../templates/icon-text.html",
   styleUrls: ["./secondary-button.component.scss", "../button-styles.scss"],
   standalone: true,
   imports: [NgIf, IconComponent, NgClass, NgTemplateOutlet]
})
export class SecondaryButtonComponent extends IconTextButtonComponent implements OnInit {
   @Input() public fullWidthOnMobile = false;
   @Input() public fullWidthForced = false;

   public ngOnInit(): void {
      this.classNames += " button-shadow";
      if (this.fullWidthForced) {
         this.classNames += " full-width-forced";
      } else if (this.fullWidthOnMobile) {
         this.classNames += " mobile-full-width";
      }
   }
}
