import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
/**
 * Service used by checkbox lists that want to be able to select/deselect all checkboxes.
 *
 * Note that a unique groupId string can be used to control checkbox groupings.
 */
@Injectable({
   providedIn: "any"
})
export class CheckboxListService {
   private readonly selectAllCheckboxesSource = new Subject<string>();
   private readonly deselectAllCheckboxesSource = new Subject<string>();

   /**
    * Checkboxes subscribe to this multicast observable when they are part of
    * a group of checkboxes that support the "select all" functionality
    */
   public selectAllCheckboxes$ = this.selectAllCheckboxesSource.asObservable();

   /**
    * Checkboxes subscribe to this multicast observable when they are part of
    * a group of checkboxes that support the "deselect all" functionality
    */
   public deselectAllCheckboxes$ = this.deselectAllCheckboxesSource.asObservable();

   /**
    * Sends out the groupId to all subscribers of the selectAllCheckboxesSource.
    * This is used by checkboxes to change their state to selected.
    * @param groupId the unique identifier for the group of checkboxes to select.
    */
   public selectAllCheckboxes(groupId: string): void {
      this.selectAllCheckboxesSource.next(groupId);
   }

   /**
    * Sends out the groupId to all subscribers of the deselectAllCheckboxesSource.
    * This is used by checkboxes to change their state to deselected.
    * @param groupId the unique identifier for the group of checkboxes to deselect.
    */
   public deselectAllCheckboxes(groupId: string): void {
      this.deselectAllCheckboxesSource.next(groupId);
   }
}
