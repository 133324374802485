import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgIf, NgFor } from '@angular/common';

import { SearchService } from '../_services/search.service';
import { NotificationService } from '../_services/notification.service';
import { isMobile } from '../_util/mobile.util';

import { MinimalIconButtonComponent } from 'cmms-ui';

import { PartSearchItemCardComponent } from '../part-search-item-card/part-search-item-card.component';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'similar-items',
  templateUrl: './similar-items.component.html',
  styleUrls: ['./similar-items.component.scss'],
  standalone: true,
  imports: [NgIf, NgFor, PartSearchItemCardComponent, MinimalIconButtonComponent]
})
export class SimilarItemsComponent implements OnInit {
  public scrollPos: any = 0;

  public similarItems: any = [];
  public items: any = [];
  public _selectedItem: any;
  public isMobile: boolean = false;

  @ViewChild('scrollContainer') scrollContainer: ElementRef | undefined;

  @Output() onItemSelect = new EventEmitter();

  constructor(
    public searchService: SearchService,
    public notificationService: NotificationService,
    public route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.isMobile = isMobile();
    this.scrollContainer;

    this.route.params.subscribe((params) => {
      const { id } = params;
      this.getSimilarItems(id);
    });
  }

  getSimilarItems(itemId: number | undefined) {
    if (!itemId) {
      return;
    }

    this.searchService.getSimilarItems(itemId as number).subscribe((data: any) => {
      this.similarItems = data;

      let similarItemsById = {};
      this.similarItems.forEach((item: any) => {
        similarItemsById[item.id] = item;
      });

      // Pull all items from our existing grouped search results based on similarItem ids
      let similarItemIds = this.similarItems.map((item: any) => item.id);

      let sub: any = null;
      sub = this.searchService.searchResults$.subscribe((items: any) => {
        if (items && items.length) {
          let filteredSimilar = items.filter((item: any) => {
            // If the item ID matches any of the similarItem ids, return the item
            if (similarItemIds.includes(item.id)) {
              return true;
            }

            // If the item is a group and any of the group items match any of the similarItem ids,
            // return the item
            if (item.matches?.items && item.matches?.items.length > 0) {
              let foundInGroup = item.matches.items.find((match: any) => {
                return similarItemIds.includes(match.id);
              });

              if (foundInGroup) {
                if (!similarItemsById[item.id]) {
                  similarItemsById[item.id] = {
                    id: item.id,
                    score: 0
                  };
                }

                similarItemsById[item.id].score = similarItemsById[foundInGroup.id].score;
                return true;
              }

              return false;
            }

            return false;
          });

          // Sort similar items by similarItemsById score attribute desc
          filteredSimilar.sort((a: any, b: any) => {
            return similarItemsById[b.id].score - similarItemsById[a.id].score;
          });

          this.items = filteredSimilar;
        } else {
          this.items = this.similarItems;
        }

        if (sub) {
          sub.unsubscribe();
        }
      });
    });
  }

  getItemCardWidth() {
    let itemCard = this.scrollContainer?.nativeElement.querySelectorAll('.item')[0];
    let scrollWidth = itemCard.clientWidth;
    let gap = 24;
    let border = 0;

    return scrollWidth + gap + border;
  }

  canScrollLeft() {
    return this.scrollPos > 0;
  }

  canScrollRight() {
    return this.scrollPos < this.items.length - 3;
  }

  scrollLeft() {
    if (!this.canScrollLeft()) {
      return;
    }

    this.scrollPos--;

    if (this.scrollContainer) {
      this.scrollContainer.nativeElement.scrollLeft -= this.getItemCardWidth();
    }

    let event = new CustomEvent('itemView', {
      detail: {
        container: this.scrollContainer?.nativeElement
      }
    });

    window.dispatchEvent(event);
  }

  scrollRight() {
    if (!this.canScrollRight()) {
      return;
    }

    this.scrollPos++;

    if (this.scrollContainer) {
      this.scrollContainer.nativeElement.scrollLeft += this.getItemCardWidth();
    }

    let event = new CustomEvent('itemView', {
      detail: {
        container: this.scrollContainer?.nativeElement
      }
    });

    window.dispatchEvent(event);
  }
}
