import { NgClass, NgIf } from "@angular/common";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { LimbleHtmlDirective } from "../../directives/limbleHtml.directive";
import { IconComponent } from "../../icon/icon.component";
import { TooltipDirective } from "../../tooltip/tooltip.directive";

@Component({
   selector: "lim-ui-radio-button",
   templateUrl: "./radio-button.component.html",
   styleUrls: ["./radio-button.component.scss"],
   standalone: true,
   imports: [NgIf, NgClass, LimbleHtmlDirective, IconComponent, TooltipDirective]
})
export class RadioButtonComponent {
   @Input() public inputID: string = "";
   @Input() public groupID: string = "";
   @Input() public label: string = "";
   @Input() public tooltip: string | undefined;
   @Input() public checked: boolean = false;
   @Input() public disabled: boolean = false;
   @Input() public labelMaxWidth: boolean = true;
   @Input() public dataLogLabel: string | undefined;
   @Output() public readonly clickRadioButton = new EventEmitter();

   public handleRadioClick() {
      this.clickRadioButton.emit("");
   }
}
