import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgIf } from '@angular/common';
import { NavigationStart, Router } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons';
import { faCircleXmark } from '@fortawesome/pro-solid-svg-icons';
import { Subscription, distinctUntilChanged } from 'rxjs';

import { SearchService } from '../_services/search.service';
import { AuthService } from '../_services/auth.service';
import { HeapService } from '../_services/heap.service';

import { PartSearchFieldComponent } from '../part-search-field/part-search-field.component';
import { QueryQualityDropdownComponent } from '../query-quality-dropdown/query-quality-dropdown';
import { LimUiModalRef, ModalComponent, ModalDirective, ModalResult, IconComponent, ModalBodyComponent } from 'cmms-ui';

@Component({
  selector: 'part-search-modal',
  standalone: true,
  imports: [
    ModalComponent,
    ModalDirective,
    ModalBodyComponent,
    PartSearchFieldComponent,
    QueryQualityDropdownComponent,
    FontAwesomeModule,
    IconComponent,
    FormsModule,
    NgIf
  ],
  templateUrl: './part-search-modal.component.html',
  styleUrl: './part-search-modal.component.scss'
})
export class PartSearchModalComponent implements ModalResult<any>, OnInit, OnDestroy, AfterViewInit {
  public query: string = '';

  public chevronLeft = faChevronLeft;
  public circleXmark = faCircleXmark;
  public isMobile: boolean = false;
  public isLoggedIn: boolean = false;
  public showQueryQuality: boolean = true;

  public authSub?: Subscription;
  public querySub?: Subscription;
  public routerSub?: Subscription;

  public constructor(
    public readonly modalRef: LimUiModalRef<PartSearchModalComponent, any>,
    public searchService: SearchService,
    public router: Router,
    public heapService: HeapService,
    public authService: AuthService
  ) {}

  ngOnInit(): void {
    this.authSub = this.authService.isLoggedIn$.subscribe((isLoggedIn) => {
      this.isLoggedIn = isLoggedIn;
      if (isLoggedIn) {
        const user = this.authService.getUser();
        this.heapService.identifyUser(user);
      }
    });

    this.querySub = this.searchService.query$.pipe(distinctUntilChanged()).subscribe((query: any) => {
      this.query = query;
    });

    this.routerSub = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.modalRef.close(false);
      }
    });

    this.searchService.toggleSearchModalState(true);

    //disable scrolling when modal is opened
    document.body.style.overflow = 'hidden';
  }

  ngAfterViewInit(): void {
    //get part search field to focus on input
    setTimeout(() => {
      const partSearchField = document.getElementById('modal-part-search-field');
      if (partSearchField) {
        partSearchField.focus();
      }
    }, 100);
  }

  close(): void {
    this.showQueryQuality = false;
    this.searchService.toggleSearchModalState(false);
    this.modalRef.close(false);

    //enable scrolling when modal is closed
    document.body.style.overflow = 'unset';
  }

  submit(): void {
    this.showQueryQuality = false;
    this.searchService.toggleSearchModalState(false);
    this.modalRef.close(this.query);

    //enable scrolling when search is submitted
    document.body.style.overflow = 'unset';
  }

  clearSearchQuery() {
    this.query = '';
  }

  ngOnDestroy(): void {
    this.authSub?.unsubscribe();
    this.querySub?.unsubscribe();
    this.routerSub?.unsubscribe();
  }
}
