import type { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import * as inventory from "./fontawesome-inventory";

export type Aliases =
   | "addressCard"
   | "addressCardRegular"
   | "angleDown"
   | "angleDownRegular"
   | "angleRight"
   | "angleRightRegular"
   | "angleLeft"
   | "angleLeftRegular"
   | "angleUp"
   | "angleUpRegular"
   | "arrowDown"
   | "arrowDownLeftAndArrowUpRightToCenter"
   | "arrowRight"
   | "arrowRightArrowLeft"
   | "arrowLeft"
   | "arrowUp"
   | "arrowUpRightFromSquare"
   | "arrowsRotate"
   | "arrowsUpDown"
   | "arrowsUpDownLeftRightLight"
   | "arrowsUpDownLeftRightRegular"
   | "barcode"
   | "banRegular"
   | "barsProgress"
   | "barsRegular"
   | "barsSort"
   | "bell"
   | "bellRegular"
   | "bold"
   | "bolt"
   | "boltRegular"
   | "book"
   | "bookmark"
   | "bookmarkRegular"
   | "broomWideRegular"
   | "bullhorn"
   | "calendar"
   | "calendarCheck"
   | "calendarDays"
   | "calendarDaysRegular"
   | "calendarXmark"
   | "camera"
   | "cameraRegular"
   | "cartShopping"
   | "chartColumn"
   | "chartLineRegular"
   | "chartNetwork"
   | "check"
   | "chevronDown"
   | "chevronLeft"
   | "chevronRight"
   | "chevronUp"
   | "circle"
   | "circleRegular"
   | "circleCheck"
   | "circleCheckRegular"
   | "circleExclamation"
   | "circleInfoRegular"
   | "circleInfoSolid"
   | "circleLocationArrowRegular"
   | "circleNotch"
   | "circlePlay"
   | "circlePlus"
   | "circlePlusRegular"
   | "circleQuestion"
   | "circleQuestionRegular"
   | "circleUser"
   | "circleXmark"
   | "clipboardList"
   | "clock"
   | "clockRegular"
   | "clockRotateLeft"
   | "cloneRegular"
   | "cloudArrowDown"
   | "cloudArrowUp"
   | "cloudDownload"
   | "cloudDownloadRegular"
   | "cloudUploadRegular"
   | "cloudUpload"
   | "codeForkRegular"
   | "comment"
   | "commentRegular"
   | "compressRegular"
   | "crosshairs"
   | "creditCard"
   | "creditCardAmex"
   | "creditCardDiscover"
   | "creditCardMaster"
   | "creditCardVisa"
   | "cube"
   | "cubeRegular"
   | "cubes"
   | "cubesRegular"
   | "desktop"
   | "dollarSign"
   | "earthAmericas"
   | "editRegular"
   | "ellipsis"
   | "ellipsisVertical"
   | "envelope"
   | "envelopeRegular"
   | "eye"
   | "eyeRegular"
   | "eyeSlashRegular"
   | "faceFrown"
   | "file"
   | "filePowerpoint"
   | "filePowerpointRegular"
   | "fileExcel"
   | "fileExcelRegular"
   | "fileLines"
   | "fileLinesRegular"
   | "filePdf"
   | "filePdfRegular"
   | "fileRegular"
   | "fileWord"
   | "fileWordRegular"
   | "fileZipper"
   | "fileZipperRegular"
   | "floppyDiskRegular"
   | "filter"
   | "fireFlameCurved"
   | "folder"
   | "gauge"
   | "gaugeHigh"
   | "gear"
   | "gearRegular"
   | "gears"
   | "gearsRegular"
   | "handshake"
   | "handBackPointUp"
   | "handshakeRegular"
   | "hashtagRegular"
   | "home"
   | "hourglassClockRegular"
   | "houseChimney"
   | "idCard"
   | "imageRegular"
   | "indent"
   | "infinity"
   | "keyboardRegular"
   | "link"
   | "linkSlash"
   | "list"
   | "listRegular"
   | "listOl"
   | "lock"
   | "lockRegular"
   | "lockOpen"
   | "locationArrow"
   | "locationDot"
   | "magnifyingGlass"
   | "magnifyingGlassRegular"
   | "map"
   | "minus"
   | "moneyBill"
   | "moneyBill1"
   | "noteSticky"
   | "noteStickyRegular"
   | "notdef"
   | "pause"
   | "pencil"
   | "penToSquare"
   | "penToSquareRegular"
   | "phone"
   | "phoneRegular"
   | "play"
   | "plus"
   | "plusRegular"
   | "plusSquare"
   | "plusSquareRegular"
   | "print"
   | "printRegular"
   | "qrCodeRegular"
   | "retweet"
   | "right"
   | "rightLeft"
   | "rightFromBracket"
   | "rightToBracket"
   | "rocketLaunchRegular"
   | "rotate"
   | "rotateRegular"
   | "rotateLeft"
   | "rotateRight"
   | "rss"
   | "rssRegular"
   | "screwdriverWrench"
   | "screwdriverWrenchRegular"
   | "shareFromSquareRegular"
   | "shuffle"
   | "shuffleRegular"
   | "sitemap"
   | "sliders"
   | "slidersRegular"
   | "sort"
   | "sortAsc"
   | "sortDesc"
   | "square"
   | "squareArrowUpRight"
   | "squareArrowUpRightRegular"
   | "squareCaretDownRegular"
   | "squareCaretRightRegular"
   | "squareCheck"
   | "squareCheckRegular"
   | "squarePlus"
   | "squarePlusRegular"
   | "stars"
   | "stop"
   | "superpowers"
   | "tableColumns"
   | "tag"
   | "thumbsDown"
   | "thumbsUp"
   | "trashCanRegular"
   | "triangleExclamation"
   | "triangleExclamationRegular"
   | "truck"
   | "underline"
   | "unlockRegular"
   | "user"
   | "userRegular"
   | "users"
   | "userSecret"
   | "wandMagic"
   | "wandMagicSparkles"
   | "warningRegular"
   | "wifi"
   | "wifiSlash"
   | "wpforms"
   | "wrench"
   | "wrenchRegular"
   | "xmarkRegular"
   | "xmark"
   | "xmarkLarge"
   | "xmarkLargeRegular"
   | "youtube";

export class FontawesomeIcon {
   public static readonly iconMapper: { [Alias in Aliases]: IconDefinition } = {
      addressCard: inventory.fasAddressCard,
      addressCardRegular: inventory.farAddressCard,
      angleDown: inventory.fasAngleDown,
      angleDownRegular: inventory.farAngleDown,
      angleRight: inventory.fasAngleRight,
      angleRightRegular: inventory.farAngleRight,
      angleLeft: inventory.fasAngleLeft,
      angleLeftRegular: inventory.farAngleLeft,
      angleUp: inventory.fasAngleUp,
      angleUpRegular: inventory.farAngleUp,
      arrowDown: inventory.fasArrowDown,
      arrowDownLeftAndArrowUpRightToCenter:
         inventory.fasArrowDownLeftAndArrowUpRightToCenter,
      arrowUp: inventory.fasArrowUp,
      arrowUpRightFromSquare: inventory.fasArrowUpRightFromSquare,
      arrowRight: inventory.fasArrowRight,
      arrowRightArrowLeft: inventory.fasArrowRightArrowLeft,
      arrowLeft: inventory.fasArrowLeft,
      arrowsRotate: inventory.fasArrowsRotate,
      arrowsUpDown: inventory.fasArrowsUpDown,
      arrowsUpDownLeftRightLight: inventory.falArrowsUpDownLeftRight,
      arrowsUpDownLeftRightRegular: inventory.farArrowsUpDownLeftRight,
      barcode: inventory.farBarcode,
      banRegular: inventory.farBan,
      barsProgress: inventory.fasBarsProgress,
      barsRegular: inventory.farBars,
      barsSort: inventory.fasBarsSort,
      bell: inventory.fasBell,
      bellRegular: inventory.farBell,
      bold: inventory.fasBold,
      bolt: inventory.fasBolt,
      boltRegular: inventory.farBolt,
      book: inventory.fasBook,
      bookmark: inventory.fasBookmark,
      bookmarkRegular: inventory.farBookmark,
      broomWideRegular: inventory.farBroomWide,
      bullhorn: inventory.fasBullhorn,
      calendar: inventory.fasCalendarDays,
      calendarCheck: inventory.farCalendarCheck,
      calendarDays: inventory.fasCalendarDays,
      calendarDaysRegular: inventory.farCalendarDays,
      calendarXmark: inventory.farCalendarXmark,
      camera: inventory.fasCamera,
      cameraRegular: inventory.farCamera,
      cartShopping: inventory.farCartShopping,
      chartColumn: inventory.fasChartColumn,
      chartLineRegular: inventory.farChartLine,
      chartNetwork: inventory.fasChartNetwork,
      check: inventory.fasCheck,
      chevronDown: inventory.fasChevronDown,
      chevronLeft: inventory.fasChevronLeft,
      chevronRight: inventory.fasChevronRight,
      chevronUp: inventory.fasChevronUp,
      circle: inventory.fasCircle,
      circleRegular: inventory.farCircle,
      circleCheck: inventory.fasCheckCircle,
      circleCheckRegular: inventory.farCheckCircle,
      circleExclamation: inventory.fasCircleExclamation,
      circleInfoRegular: inventory.farCircleInfo,
      circleInfoSolid: inventory.fasCircleInfo,
      circleLocationArrowRegular: inventory.farCircleLocationArrow,
      circleNotch: inventory.fasCircleNotch,
      circlePlus: inventory.fasCirclePlus,
      circlePlusRegular: inventory.farCirclePlus,
      circleQuestion: inventory.fasCircleQuestion,
      circleQuestionRegular: inventory.farCircleQuestion,
      circleUser: inventory.fasCircleUser,
      circleXmark: inventory.fasCircleXmark,
      clipboardList: inventory.fasClipboardList,
      clock: inventory.fasClock,
      clockRegular: inventory.farClock,
      clockRotateLeft: inventory.fasClockRotateLeft,
      cloneRegular: inventory.farClone,
      cloudArrowDown: inventory.fasCloudDownload,
      cloudArrowUp: inventory.farCloudArrowUp,
      cloudUploadRegular: inventory.farCloudArrowUp,
      cloudDownload: inventory.fasCloudDownload,
      cloudDownloadRegular: inventory.farCloudDownload,
      cloudUpload: inventory.fasCloudUpload,
      codeForkRegular: inventory.farCodeFork,
      comment: inventory.fasComment,
      commentRegular: inventory.farComment,
      compressRegular: inventory.farCompress,
      crosshairs: inventory.fasCrosshairs,
      creditCard: inventory.fasCreditCard,
      creditCardAmex: inventory.fabCreditCardAmex,
      creditCardDiscover: inventory.fabCreditCardDiscover,
      creditCardMaster: inventory.fabCreditCardMaster,
      creditCardVisa: inventory.fabCreditCardVisa,
      cube: inventory.fasCube,
      cubeRegular: inventory.farCube,
      cubes: inventory.fasCubes,
      cubesRegular: inventory.farCubes,
      desktop: inventory.fasDesktop,
      dollarSign: inventory.fasDollarSign,
      earthAmericas: inventory.fasEarthAmericas,
      editRegular: inventory.farEdit,
      ellipsis: inventory.fasEllipsis,
      ellipsisVertical: inventory.fasEllipsisVertical,
      envelope: inventory.fasEnvelope,
      imageRegular: inventory.farImage,
      envelopeRegular: inventory.farEnvelope,
      eye: inventory.fasEye,
      eyeRegular: inventory.farEye,
      eyeSlashRegular: inventory.farEyeSlash,
      faceFrown: inventory.fasFaceFrown,
      file: inventory.fasFile,
      fileExcel: inventory.fasFileExcel,
      fileExcelRegular: inventory.farFileExcel,
      fileLines: inventory.fasFileLines,
      fileLinesRegular: inventory.farFileLines,
      filePdf: inventory.fasFilePdf,
      filePdfRegular: inventory.farFilePdf,
      filePowerpoint: inventory.fasFilePowerpoint,
      filePowerpointRegular: inventory.farFilePowerpoint,
      fileRegular: inventory.farFile,
      fileWord: inventory.fasFileWord,
      fileWordRegular: inventory.farFileWord,
      fileZipper: inventory.fasFileZipper,
      fileZipperRegular: inventory.farFileZipper,
      filter: inventory.fasFilter,
      fireFlameCurved: inventory.fasFireFlameCurved,
      floppyDiskRegular: inventory.farFloppyDisk,
      folder: inventory.fasFolder,
      gauge: inventory.fasGauge,
      gaugeHigh: inventory.fasGaugeHigh,
      gear: inventory.fasGear,
      gearRegular: inventory.farGear,
      gears: inventory.fasGears,
      gearsRegular: inventory.farGears,
      handBackPointUp: inventory.farHandBackPointUp,
      handshake: inventory.fasHandshake,
      handshakeRegular: inventory.farHandshake,
      hashtagRegular: inventory.farHashtag,
      home: inventory.fasHome,
      hourglassClockRegular: inventory.farHourglassClock,
      houseChimney: inventory.fasHouseChimney,
      idCard: inventory.fasIdCard,
      indent: inventory.fasIndent,
      infinity: inventory.fasInfinity,
      keyboardRegular: inventory.farKeyboard,
      link: inventory.fasLink,
      linkSlash: inventory.fasLinkSlash,
      list: inventory.fasList,
      listRegular: inventory.farList,
      listOl: inventory.fasListOl,
      lock: inventory.fasLock,
      lockRegular: inventory.farLock,
      lockOpen: inventory.fasLockOpen,
      locationArrow: inventory.fasLocationArrow,
      locationDot: inventory.fasLocationDot,
      magnifyingGlass: inventory.fasMagnifyingGlass,
      magnifyingGlassRegular: inventory.farMagnifyingGlass,
      map: inventory.fasMap,
      moneyBill: inventory.fasMoneyBill,
      moneyBill1: inventory.fasMoneyBill1,
      notdef: inventory.fasNotdef,
      noteSticky: inventory.fasNoteSticky,
      noteStickyRegular: inventory.farNoteSticky,
      pencil: inventory.farPencil,
      penToSquare: inventory.fasPenToSquare,
      penToSquareRegular: inventory.farPenToSquare,
      phone: inventory.fasPhone,
      phoneRegular: inventory.farPhone,
      plus: inventory.fasPlus,
      plusRegular: inventory.farPlus,
      plusSquare: inventory.fasPlusSquare,
      plusSquareRegular: inventory.farSquarePlus,
      minus: inventory.fasMinus,
      pause: inventory.fasPause,
      play: inventory.fasPlay,
      circlePlay: inventory.fasCirclePlay,
      print: inventory.fasPrint,
      printRegular: inventory.farPrint,
      qrCodeRegular: inventory.farQrcode,
      retweet: inventory.fasRetweet,
      right: inventory.fasRight,
      rightLeft: inventory.fasRightLeft,
      rightFromBracket: inventory.fasRightFromBracket,
      rightToBracket: inventory.fasRightToBracket,
      rocketLaunchRegular: inventory.farRocketLaunchRegular,
      rotate: inventory.fasRotate,
      rotateRegular: inventory.farRotateRegular,
      rotateLeft: inventory.fasRotateLeft,
      rotateRight: inventory.fasRotateRight,
      rss: inventory.fasRss,
      rssRegular: inventory.farRss,
      screwdriverWrench: inventory.fasScrewdriverWrench,
      screwdriverWrenchRegular: inventory.farScrewdriverWrench,
      shareFromSquareRegular: inventory.farShareFromSquare,
      shuffle: inventory.fasShuffle,
      shuffleRegular: inventory.farShuffle,
      sitemap: inventory.fasSitemap,
      sliders: inventory.fasSliders,
      slidersRegular: inventory.farSliders,
      sort: inventory.fasSort,
      sortAsc: inventory.fasSortAsc,
      sortDesc: inventory.fasSortDesc,
      square: inventory.fasSquare,
      squareArrowUpRight: inventory.fasSquareArrowUpRight,
      squareArrowUpRightRegular: inventory.farSquareArrowUpRight,
      squareCaretDownRegular: inventory.farSquareCaretDown,
      squareCaretRightRegular: inventory.farSquareCaretRight,
      squareCheck: inventory.fasSquareCheck,
      squareCheckRegular: inventory.farSquareCheck,
      squarePlus: inventory.fasSquarePlus,
      squarePlusRegular: inventory.farSquarePlus,
      superpowers: inventory.fabSuperpowers,
      stars: inventory.fasStars,
      stop: inventory.fasStop,
      tableColumns: inventory.fasTableColumns,
      tag: inventory.fasTag,
      thumbsDown: inventory.fasThumbsDown,
      thumbsUp: inventory.fasThumbsUp,
      trashCanRegular: inventory.farTrashCan,
      triangleExclamation: inventory.fasTriangleExclamation,
      triangleExclamationRegular: inventory.farTriangleExclamation,
      truck: inventory.fasTruck,
      underline: inventory.fasUnderline,
      unlockRegular: inventory.farUnlock,
      user: inventory.fasUser,
      userRegular: inventory.farUser,
      users: inventory.fasUsers,
      userSecret: inventory.fasUserSecret,
      wandMagic: inventory.fasWandMagic,
      wandMagicSparkles: inventory.fasWandMagicSparkles,
      wifi: inventory.fasWifi,
      wifiSlash: inventory.fasWifiSlash,
      wpforms: inventory.fabWpforms,
      wrench: inventory.fasWrench,
      wrenchRegular: inventory.farWrench,
      warningRegular: inventory.farWarning,
      xmarkRegular: inventory.farXmark,
      xmark: inventory.fasXmark,
      xmarkLarge: inventory.fasXmarkLarge,
      xmarkLargeRegular: inventory.farXmarkLarge,
      youtube: inventory.fabYoutube
   };

   public supplier = "fontawesome";
   public icon: IconDefinition;

   public constructor(alias: Aliases) {
      this.icon = FontawesomeIcon.iconMapper[alias];
      if (this.icon === undefined) {
         throw new Error(`Icon name '${alias}' is not usable in FontawesomeIcon`);
      }
   }
}
