<div>
   <div
      (click)="handleDropdownButtonClicked()"
      (mouseenter)="hoverDropdown ? initiateOpen() : false"
      (mouseleave)="hoverDropdown ? delayedClose() : false"
      #button
   >
      <ng-content select="[button]"></ng-content>
   </div>
   <div
      *ngIf="showMenu"
      class="dropdown-wrapper"
      #dropdown
      (mouseenter)="menuHovered = true"
      (mouseleave)="hoverDropdown ? initiateClose() : false"
   >
      <div
         class="dropdown-menu-wrapper"
         [ngClass]="{ 'fit-content': widthFitContent, 'show': showMenu }"
         id="dropdown"
      >
         <ng-content select="[header]"></ng-content>
         <lim-ui-scroll-container [thin]="true">
            <ng-content select="[menu]"></ng-content>
         </lim-ui-scroll-container>
         <ng-content select="[footer]"></ng-content>
      </div>
   </div>
</div>
