import { Component, Input } from "@angular/core";
import { IconComponent } from "../../icon/icon.component";
import { DropdownComponent } from "../dropdown.component";

@Component({
   selector: "lim-ui-dropdown-button",
   templateUrl: "./dropdown-button.component.html",
   styleUrls: ["./dropdown-button.component.scss"],
   standalone: true,
   imports: [DropdownComponent, IconComponent]
})
export class DropdownButtonComponent {
   @Input() label?: string;
   @Input() public widthFitContent: boolean = false;
}
