<div class="main-container">
  <div>
    <div class="cover-image">
      <div class="overlay-content" [ngStyle]="{ position: showQueryQuality || showVendorPrefs ? 'relative' : '' }">
        <div class="banner-badge">
          <fa-icon [icon]="sparkles" [style.color]="'#289E49'" size="xs" />
          <span>AI-Powered Search</span>
        </div>
        <h1 class="search-banner">Top-Quality Parts from Leading Vendors, All in One Place</h1>
        <div class="landing-part-search-container">
          <div class="landing-part-search-bar-container" [ngClass]="{ 'has-modal': showQueryQuality || showVendorPrefs }">
            <input
              name="part_search"
              id="part-search-field"
              [(ngModel)]="query"
              class="part-search"
              (keydown.enter)="search()"
              (focus)="showQueryQualityDropdown()"
              (blur)="showQueryQuality = false"
              placeholder="Search by Part Name, Part Detail, etc."
              autocomplete="off"
            />
            <button class="search-button" (click)="search()">
              <lim-ui-icon icon="magnifyingGlass" iconSize="small" />
            </button>
          </div>
          <query-quality-dropdown
            [query]="query"
            [show]="showQueryQuality"
            style="position: absolute"
            [ngClass]="{ 'query-quality-dropdown': showQueryQuality }"
          ></query-quality-dropdown>
        </div>

        <div style="position: relative; width: 100%" *ngIf="isLoggedIn">
          <a (click)="showVendorPrefs = !showVendorPrefs" class="vendor-prefs-link">
            <lim-ui-icon icon="sliders" iconSize="small" />
            Vendor Preferences
          </a>
          <vendor-prefs
            [show]="showVendorPrefs"
            (onClose)="showVendorPrefs = false"
            style="width: 100%; position: absolute; left: 0px; right: 0px; top: 46px"
          ></vendor-prefs>
        </div>
      </div>
    </div>
  </div>
  <h2>Popular Purchased Items</h2>
  <div class="popular-products">
    <div class="popular-card" *ngFor="let item of popularItems">
      <div class="card-image">
        <img src="/assets/images/product-images/{{ item.partImage }}" alt="Product Image" />
      </div>
      <div class="card-details">
        <div class="part-details">
          <div class="title">{{ item.itemTitle }}</div>
          <div class="part-number">{{ item.partNumber }}</div>
        </div>
        <div class="price-variance">
          <span class="icon-padding"><fa-icon [icon]="arrowTrendUp" size="xs"></fa-icon></span>
          <span class="number">{{ item.priceVariance }}%</span> price variance across {{ item.noOfVendors }} vendors
        </div>
        <div class="search-item" (click)="searchItem(item.partNumber, item.itemTitle)">Search Now</div>
      </div>
    </div>
  </div>
  <div class="vendor-section">
    <h2 class="vendor-line">Over +150 Million Parts from Popular Vendors</h2>
    <div class="vendor-page">
      <img
        *ngFor="let vendor of pagedVendors"
        src="/assets/images/vendor-logos/{{ vendor.vendorImg }}"
        alt="{{ vendor.vendor }} Logo"
      />
    </div>
  </div>
  <ng-container *ngIf="isMobile">
    <div class="signup">
      <div class="signup-content-container">
        <div class="free-trial">Sign up now to enjoy a 7-day free trial</div>
        <a routerLink="/register" class="signup-text">Get Started</a>
        <div class="account-text">Already have an account? <a routerLink="/login" class="login-text">Log In</a></div>
      </div>
    </div>
  </ng-container>
  <div class="part-search-section">
    <h2>Revolutionize Your Part Search</h2>
    <div class="part-search-attribute-container">
      <div class="part-search-attribute">
        <div class="icon-container">
          <fa-icon [icon]="sparkles" [style.color]="'#289E49'" size="lg" transform="grow-4" />
        </div>
        <div class="header">AI-Powered Part Search</div>
        <div class="details">Leverages advanced AI-powered technology to make comparing prices for parts effortless.</div>
      </div>
      <div class="part-search-attribute">
        <div class="icon-container">
          <fa-icon [icon]="circleDollar" [style.color]="'#289E49'" size="lg" transform="grow-4" />
        </div>
        <div class="header">Save on Every Purchase</div>
        <div class="details">
          Limble Search ensures you save money on every purchase with live results and the latest pricing.
        </div>
      </div>
      <div class="part-search-attribute">
        <div class="icon-container">
          <fa-icon [icon]="clock" [style.color]="'#289E49'" size="lg" transform="grow-4" />
        </div>
        <div class="header">Save Time Shopping</div>
        <div class="details">
          Access Grainger, McMaster-Carr, Zoro, Uline, MSC, Fastenal, Amazon, and more - all in one place.
        </div>
      </div>
    </div>
  </div>
  <div class="customer-reviews-section">
    <div class="customers-title-container">
      <h2 class="customers-title">What Our Customers Say</h2>
    </div>
    <div class="customer-reviews">
      <div class="review-card" *ngFor="let review of customerReviews">
        <div class="review">
          <img src="/assets/images/quote-icon.svg" alt="Quote Icon" />
          <div class="review-content">{{ review.reviewContent }}</div>
        </div>
        <div class="reviewer-details">
          <img src="/assets/images/reviewer-images/{{ review.reviewerImage }}" alt="Reviewer Image" />
          <div class="reviewer">
            <div class="reviewer-name">{{ review.name }}</div>
            <div class="reviewer-title">{{ review.jobTitle }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
