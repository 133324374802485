<ng-template #content>
   <span *ngIf="icon" class="icon-holder">
      <lim-ui-icon [iconColor]="iconColor" [icon]="icon" [iconSize]="iconSize" />
   </span>

   <ng-content></ng-content>

   <span *ngIf="secondaryIcon" class="secondary-icon-holder">
      <lim-ui-icon [icon]="secondaryIcon" [iconSize]="secondaryIconSize" />
   </span>
</ng-template>

<div *ngIf="href then anchorLayout; else buttonLayout"></div>

<ng-template #anchorLayout>
   <a
      class="lim-ui-button {{ classNames }}"
      [ngClass]="{'active': active, 'login-button': isLoginButton}"
      [href]="href"
      [target]="target"
      [attr.data-log]="dataLogLabel"
   >
      <ng-container *ngTemplateOutlet="content" />
   </a>
</ng-template>

<ng-template #buttonLayout>
   <button
      class="lim-ui-button {{ classNames }}"
      [ngClass]="{'active': active, 'login-button': isLoginButton, 'primary-button': isPrimaryButton, 'task-table-button': fixedWidthTableButton}"
      [disabled]="disabled"
      [attr.data-log]="dataLogLabel"
   >
      <ng-container *ngTemplateOutlet="content" />
   </button>
</ng-template>
