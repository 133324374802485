import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import type { LimUiModalRef } from "./modalRef";

@Injectable({ providedIn: "root" })
export class ModalEvents {
   public closeEvents$: Subject<{ modalRef: LimUiModalRef<any, any>; result: any }> =
      new Subject();
   public dismissEvents$: Subject<{
      modalRef: LimUiModalRef<any, any>;
      reason: Error | undefined;
   }> = new Subject();
   private readonly onModalCloseSubject = new Subject<LimUiModalRef<any, any>>();
   public onModalClose$ = this.onModalCloseSubject.asObservable();

   public close<Component, Result>(
      modalRef: LimUiModalRef<Component, Result>,
      result: Result
   ): void {
      this.closeEvents$.next({ modalRef, result });
      this.onModalCloseSubject.next(modalRef);
   }

   public dismiss<Component, Result>(
      modalRef: LimUiModalRef<Component, Result>,
      reason?: Error
   ): void {
      this.dismissEvents$.next({ modalRef, reason });
      this.onModalCloseSubject.next(modalRef);
   }
}
