import type { AfterViewInit, ComponentRef, OnDestroy, Type } from "@angular/core";
import { Component, Input, ViewChild, ViewContainerRef } from "@angular/core";

import { NgIf } from "@angular/common";
import { LimUiMobileFooter } from "../footers/mobileAppFooter/mobileAppFooter.service";
import { LimUiMobileHeader } from "../headers/mobileAppHeader/mobileAppHeader.service";
import { LimUiModal } from "./modal.service";

@Component({
   selector: "lim-ui-modal-wrapper",
   templateUrl: "./modal-wrapper.component.html",
   styleUrls: ["./modal-wrapper.component.scss"],
   standalone: true,
   imports: [NgIf]
})
export class LimUiModalComponent<Component = unknown>
   implements AfterViewInit, OnDestroy
{
   @Input() isEmbedded: boolean = false;

   @ViewChild("container") container;

   public modalContent: ComponentRef<Component> | undefined;

   public constructor(
      private readonly viewContainerRef: ViewContainerRef,
      private readonly modalService: LimUiModal,
      private readonly footerService: LimUiMobileFooter,
      private readonly headerService: LimUiMobileHeader
   ) {
      this.footerService.hideFooter(this);
      this.headerService.hideHeader(this);
   }

   public ngAfterViewInit(): void {
      if (this.modalContent) {
         this.container.nativeElement.appendChild(
            this.modalContent.location.nativeElement
         );
      }
   }

   public ngOnDestroy(): void {
      this.footerService.showFooter();
      this.headerService.showHeader();
   }

   public init(content: Type<Component>): void {
      this.modalContent = this.viewContainerRef.createComponent(content);
   }

   public close() {
      if (this.isEmbedded) {
         console.warn("Embedded modal cannot be closed");
         return;
      }
      this.modalService.getActiveModal()?.close(0);
   }
}
