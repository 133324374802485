import { NgClass, NgIf } from "@angular/common";
import type { OnInit } from "@angular/core";
import {
   ChangeDetectorRef,
   Component,
   ElementRef,
   Input,
   ViewChild
} from "@angular/core";
import { computePosition, flip, offset, shift } from "@floating-ui/dom";
import { isPhoneScreenSize } from "../../util/mobile.util";
import { ScrollContainerComponent } from "../scrolling/scroll-container/scroll-container.component";
import { DropdownService } from "./dropdown.service";

type placementOptions =
   | "top"
   | "top-start"
   | "top-end"
   | "right"
   | "right-start"
   | "right-end"
   | "bottom"
   | "bottom-start"
   | "bottom-end"
   | "left"
   | "left-start"
   | "left-end";

@Component({
   selector: "lim-ui-dropdown",
   templateUrl: "./dropdown.component.html",
   styleUrls: ["./dropdown.component.scss"],
   standalone: true,
   imports: [NgIf, NgClass, ScrollContainerComponent]
})
export class DropdownComponent implements OnInit {
   @Input() public placement: placementOptions = "bottom-start";
   @Input() public widthFitContent: boolean = false;
   @Input() public disabled: boolean = false;
   @Input() public hoverDropdown: boolean = false;
   @ViewChild("button") button!: ElementRef<HTMLDivElement>;
   @ViewChild("dropdown") dropdown!: ElementRef<HTMLDivElement>;
   public classNames: Array<string> = [];
   public showMenu: boolean = false;
   public menuHovered: boolean = false;

   public constructor(
      private readonly dropdownService: DropdownService,
      private readonly changeDetector: ChangeDetectorRef
   ) {}

   public ngOnInit(): void {
      this.classNames = [this.disabled ? "disabled" : ""];
   }

   public async updatePosition() {
      return computePosition(this.button.nativeElement, this.dropdown.nativeElement, {
         placement: this.placement,
         middleware: [flip(), offset(8), shift({ crossAxis: true, padding: 4 })]
      }).then((data) => {
         if (isPhoneScreenSize()) {
            Object.assign(this.dropdown.nativeElement.style, {
               top: `${data.y}px`
            });
         } else {
            Object.assign(this.dropdown.nativeElement.style, {
               left: `${data.x}px`,
               top: `${data.y}px`
            });
         }
      });
   }

   protected delayedClose() {
      setTimeout(() => {
         if (this.menuHovered) {
            return;
         }
         this.initiateClose();
      }, 500);
   }

   /**
    * Opens the dropdown and adds it to the DOM.
    *
    * @remarks This function should only be called by the @link{dropdownService}.
    *    The reason that it should only be called by the @link{dropdownService} is that
    *    the @link{dropdownService} keeps track of which dropdowns are open, and opening
    *    a dropdown directly rather than going through the service can lead to orphaned
    *    dropdowns that are open, but the service doesn't know about them.
    * @returns true if the dropdown opens, false if it does not.
    */
   public open(): boolean {
      if (this.disabled || this.isOpen()) {
         return false;
      }
      this.showMenu = true;
      this.changeDetector.detectChanges();

      this.dropdown.nativeElement.remove();
      document.querySelector("body")?.appendChild(this.dropdown.nativeElement);
      this.dropdown.nativeElement.classList.add("intermediate");

      this.updatePosition().then(() => {
         this.dropdown.nativeElement.classList.remove("intermediate");
      });
      return true;
   }

   /**
    * Initiates the opening of the dropdown.
    */
   public initiateOpen(): void {
      this.dropdownService.openDropdown(this);
   }

   /**
    * Closes the dropdown and removes it from the DOM.
    *
    * @remarks This function should only be called by the @link{dropdownService}.
    *    The reason that it should only be called by the @link{dropdownService} is that
    *    the @link{dropdownService} keeps track of which dropdowns are open, and closing
    *    a dropdown directly rather than going through the service can lead to orphaned
    *    dropdowns that are closed, but the service still thinks they are open.
    *    If you want to close a dropdown from another component, use the initiateClose()
    *    function instead.
    */
   public close() {
      this.showMenu = false;
      this.menuHovered = false;
      this.dropdown.nativeElement.remove();
      this.changeDetector.detectChanges();
   }

   /**
    * Initiates the closing of the dropdown.
    */
   public initiateClose() {
      this.dropdownService.closeDropdownAndChildren(this);
   }

   protected handleDropdownButtonClicked() {
      if (!this.isOpen()) {
         this.initiateOpen();
      }
   }

   public isOpen(): boolean {
      return this.showMenu;
   }

   public getDropdownMenuRef(): ElementRef<HTMLDivElement> {
      return this.dropdown;
   }
}
