import { NgClass, NgIf, NgTemplateOutlet } from "@angular/common";
import { Component, ElementRef, HostBinding, Input, ViewChild } from "@angular/core";
import { ScrollContainerComponent } from "../../scrolling/scroll-container/scroll-container.component";

@Component({
   selector: "lim-ui-modal-body",
   templateUrl: "./modal-body.component.html",
   styleUrls: ["./modal-body.component.scss"],
   standalone: true,
   imports: [NgIf, ScrollContainerComponent, NgTemplateOutlet, NgClass]
})
export class ModalBodyComponent {
   @HostBinding("class") public readonly classes = "scroll-height-inheritance";
   @ViewChild("modalBody") modalBody?: ElementRef<HTMLElement>;
   @Input() scrollable: boolean = true;
   public scrollPosition: number = 0;

   public onContentScroll() {
      if (!this.modalBody) return;
      this.scrollPosition = this.modalBody.nativeElement.scrollTop;
   }
}
