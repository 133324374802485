import { NgClass, NgIf } from "@angular/common";
import type { AfterViewInit } from "@angular/core";
import {
   Component,
   ElementRef,
   EventEmitter,
   Input,
   Output,
   ViewChild,
   forwardRef
} from "@angular/core";
import type { ControlValueAccessor } from "@angular/forms";
import { FormsModule, NG_VALUE_ACCESSOR } from "@angular/forms";
import { noop } from "rxjs";
import { IconComponent } from "../icon/icon.component";

@Component({
   selector: "lim-ui-search-box",
   templateUrl: "./search-box.component.html",
   styleUrls: ["./search-box.component.scss"],
   providers: [
      {
         provide: NG_VALUE_ACCESSOR,
         useExisting: forwardRef(() => SearchBoxComponent),
         multi: true
      }
   ],
   standalone: true,
   imports: [NgClass, IconComponent, FormsModule, NgIf]
})
export class SearchBoxComponent implements AfterViewInit, ControlValueAccessor {
   /** The user input search value. */
   @Input() public searchVal: string = "";
   /** Event that's emitted on searchVal change. */
   @Output() public readonly searchValChange = new EventEmitter<string>();
   /** Text value for the 'Clear button */
   @Input() public clearText: string = "";
   /** Placeholder text for the search bar */
   @Input() public placeholder: string = "";
   /** Whether or not to focus this input on component initialization */
   @Input() public focusOnLoad?: boolean = false;
   /** limSearchInput ViewChild to focus the element if focusOnLoad is set  */
   @ViewChild("limSearchInput") limSearchInput!: ElementRef<HTMLDivElement>;
   /** Whether or not the searchbox should be styled for use within a dropdown */
   @Input() public useDropdownStyling?: boolean = false;
   /** Whether or not the searchbox should be full width of the parent */
   @Input() public fullWidth?: boolean = false;

   private onChangeCallback: (value: string) => void = noop;
   protected onTouchedCallback: () => void = noop;

   public ngAfterViewInit(): void {
      if (this.focusOnLoad) {
         setTimeout(() => {
            this.limSearchInput.nativeElement.focus();
         });
      }
   }

   public clearSearch(event): void {
      this.writeValue("");
      this.limSearchInput.nativeElement.focus();
      // Prevent the dropdowns from closing on clear search
      event.stopPropagation();
   }

   public writeValue(searchVal: string): void {
      this.searchVal = searchVal;
      this.searchValChange.emit(searchVal);
      this.onChangeCallback(searchVal);
   }

   public registerOnChange(callback: (value: string) => void): void {
      this.onChangeCallback = callback;
   }

   public registerOnTouched(callback: () => void): void {
      this.onTouchedCallback = callback;
   }
}
