export type LimbleHtmlInput = string | null | undefined | { toString: () => string };

import type { OnChanges } from "@angular/core";
import { Directive, ElementRef, Input, SecurityContext } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

/**
 * This directive can be used as a faster, more limited version of Angular's
 * `[innerHTML]` directive.
 *
 * @remarks
 * We found that `[innerHTML]` is too slow when it is used many times on a page.
 * This directive does basically the same thing, but more tailored to our needs.
 */

@Directive({
   standalone: true,
   selector: "[limbleHtml]"
})
export class LimbleHtmlDirective implements OnChanges {
   @Input() limbleHtml: LimbleHtmlInput;

   public constructor(
      private readonly host: ElementRef<HTMLElement>,
      private readonly sanitizer: DomSanitizer
   ) {}

   public ngOnChanges(): void {
      if (!this.isHtmlValid(this.limbleHtml)) {
         this.handleInvalidType(this.limbleHtml);
         return;
      }
      this.updateInnerHtml(this.limbleHtml);
   }

   private updateInnerHtml(html: LimbleHtmlInput): void {
      const htmlStringable = html ?? "";
      const htmlString = String(htmlStringable);
      const sanitizedHtml = this.sanitizeHtml(htmlString);
      this.host.nativeElement.innerHTML = sanitizedHtml;
   }

   private isHtmlValid(html: unknown): boolean {
      return (
         typeof html === "string" ||
         html === null ||
         html === undefined ||
         typeof (html as object).toString === "function"
      );
   }

   private sanitizeHtml(html: string): string {
      if (!html.includes("<")) {
         //doesn't contain html elements, so it doesn't need to be sanitized.
         return html;
      }
      return this.sanitizer.sanitize(SecurityContext.HTML, html) ?? "";
   }

   private handleInvalidType(value: unknown): void {
      console.error(
         `Bad data passed into limbleHtml directive. Expected a string or 'undefined', but got '${typeof value}'`
      );
      //Try to insert it anyway.
      this.updateInnerHtml(String(value));
   }
}
