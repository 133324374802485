import { Directive, ElementRef, Renderer2 } from "@angular/core";

/**
 * This directive is used to add a class to the containing element of all lim-ui-modal components.
 * The class is necessary to ensure cross browser compatibility by not using vh units to determine
 * the viewport height. The class allows the modal height to fit its content while also not
 * allowing it to expand beyond the viewport.
 *
 * The directive allows us to hide some of the complexities of the implementation making modals
 * easier to use.  By using the directive we don't need to use @HostBinding to add the class to
 * the host element in every component that has a modal in its template.
 *
 * Depending on the template structure it is possible you will still need to add an @hostBinding
 * to the component if the modal tag is wrapped by another element.
 */

@Directive({
   selector: "lim-ui-modal",
   standalone: true
})
export class ModalDirective {
   public constructor(private readonly renderer: Renderer2, hostElement: ElementRef) {
      const parent = renderer.parentNode(hostElement.nativeElement);
      if (!parent) {
         return;
      }
      renderer.addClass(parent, "scroll-height-inheritance");
   }
}
