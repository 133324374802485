import type { Observable } from "rxjs";
import { BehaviorSubject, debounce, fromEvent, timer } from "rxjs";
import { manageDemo } from "./demo.util";

export function isMobile(): boolean {
   return isNativeMobileApp() || isMobileBrowser() || isMobileOrTabletDemo();
}

export function isNativeMobileApp(): boolean {
   return navigator.userAgent.includes("gonative");
}

export function isMobileBrowser(): boolean {
   return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
   );
}

export function isMobileOrTabletDemo(): boolean {
   const demoScreenType = manageDemo.getDemoScreenType();
   return demoScreenType === "mobile" || demoScreenType === "tablet";
}

function isScreenSizeSmallerThan(screenSize): boolean {
   return window.innerWidth < screenSize;
}

export function isPhoneScreenSize(): boolean {
   return isScreenSizeSmallerThan(750);
}

export function isTabletOrSmallerScreenSize(): boolean {
   return isScreenSizeSmallerThan(1170);
}

/**
 * This has proven to be unreliable on ipad. Think about using isIOSUserAgent or isAndroidUserAngent instead if you absolutely need to test the platform.
 * Otherwise, test if the feature is available.
 * @deprecated
 */
export function isiOS(): boolean {
   return (
      isMobile() &&
      [
         "iPad Simulator",
         "iPhone Simulator",
         "iPod Simulator",
         "iPad",
         "iPhone",
         "iPod",
         "MacIntel"
      ].includes(navigator.platform)
   );
}

export function isIOSUserAgent(): boolean {
   const userAgent = window.navigator.userAgent.toLowerCase();
   return isMobile() && /iphone|ipad| macintosh | mac| ipod/.test(userAgent);
}

export function isAndroidUserAgent(): boolean {
   return isMobile() && navigator.userAgent.toLowerCase().includes("android");
}

function getScreenSizeObservable(screenSize: () => boolean): Observable<boolean> {
   const isPhoneScreenSize$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
      screenSize()
   );

   fromEvent(window, "resize")
      .pipe(debounce(() => timer(200))) // debounce resize event so we don't cause too many rerenders
      .subscribe(() => {
         isPhoneScreenSize$.next(screenSize());
      });

   return isPhoneScreenSize$.asObservable();
}

export const isPhoneScreenSize$ = getScreenSizeObservable(isPhoneScreenSize);
export const isTabletOrSmallerScreenSize$ = getScreenSizeObservable(
   isTabletOrSmallerScreenSize
);
