import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { AuthService } from '../../_services/auth.service';
import { SearchTestService } from 'src/app/_services/internal/search-test.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NgFor, NgIf, AsyncPipe } from '@angular/common';
import { CheckboxComponent } from 'cmms-ui';
import { TabulatorFull as Tabulator } from 'tabulator-tables';
import { NgStyle } from '@angular/common';
import { CurrencyPipe } from '@angular/common';

import { CONFIG } from '../../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  withCredentials: true
};

@Component({
  selector: 'test-results',
  templateUrl: './test-results.component.html',
  styleUrls: ['./test-results.component.scss'],
  standalone: true,
  imports: [NgFor, NgIf, AsyncPipe, CheckboxComponent, NgStyle, CurrencyPipe]
})
export class TestResultsComponent implements OnInit, OnDestroy {
  @Input() public searchService: SearchTestService | undefined;
  @Input() public pipelines: any | undefined;
  @Input() public dataSources: any | undefined;

  @Output() deleteTestEvent = new EventEmitter<any>();
  @Output() resultUpdateEvent = new EventEmitter<any>();

  @ViewChild('itemsTable') itemsTable: ElementRef | undefined;

  public query: string = '';
  public items: any = [];
  public fields: any = [];

  public timings: any = {
    total_time: 0,
    timings: []
  };

  public cost: any = {
    total_cost: 0,
    cost: []
  };

  public timingTicks: any = new Array(5);

  constructor(private http: HttpClient) {
    /*
    this.http.get<any>(
      CONFIG.API_URL + '/search/pipelines',
      httpOptions
    ).subscribe({
      next: (data: any) => {
        this.pipelines = data.map((pipeline: any) => {
          pipeline.enabled = false;
          return pipeline;
        });
      },
      error: (err: any) => {
        console.error(err);
      }
    });
    */
  }

  ngOnInit(): void {
    this.searchService?.fields$.subscribe({
      next: (fields: any) => {
        this.fields = fields;
        console.debug('fields changed', fields);
        this.triggerUpdate();
      }
    });

    this.searchService?.query$.subscribe({
      next: (query: string) => {
        this.query = query;
      }
    });

    this.searchService?.meta$.subscribe({
      next: (meta: any) => {
        this.timings = this.setTimings(meta.timing ?? []);
        this.cost = this.setCost(meta.cost ?? []);
      }
    });

    this.searchService?.searchResults$.subscribe({
      next: (results: any) => {
        let itemsMatched: any = [];
        let allItems = results.map((item: any) => {
          item.selected = false;
          item.total_items = 0; // TODO: Figure out item matches based on itemGroup IDs shared between items
          return item;
        });

        // Loop through items again and count how many items are in at least 1 of the same groups based on group ID
        allItems.forEach((item: any) => {
          if (itemsMatched.includes(item.id)) {
            return;
          }

          if (!item.itemGroupValues || item.itemGroupValues.length == 0) {
            item.total_items = 0;
            item.items = [];
            return;
          }

          let subItems = allItems.filter((compareItem: any) => {
            if (compareItem.id == item.id) {
              return false;
            }

            if (!compareItem.itemGroupValues || compareItem.itemGroupValues.length == 0) {
              return false;
            }

            let groupMatches = compareItem.itemGroupValues.every((groupValue: any) => {
              return item.itemGroupValues.every((compareGroupValue: any) => {
                return groupValue.id == compareGroupValue.id;
              });
            });

            return groupMatches;
          });

          if (subItems) {
            item.items = subItems.map((subItem: any) => {
              itemsMatched.push(subItem.id);

              // Clone item, leave out items property
              let newItem = Object.assign({}, subItem);
              delete newItem.items;
              return newItem;
            });
          }

          item.total_items = subItems.length ?? 0;
        });

        this.items = allItems.filter((item: any) => {
          return !itemsMatched.includes(item.id);
        });

        console.debug(this.items);

        this.triggerUpdate();
      }
    });
  }

  async search(): Promise<any> {
    let enabledPipelineKeys = this.pipelines
      .filter((pipeline: any) => {
        return pipeline.enabled;
      })
      .map((pipeline: any) => {
        return pipeline.key;
      });

    let vendorKeys = this.dataSources
      .filter((dataSource: any) => {
        return dataSource.enabled;
      })
      .map((dataSource: any) => {
        return dataSource.key;
      });

    this.searchService?.search(this.query, {
      pipeline: enabledPipelineKeys,
      filters: {
        vendor: vendorKeys
      }
    });
  }

  togglePipeline(enabled: boolean, index: number) {
    this.pipelines[index].enabled = enabled;
  }

  toggleDataSource(enabled: boolean, index: number) {
    this.dataSources[index].enabled = enabled;
  }

  getValue(rowData: any, key: string) {
    let keys = key.split('.');
    let value = rowData;
    keys.forEach((key: string) => {
      value = value[key];
    });
    return value;
  }

  toggleItem(index: number): void {
    this.items[index].selected = !this.items[index].selected;
    this.triggerUpdate();
  }

  deleteTest(): void {
    this.deleteTestEvent.emit();
  }

  setQuery(query: any): void {
    this.searchService?.setQuery(query.target.value);
  }

  triggerUpdate(): void {
    if (!this.itemsTable) {
      return;
    }
    let enabledFields = this.enabledFields().map((field: any) => {
      return {
        title: field.label,
        field: field.key,
        formatter: field.formatter ?? null,
        maxInitialWidth: field.maxWidth ?? 200
      };
    });

    console.debug(enabledFields);

    let table = new Tabulator(this.itemsTable?.nativeElement, {
      data: this.items,
      columns: enabledFields,
      selectable: true,
      dataTree: true,
      dataTreeChildField: 'items'
    });

    table.on('rowSelected', (row) => {
      let rowData = row.getData();
      let item = this.items.find((item: any) => {
        return item.id == rowData.id;
      });
      item.selected = true;
    });

    table.on('rowDeselected', (row) => {
      let rowData = row.getData();
      let item = this.items.find((item: any) => {
        return item.id == rowData.id;
      });
      item.selected = false;
    });

    this.resultUpdateEvent.emit(this.items);
  }

  setTimings(timing: any): object {
    if (!timing) {
      return {
        total_time: 0,
        timings: []
      };
    }

    // Loop through timings and calculate difference of start from leastStart
    timing.timings.forEach((timing: any) => {
      timing.display_width = Math.max(timing.time / 40, 5);
      timing.display_start = timing.delta_start / 40;
    });

    // Each tick is 1 second, + 2 seconds on the end for padding
    let totalTicks = Math.ceil(timing.total_duration / 1000) + 2;
    totalTicks = Math.max(totalTicks, 5);

    let timingTicks = new Array(totalTicks);
    this.timingTicks = timingTicks;

    return {
      total_time: timing.total_duration,
      timings: timing.timings
    };
  }

  setCost(cost: any): object {
    if (!cost) {
      return {
        total_cost: 0,
        cost: []
      };
    }

    return {
      total_cost: cost.total,
      cost: cost.costs
    };
  }

  enabledFields(): any {
    return this.fields.filter((field: any) => {
      return field.enabled;
    });
  }

  ngOnDestroy(): void {}
}
