// import { Attribute } from "@angular/compiler";
import { NgClass } from "@angular/common";
import { Component, HostBinding, Input } from "@angular/core";

@Component({
   selector: "lim-ui-modal",
   templateUrl: "./modal.component.html",
   styleUrls: ["./modal.component.scss"],
   standalone: true,
   imports: [NgClass]
})
export class ModalComponent {
   @Input() showWatermark: boolean = false;
   @Input() completed: boolean = false;
   @Input() small: boolean = false;
   @Input() extraLarge: boolean = false;
   @Input() singleComponent: boolean = false;

   @HostBinding("class") public readonly classes = "scroll-height-inheritance";
}
