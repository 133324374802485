<div (click)="handleCheckboxToggle()" class="checkbox-container">
   <span [ngClass]="{ selected: isValueTruthy, disabled: disabled }" class="checkbox">
      <!-- [hidden] here is required to preserve the correct click handling in the dropdown.service. -->
      <!-- Using *ngIf instead will cause the icon to no longer render in the dom and the click handler will not work. -->
      <lim-ui-icon [hidden]="!isValueTruthy" icon="check" iconSize="extra-small"
   /></span>
   <div class="label-wrapper">
      <span
         class="label"
         [ngClass]="{
            'no-wrap': labelNoWrap,
            'semi-bold-label': useSemiBoldLabel,
            'warning-color': useWarningColor
         }"
         [limbleHtml]="label"
      >
      </span>
      <lim-ui-icon
         *ngIf="tooltip !== undefined"
         icon="circleQuestionRegular"
         limUiTooltip="{{ tooltip }}"
         iconSize="small"
      />
   </div>
</div>
