export {
   faAddressCard as fasAddressCard,
   faAngleDown as fasAngleDown,
   faAngleLeft as fasAngleLeft,
   faAngleRight as fasAngleRight,
   faAngleUp as fasAngleUp,
   faArrowDown as fasArrowDown,
   faArrowDownLeftAndArrowUpRightToCenter as fasArrowDownLeftAndArrowUpRightToCenter,
   faArrowLeft as fasArrowLeft,
   faArrowRight as fasArrowRight,
   faArrowRightArrowLeft as fasArrowRightArrowLeft,
   faArrowUp as fasArrowUp,
   faArrowUpRightFromSquare as fasArrowUpRightFromSquare,
   faArrowsRotate as fasArrowsRotate,
   faArrowsUpDown as fasArrowsUpDown,
   faBarsProgress as fasBarsProgress,
   faBarsSort as fasBarsSort,
   faBell as fasBell,
   faBold as fasBold,
   faBolt as fasBolt,
   faBook as fasBook,
   faBookmark as fasBookmark,
   faBullhorn as fasBullhorn,
   faCalendarDays as fasCalendarDays,
   faCamera as fasCamera,
   faChartColumn as fasChartColumn,
   faChartNetwork as fasChartNetwork,
   faCheck as fasCheck,
   faCheckCircle as fasCheckCircle,
   faChevronDown as fasChevronDown,
   faChevronLeft as fasChevronLeft,
   faChevronRight as fasChevronRight,
   faChevronUp as fasChevronUp,
   faCircle as fasCircle,
   faCircleExclamation as fasCircleExclamation,
   faCircleInfo as fasCircleInfo,
   faCircleNotch as fasCircleNotch,
   faCirclePlay as fasCirclePlay,
   faCirclePlus as fasCirclePlus,
   faCircleQuestion as fasCircleQuestion,
   faCircleUser as fasCircleUser,
   faCircleXmark as fasCircleXmark,
   faClipboardList as fasClipboardList,
   faClock as fasClock,
   faClockRotateLeft as fasClockRotateLeft,
   faCloudDownload as fasCloudDownload,
   faCloudUpload as fasCloudUpload,
   faComment as fasComment,
   faCreditCard as fasCreditCard,
   faCrosshairs as fasCrosshairs,
   faCube as fasCube,
   faCubes as fasCubes,
   faDesktop as fasDesktop,
   faDollarSign as fasDollarSign,
   faEarthAmericas as fasEarthAmericas,
   faEllipsis as fasEllipsis,
   faEllipsisVertical as fasEllipsisVertical,
   faEnvelope as fasEnvelope,
   faEye as fasEye,
   faFaceFrown as fasFaceFrown,
   faFile as fasFile,
   faFileExcel as fasFileExcel,
   faFileLines as fasFileLines,
   faFilePdf as fasFilePdf,
   faFilePowerpoint as fasFilePowerpoint,
   faFileWord as fasFileWord,
   faFileZipper as fasFileZipper,
   faFilter as fasFilter,
   faFireFlameCurved as fasFireFlameCurved,
   faFolder as fasFolder,
   faGauge as fasGauge,
   faGaugeHigh as fasGaugeHigh,
   faGear as fasGear,
   faGears as fasGears,
   faHandshake as fasHandshake,
   faHome as fasHome,
   faHouseChimney as fasHouseChimney,
   faIdCard as fasIdCard,
   faIndent as fasIndent,
   faInfinity as fasInfinity,
   faLink as fasLink,
   faLinkSlash as fasLinkSlash,
   faList as fasList,
   faListOl as fasListOl,
   faLocationArrow as fasLocationArrow,
   faLocationDot as fasLocationDot,
   faLock as fasLock,
   faLockOpen as fasLockOpen,
   faMagnifyingGlass as fasMagnifyingGlass,
   faMap as fasMap,
   faMinus as fasMinus,
   faMoneyBill as fasMoneyBill,
   faMoneyBill1 as fasMoneyBill1,
   faNotdef as fasNotdef,
   faNoteSticky as fasNoteSticky,
   faPause as fasPause,
   faPenToSquare as fasPenToSquare,
   faPencil as fasPencil,
   faPhone as fasPhone,
   faPlay as fasPlay,
   faPlus as fasPlus,
   faPlusSquare as fasPlusSquare,
   faPrint as fasPrint,
   faRetweet as fasRetweet,
   faRight as fasRight,
   faRightFromBracket as fasRightFromBracket,
   faRightLeft as fasRightLeft,
   faRightToBracket as fasRightToBracket,
   faRotate as fasRotate,
   faRotateLeft as fasRotateLeft,
   faRotateRight as fasRotateRight,
   faRss as fasRss,
   faScrewdriverWrench as fasScrewdriverWrench,
   faShuffle as fasShuffle,
   faSitemap as fasSitemap,
   faSliders as fasSliders,
   faSort as fasSort,
   faSortAsc as fasSortAsc,
   faSortDesc as fasSortDesc,
   faSquare as fasSquare,
   faSquareArrowUpRight as fasSquareArrowUpRight,
   faSquareCheck as fasSquareCheck,
   faSquarePlus as fasSquarePlus,
   faStars as fasStars,
   faStop as fasStop,
   faTableColumns as fasTableColumns,
   faTag as fasTag,
   faThumbsDown as fasThumbsDown,
   faThumbsUp as fasThumbsUp,
   faTriangleExclamation as fasTriangleExclamation,
   faTruck as fasTruck,
   faUnderline as fasUnderline,
   faUser as fasUser,
   faUserSecret as fasUserSecret,
   faUsers as fasUsers,
   faWandMagic as fasWandMagic,
   faWandMagicSparkles as fasWandMagicSparkles,
   faWifi as fasWifi,
   faWifiSlash as fasWifiSlash,
   faWrench as fasWrench,
   faXmark as fasXmark,
   faXmarkLarge as fasXmarkLarge
} from "@fortawesome/pro-solid-svg-icons";

export {
   faAddressCard as farAddressCard,
   faAngleDown as farAngleDown,
   faAngleLeft as farAngleLeft,
   faAngleRight as farAngleRight,
   faAngleUp as farAngleUp,
   faArrowsUpDownLeftRight as farArrowsUpDownLeftRight,
   faBan as farBan,
   faBarcode as farBarcode,
   faBars as farBars,
   faBell as farBell,
   faBolt as farBolt,
   faBookmark as farBookmark,
   faBroomWide as farBroomWide,
   faCalendarCheck as farCalendarCheck,
   faCalendarDays as farCalendarDays,
   faCalendarXmark as farCalendarXmark,
   faCamera as farCamera,
   faCartShopping as farCartShopping,
   faChartLine as farChartLine,
   faCheckCircle as farCheckCircle,
   faCircle as farCircle,
   faCircleInfo as farCircleInfo,
   faCircleLocationArrow as farCircleLocationArrow,
   faCirclePlus as farCirclePlus,
   faCircleQuestion as farCircleQuestion,
   faClock as farClock,
   faClone as farClone,
   faCloudArrowUp as farCloudArrowUp,
   faCloudDownload as farCloudDownload,
   faCloudUpload as farCloudUpload,
   faCodeFork as farCodeFork,
   faComment as farComment,
   faCompress as farCompress,
   faCube as farCube,
   faCubes as farCubes,
   faEdit as farEdit,
   faEnvelope as farEnvelope,
   faEye as farEye,
   faEyeSlash as farEyeSlash,
   faFile as farFile,
   faFileExcel as farFileExcel,
   faFileLines as farFileLines,
   faFilePdf as farFilePdf,
   faFilePowerpoint as farFilePowerpoint,
   faFileWord as farFileWord,
   faFileZipper as farFileZipper,
   faFloppyDisk as farFloppyDisk,
   faGear as farGear,
   faGears as farGears,
   faHandBackPointUp as farHandBackPointUp,
   faHandshake as farHandshake,
   faHashtag as farHashtag,
   faHourglassClock as farHourglassClock,
   faImage as farImage,
   faKeyboard as farKeyboard,
   faList as farList,
   faLock as farLock,
   faMagnifyingGlass as farMagnifyingGlass,
   faNoteSticky as farNoteSticky,
   faPenToSquare as farPenToSquare,
   faPencil as farPencil,
   faPhone as farPhone,
   faPlus as farPlus,
   faPrint as farPrint,
   faQrcode as farQrcode,
   faRocketLaunch as farRocketLaunchRegular,
   faRotate as farRotateRegular,
   faRss as farRss,
   faScrewdriverWrench as farScrewdriverWrench,
   faShareFromSquare as farShareFromSquare,
   faShuffle as farShuffle,
   faSliders as farSliders,
   faSquareArrowUpRight as farSquareArrowUpRight,
   faSquareCaretDown as farSquareCaretDown,
   faSquareCaretRight as farSquareCaretRight,
   faSquareCheck as farSquareCheck,
   faSquarePlus as farSquarePlus,
   faTrashCan as farTrashCan,
   faTriangleExclamation as farTriangleExclamation,
   faUnlock as farUnlock,
   faUser as farUser,
   faWarning as farWarning,
   faWrench as farWrench,
   faXmark as farXmark,
   faXmarkLarge as farXmarkLarge
} from "@fortawesome/pro-regular-svg-icons";

export {
   faCcAmex as fabCreditCardAmex,
   faCcDiscover as fabCreditCardDiscover,
   faCcMastercard as fabCreditCardMaster,
   faCcVisa as fabCreditCardVisa,
   faSuperpowers as fabSuperpowers,
   faWpforms as fabWpforms,
   faYoutube as fabYoutube
} from "@fortawesome/free-brands-svg-icons";

export { faArrowsUpDownLeftRight as falArrowsUpDownLeftRight } from "@fortawesome/pro-light-svg-icons";

// export {} from "@fortawesome/pro-light-svg-icons";
// export {} from "@fortawesome/pro-duotone-svg-icons";
