/**
 * Awaiting this function ensures that all tasks in the event loop are executed
 * before any following code is executed.
 */
export async function clearEventLoop(): Promise<void> {
   return new Promise<void>((resolve) => {
      setTimeout(() => {
         resolve();
      });
   });
}
