import type { ComponentRef, TemplateRef } from "@angular/core";
import {
   ApplicationRef,
   createComponent,
   Injectable,
   Injector,
   NgZone
} from "@angular/core";
import type { Placement } from "@floating-ui/dom";
import { computePosition, flip, offset, shift } from "@floating-ui/dom";
import { filter, fromEvent, throttleTime } from "rxjs";
import { clearEventLoop } from "../../util/clearEventLoop";
import { TooltipDestroyedError } from "./tooltip-destroyed.error";
import { TooltipComponent } from "./tooltip.component";

@Injectable({ providedIn: "root" })
export class TooltipService {
   private activeTooltip: ComponentRef<TooltipComponent> | null = null;
   private hostElement: HTMLElement | null = null;

   public constructor(
      private readonly application: ApplicationRef,
      private readonly injector: Injector,
      private readonly ngZone: NgZone
   ) {
      // The following is to catch cases where the mouseleave event does not fire as
      // anticipated on the host element, and therefore the tooltip is not destroyed as
      // expected. This seems to happen when the CPU is under heavy load.
      this.ngZone.runOutsideAngular(() => {
         fromEvent<MouseEvent>(document, "mousemove")
            .pipe(
               filter(() => this.activeTooltip !== null),
               throttleTime(500),
               filter((event) => {
                  return !this.isOverHost(event);
               })
            )
            .subscribe(() => {
               this.removeActiveTooltip();
               this.application.tick();
            });
      });
   }

   public async activateTooltip(
      tooltip: string | TemplateRef<any> | undefined,
      position: { host: HTMLElement; placement: Placement }
   ): Promise<TooltipComponent | undefined> {
      this.removeActiveTooltip();
      try {
         const tooltipComponent = await this.renderTooltip(tooltip, position);
         return tooltipComponent;
      } catch (error) {
         if (error instanceof TooltipDestroyedError) {
            // The tooltip was destroyed before it could be fully rendered.
            return undefined;
         }
         throw error;
      }
   }

   public removeActiveTooltip(): void {
      if (this.activeTooltip === null) return;
      this.activeTooltip?.destroy();
      this.activeTooltip = null;
      this.hostElement = null;
   }

   public getActiveTooltip(): ComponentRef<TooltipComponent> | null {
      return this.activeTooltip;
   }

   private isOverHost(event: MouseEvent): boolean {
      if (this.hostElement?.contains(event.target as Node | null)) return true;
      return false;
   }

   private async renderTooltip(
      tooltip: string | TemplateRef<any> | undefined,
      position: { host: HTMLElement; placement: Placement }
   ): Promise<TooltipComponent> {
      this.hostElement = position.host;
      this.activeTooltip = createComponent(TooltipComponent, {
         environmentInjector: this.application.injector,
         elementInjector: this.injector
      });

      this.activeTooltip.instance.tooltip = tooltip;
      this.activeTooltip.location.nativeElement.style.display = "block";
      this.application.attachView(this.activeTooltip.hostView);
      document.body.appendChild(this.activeTooltip.location.nativeElement);
      await this.updatePosition(position);
      if (this.activeTooltip === null) {
         throw new TooltipDestroyedError();
      }
      this.activeTooltip.instance.open();
      return this.activeTooltip.instance;
   }

   // This is a workaround for the fact that the tooltip component does not support html.
   // Stripping html out so that it doesn't always render as raw text until we find a better solution.
   private stripHTMLOutOfTooltip(
      tooltip: string | TemplateRef<any> | undefined
   ): string | TemplateRef<any> | undefined {
      let tooltipTemp = tooltip;
      if (tooltip && typeof tooltip === "string") {
         tooltipTemp = tooltip.replace(/<script[^>]*>([\S\s]*?)<\/script>/gim, "");
         tooltipTemp = tooltipTemp.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gim, "");
      }
      return tooltipTemp;
   }

   private async updatePosition(position: {
      host: HTMLElement;
      placement: Placement;
   }): Promise<void> {
      //updatePosition has a race condition with the tooltip rendering
      await clearEventLoop();
      if (this.activeTooltip === null) {
         throw new TooltipDestroyedError();
      }
      const data = await computePosition(
         position.host,
         this.activeTooltip.location.nativeElement,
         {
            placement: position.placement,
            middleware: [flip(), offset(8), shift()]
         }
      );
      if (this.activeTooltip === null) {
         throw new TooltipDestroyedError();
      }
      Object.assign(this.activeTooltip.location.nativeElement.style, {
         left: `${data.x}px`,
         top: `${data.y}px`
      });
   }
}
