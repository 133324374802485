import { CommonModule } from "@angular/common";
import type { OnChanges, OnInit } from "@angular/core";
import { Component, Input } from "@angular/core";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import type { Aliases, Colors, IconDef, Sizes } from "./icon.service";
import { IconService } from "./icon.service";

@Component({
   standalone: true,
   imports: [CommonModule, FontAwesomeModule],
   selector: "lim-ui-icon",
   templateUrl: "./icon.component.html",
   styleUrls: ["./icon.component.scss"]
})
export class IconComponent implements OnInit, OnChanges {
   @Input() icon: Aliases | undefined;
   @Input() iconSize: Sizes | undefined;
   @Input() iconColor?: Colors | undefined;
   public classNames: Array<string | undefined> = [];
   // The "notdef" icon is our placeholder icon. We use a placeholder icon so that we
   // do not require an *ngIf in the template. This improves the performance of icons
   // on changes by 90%.
   protected iconDef: IconDef = this.iconService.get("notdef");

   public constructor(private readonly iconService: IconService) {}

   public ngOnInit(): void {
      if (this.icon === undefined) {
         throw new Error("'icon' is a required input");
      }
      this.iconDef = this.iconService.get(this.icon);
      this.classNames = [this.iconSize ?? "", this.iconColor ?? ""];
   }

   public ngOnChanges(): void {
      if (this.icon === undefined) {
         throw new Error("'icon' is a required input");
      }
      this.iconDef = this.iconService.get(this.icon);
      this.classNames = [this.iconSize ?? "", this.iconColor ?? ""];
   }
}
