import type { ElementRef, Type } from "@angular/core";
import {
   ApplicationRef,
   Injectable,
   Injector,
   NgZone,
   createComponent
} from "@angular/core";
import type { Subscription } from "rxjs";
import { assert } from "../../util/assert";
import type { ModalResult } from "./modal-result";
import { LimUiModalComponent } from "./modal-wrapper.component";
import { ModalEvents } from "./modalEvents.service";
import { LimUiModalRef } from "./modalRef";

@Injectable({ providedIn: "root" })
export class LimUiModal {
   public modalCloseEvents: Subscription;
   public modalDismissEvents: Subscription;
   private activeModal: LimUiModalRef | null = null;
   private readonly modalStack: LimUiModalRef[] = [];

   public constructor(
      private readonly application: ApplicationRef,
      private readonly injector: Injector,
      private readonly modalEvents: ModalEvents,
      private readonly ngZone: NgZone
   ) {
      this.modalCloseEvents = this.modalEvents.closeEvents$.subscribe(() => {
         this.closeActiveModal();
      });

      this.modalDismissEvents = this.modalEvents.dismissEvents$.subscribe(() => {
         this.closeActiveModal();
      });

      this.ngZone.runOutsideAngular(() => {
         document.addEventListener("keyup", this.handleDocumentKeyUp.bind(this));
      });
   }

   private handleDocumentKeyUp(event): void {
      if (event.key === "Escape") {
         this.closeActiveModal();
      }
   }

   public open<
      Component,
      Result = Component extends ModalResult<infer T> ? T | undefined : any
   >(
      component: Type<Component>,
      targetEl?: ElementRef | undefined | null
   ): LimUiModalRef<Component, Result> {
      let target = document.body;
      let isEmbedded = false;

      // Load the modal into a specific element on the page rather than the body
      if (targetEl) {
         target = targetEl.nativeElement;
         target.classList.add("contains-modal");
         isEmbedded = true;
      }

      const elementInjector = Injector.create({
         providers: [
            {
               provide: LimUiModalRef<Component, Result>,
               useFactory: () => this.activeModal
            }
         ],
         parent: this.injector
      });
      const modalComponent = createComponent<LimUiModalComponent<Component>>(
         LimUiModalComponent,
         {
            environmentInjector: this.application.injector,
            elementInjector: elementInjector
         }
      );
      this.application.attachView(modalComponent.hostView);
      target.appendChild(modalComponent.location.nativeElement);
      modalComponent.setInput("isEmbedded", isEmbedded);
      const activeModal = new LimUiModalRef<Component, Result>(
         modalComponent,
         this.modalEvents,
         this.modalStack.length
      );
      this.activeModal = activeModal;
      modalComponent.instance.init(component);
      this.modalStack.push(this.activeModal);
      return activeModal;
   }

   public getActiveModal(): LimUiModalRef | null {
      return this.activeModal;
   }

   private closeActiveModal(): void {
      this.modalStack.pop()?.componentRef.destroy();

      this.activeModal =
         this.modalStack.length > 0 ? this.modalStack[this.modalStack.length - 1] : null;
   }

   public hasOpenModals(): boolean {
      if (this.activeModal || this.modalStack.length > 0) {
         return true;
      }
      return false;
   }

   public dismissAll(reason?): void {
      this.modalStack.forEach((elem: LimUiModalRef) => {
         this.modalEvents.dismiss(elem, reason);
      });
   }

   public getComponentIndexInModalStack(componentClass) {
      const firstFoundIndex = this.modalStack.findIndex((modalRef) => {
         assert(modalRef.componentRef.instance.modalContent !== undefined);
         return (
            modalRef.componentRef.instance.modalContent.componentType === componentClass
         );
      });
      return firstFoundIndex;
   }

   public checkIfModalShouldRefresh(
      modalRefBeingClosed: LimUiModalRef,
      componentClassToRefresh
   ): boolean {
      if (modalRefBeingClosed === null) {
         return false;
      }
      const thisComponentsModalStackIndex = this.getComponentIndexInModalStack(
         componentClassToRefresh
      );
      if (modalRefBeingClosed.indexInModalStack === thisComponentsModalStackIndex + 1) {
         return true;
      }
      return false;
   }
}
