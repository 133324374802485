import { NgIf } from "@angular/common";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { LimbleHtmlDirective } from "../../directives/limbleHtml.directive";
import { IconComponent } from "../../icon/icon.component";
import { TooltipDirective } from "../../tooltip/tooltip.directive";

@Component({
   selector: "lim-ui-dropdown-text-item",
   templateUrl: "./dropdown-text-item.component.html",
   styleUrls: ["./dropdown-text-item.component.scss"],
   standalone: true,
   imports: [LimbleHtmlDirective, NgIf, IconComponent, TooltipDirective]
})
export class DropdownTextItemComponent {
   /**
    * The string to display to the user for this dropdown text item.
    */
   @Input() displayString: string = "";
   /**
    * Optional output string. This is the string that is emitted by the itemSelectedEvent
    * emitter when the item is selected.
    */
   @Input() outputString?: string = undefined;
   /**
    * Optional hint string.
    */
   @Input() hintString?: string = undefined;
   /**
    * Optional boolean that specifies whether or not clicking on this item should close
    * the dropdown that this item is on.
    * Defaults to true;
    */
   @Input() closeDropdownOnClick: boolean = true;
   /**
    * Identifer to track this element's usage with analytics tools such as Heap.
    */
   @Input() dataLogLabel: string | undefined;
   /**
    * This Output emits the outputString when a user selects this item in a dropdown.
    */
   @Output() readonly itemSelectedEvent = new EventEmitter<string>();

   public handleItemClick(): void {
      if (this.outputString != undefined) {
         this.itemSelectedEvent.emit(this.outputString);
      }
   }
}
