import type { Type } from "@angular/core";
import { Injectable } from "@angular/core";
import type { Aliases as FontawesomeAliases } from "./suppliers/fontawesome/fontawesome-icon";
import { FontawesomeIcon } from "./suppliers/fontawesome/fontawesome-icon";

export type Aliases = FontawesomeAliases;
export type Sizes = SizeDescriptions;
export type BlockSizes = BlockSizeDescriptions;
export type Colors = ColorDescriptions;
/** Typical icon sizes, about the same size range as normal text would be */
type SizeDescriptions =
   | "xxs"
   | "extra-small"
   | "small"
   | "medium"
   | "medium-large"
   | "large"
   | "extra-large"
   | "xxl";

type BlockSizeDescriptions = "small" | "medium" | "medium-large";

/** Available icon colors */
type ColorDescriptions =
   | "standard"
   | "youtube-red"
   | "primary"
   | "danger"
   | "success"
   | "warn"
   | "blue"
   | "navy-blue"
   | "purple"
   | "violet"
   | "orange"
   | "white"
   | "medium-grey";
/** IconDef is a discriminated union based on the `supplier` property */
export type IconDef = FontawesomeIcon;

@Injectable({ providedIn: "root" })
export class IconService {
   private readonly iconClassMapper: { [key: string]: Type<IconDef> };

   public constructor() {
      //If we ever add a second icon supplier, we should remember to add a guard clause here
      //that checks for duplicate aliases between suppliers and throws an error if it
      //finds any
      this.iconClassMapper = Object.fromEntries(
         Object.keys(FontawesomeIcon.iconMapper).map((alias) => [alias, FontawesomeIcon])
      );
   }

   public get(alias: Aliases): IconDef {
      const IconClass = this.iconClassMapper[alias];
      if (IconClass === FontawesomeIcon) {
         return new FontawesomeIcon(alias);
      }
      throw new Error(`Icon alias '${alias}' was not found in Lim-UI`);
   }
}
