import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { NgClass, NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';

import { AuthService } from '../_services/auth.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { isMobile } from '../_util/mobile.util';

import { IconComponent } from 'cmms-ui';
import { LogoAnimation } from '../animations/logo-animation/logo-animation.component';
import { RegisterFormComponent } from '../register-form/register-form.component';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  standalone: true,
  imports: [FormsModule, NgClass, NgIf, RouterLink, LogoAnimation, IconComponent, RegisterFormComponent]
})
export class RegisterComponent implements OnInit, OnDestroy {
  public isMobile: boolean = false;
  public loggedInSubscriber?: Subscription;
  private returnRoute: string = '';

  constructor(
    private authService: AuthService,
    public router: Router,
    public route: ActivatedRoute,
    private recaptchaV3Service: ReCaptchaV3Service,
    private http: HttpClient
  ) {}

  ngOnInit(): void {
    this.isMobile = isMobile();

    this.route.params.subscribe((data) => {
      if (data.return) {
        this.returnRoute = data.return;
      }
    });

    this.loggedInSubscriber = this.authService.isLoggedIn$.subscribe((isLoggedIn) => {
      if (isLoggedIn) {
        let user = this.authService.getUser();
        this.authService.initMonitoring(user);

        if (!this.returnRoute) {
          this.router.navigate(['/']);
        } else {
          this.router.navigate([this.returnRoute]);
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.loggedInSubscriber?.unsubscribe();
  }
}
