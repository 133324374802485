<ng-template #content>
   <span *ngIf="icon" class="icon-holder">
      <lim-ui-icon [icon]="icon" [iconSize]="iconSize" [iconColor]="iconColor" />
   </span>
</ng-template>

<div *ngIf="href then anchorLayout; else buttonLayout"></div>

<ng-template #anchorLayout>
   <a
      class="lim-ui-button icon-button {{ classNames }}"
      [ngClass]="{'active': active}"
      [href]="href"
      [target]="target"
      [attr.data-log]="dataLogLabel"
   >
      <ng-container *ngTemplateOutlet="content" />
   </a>
</ng-template>

<ng-template #buttonLayout>
   <button
      class="lim-ui-button icon-button {{ classNames }}"
      [ngClass]="{'active': active, 'super-minimal':superMinimal, 'dark-hover-background':darkHoverBackground, 'use-small-mobile-tap-target':useSmallMobileTapTarget}"
      [disabled]="disabled"
      [attr.data-log]="dataLogLabel"
   >
      <ng-container *ngTemplateOutlet="content" />
   </button>
</ng-template>
