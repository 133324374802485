import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from '../../_services/auth.service';
import { SearchStatus, SearchTestService } from 'src/app/_services/internal/search-test.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TestResultsComponent } from '../test-results/test-results.component';
import { FormsModule } from '@angular/forms';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { CheckboxComponent } from 'cmms-ui';
import { Parser } from '@json2csv/plainjs';

import { CONFIG } from '../../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  withCredentials: true
};

@Component({
  selector: 'internal-a-b-testing',
  templateUrl: './internal-a-b-testing.component.html',
  styleUrls: ['./internal-a-b-testing.component.scss'],
  standalone: true,
  imports: [NgFor, NgIf, FormsModule, TestResultsComponent, CheckboxComponent, AsyncPipe]
})
export class InternalABTestingComponent implements OnInit, OnDestroy {
  public SearchStatus = SearchStatus;
  public pipelines: any = [];
  public dataSources: any = [];
  public tests: any = [];
  public queryListText: string = '';
  public settingsVisible: boolean = false;
  public bulkToolVisible: boolean = false;
  public columnSettingsVisible: boolean = false;

  public allResults: any = {};
  public fields: any = [];

  constructor(private authService: AuthService, private http: HttpClient, private searchService: SearchTestService) {
    this.searchService = searchService;

    this.searchService.fields$.subscribe({
      next: (fields: any) => {
        this.fields = fields;
      }
    });

    this.searchService.getPipelines().subscribe({
      next: (data: any) => {
        this.pipelines = data.map((pipeline: any) => {
          pipeline.enabled = false;
          return pipeline;
        });
      },
      error: (err: any) => {
        console.error(err);
      }
    });

    this.searchService.getDataSources().subscribe({
      next: (data: any) => {
        this.dataSources = data.map((dataSource: any) => {
          dataSource.enabled = true;
          return dataSource;
        });
      },
      error: (err: any) => {
        console.error(err);
      }
    });
  }

  ngOnInit(): void {}

  totalFields(): number {
    return this.fields.length;
  }

  totalEnabledFields(): number {
    return this.fields.filter((field: any) => field.enabled).length;
  }

  downloadCSVFile(rows: Array<any>, filename: string): void {
    let csvData = '';

    try {
      const parser = new Parser();
      csvData = parser.parse(rows);
    } catch (err) {
      console.error(err);
      return;
    }

    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = filename;

    document.body.appendChild(a);
    a.click();

    URL.revokeObjectURL(url);
  }

  mapFields(rowData: any, query: any): any {
    let row: any = {};

    row.selected = rowData.selected ? 1 : 0;
    row.Query = query;
    row.items = rowData.items;

    this.fields.forEach((field: any) => {
      if (field.enabled) {
        row[field.label] = rowData[field.key];
      }
    });

    return row;
  }

  export(): void {
    let allResults: Array<any> = [];
    Object.keys(this.allResults).forEach((key: any) => {
      let query = this.tests[key].searchService.getQuery();
      let results = this.allResults[key];

      results.forEach((rowData: any) => {
        let newRow = this.mapFields(rowData, query);
        let subItems = newRow.items;
        delete newRow.items;

        allResults.push(newRow);

        if (subItems) {
          subItems.forEach((item: any) => {
            allResults.push(this.mapFields(item, query));
          });
        }
      });
    });

    this.downloadCSVFile(allResults, 'test-results.csv');
  }

  queries(): string[] {
    return this.queryListText.split('\n').filter((query: string) => query.trim() !== '');
  }

  bulkCreateTests(run?: boolean): void {
    this.queries().forEach((query: string) => {
      let service = this.addTest(query);

      if (run) {
        service.search(query);
      }
    });
  }

  addTest(query?: string): SearchTestService {
    let searchService = new SearchTestService(this.http);
    searchService.setQuery(query || '');

    // clone pipelines array
    let pipelinesCopy = JSON.parse(JSON.stringify(this.pipelines));
    let dataSourcesCopy = JSON.parse(JSON.stringify(this.dataSources));

    let test = {
      searchService: searchService,
      pipelines: pipelinesCopy,
      dataSources: dataSourcesCopy,
      visible: false
    };

    this.tests.push(test);
    this.showTest(this.tests.length - 1);

    return searchService;
  }

  async search(): Promise<any> {}

  updateResults(index: number, results: any): void {
    this.allResults[index] = results;
  }

  deleteTest(index: number): void {
    this.tests.splice(index, 1);

    if (this.tests.length > 0) {
      this.showTest(this.tests.length - 1);
    }
  }

  togglePipeline(enabled: boolean, index: number) {
    this.pipelines[index].enabled = enabled;
  }

  toggleDataSource(enabled: boolean, index: number) {
    this.dataSources[index].enabled = enabled;
  }

  toggleField(enabled: boolean, key: string) {
    this.searchService.toggleFieldVisibility(key, enabled);
  }

  toggleOnlyField(index: number) {
    this.fields.forEach((field: any, i: number) => {
      field.enabled = i === index;
    });
  }

  toggleOnlyVendor(index: number) {
    this.dataSources.forEach((dataSource: any, i: number) => {
      dataSource.enabled = i === index;
    });
  }

  showTest(index: number) {
    // Hide all tests
    this.tests.forEach((test: any) => {
      test.visible = false;
    });

    // Show the test
    this.tests[index].visible = true;
  }

  isTestVisible(index: number): boolean {
    return this.tests[index].visible;
  }

  toggleSearchTypeMenu() {
    //this.searchTypeMenuVisible = !this.searchTypeMenuVisible;
  }

  ngOnDestroy(): void {}
}
