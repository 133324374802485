import type { Observable } from "rxjs";
import { BehaviorSubject } from "rxjs";

/**
 * This is a helper class that can be used in a service that needs to be a global singleton, and toggle an elements visibility.
 *
 * @remarks
 * This class supports multiple components trying to hide the same element. It guarantees that the element will only be shown when all components have called show().
 *
 * @example LimUiMobileFooter
 * See the mobileAppFooter.service.ts file for an example of how to use this class.
 *
 */
export class ToggleElement {
   private readonly componentRefList: any[] = [];
   private readonly subject: BehaviorSubject<boolean>;

   public constructor() {
      this.subject = new BehaviorSubject<boolean>(true);
   }

   /**
    * Updates the internal subject's value to true if no other component has hidden the element.
    *
    * @remarks
    * If no component has subscribed to the internal observable, then nothing will happen when this method is called.
    *
    * @remarks
    * show() does not guarantee that the element will be shown. It will only show the element if there are no other components that have hidden the element.
    *
    * @returns void
    */
   public show(): void {
      this.componentRefList.pop();
      this.subject.next(this.showable());
   }

   /**
    * Updates the internal subject's value to false if it is not already false.
    *
    * @remarks
    * If no component has subscribed to the internal observable, then nothing will happen when this method is called.
    *
    * @param componentRef - The component reference that is hiding the element.
    * @returns void
    */
   public hide(componentRef: any): void {
      this.componentRefList.push(componentRef);
      this.subject.next(this.showable());
   }

   protected showable(): boolean {
      return this.componentRefList.length === 0;
   }

   /**
    * Returns an observable that will emit a boolean value whenever the element should be shown or hidden.
    *
    * @returns Observable<boolean>
    */
   public get observable(): Observable<boolean> {
      return this.subject.asObservable();
   }
}
