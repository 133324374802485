import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgFor, NgIf } from '@angular/common';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { SearchService } from '../_services/search.service';
import { vendorLogos } from '../_util/constants';
import { isMobile } from '../_util/mobile.util';

import { MinimalIconButtonComponent } from 'cmms-ui';
import { PartSearchFieldComponent } from '../part-search-field/part-search-field.component';

@Component({
  selector: 'loading-search',
  templateUrl: './loading-search.component.html',
  styleUrls: ['./loading-search.component.scss'],
  standalone: true,
  imports: [NgIf, MinimalIconButtonComponent, NgFor, PartSearchFieldComponent]
})
export class LoadingSearch implements OnInit, OnDestroy {
  public vendors = [...vendorLogos];

  public animationDone: boolean = false;
  public animationNotStarted: boolean = true;
  public percentComplete: number = 0;
  public navigateToResults: any;
  public query: string | undefined;
  public progress = 20;
  public searchComplete: boolean = false;
  public isMobile: boolean = false;

  public percentCompleteSub?: Subscription;
  public searchCompleteSub?: Subscription;
  public searchErrorSub?: Subscription;
  public querySub?: Subscription;

  constructor(private router: Router, public searchService: SearchService) {
    this.querySub = this.searchService.query$.subscribe((query) => {
      this.query = query;
      if (this.query === '') {
        this.router.navigate(['']).then(() => {
          return;
        });
      }
    });
  }

  ngOnInit(): void {
    this.isMobile = isMobile();
    //dummy route for debugging issues on loading state
    if (this.router.url === '/loading') {
      this.shuffleVendors();
      return;
    }

    this.searchService.search(this.query);
    //shuffle vendors names
    this.shuffleVendors();

    //simulate loading progress
    const bar = document.getElementById('bar');
    const vendorProgress = setInterval(() => {
      bar?.style.setProperty('--progress', `${this.progress}%`);
      if (this.progress < 80) {
        this.progress += 20;
      }
      //slight increases after 80% to simulate progress bar update to atleast 85% before it navigates to results
      else if (this.progress === 80 || this.progress === 85) {
        this.progress += 5;
      } else {
        clearInterval(vendorProgress);
      }
    }, 1000);

    const rotateAnimation = document.querySelector('.rotate');
    if (rotateAnimation) {
      rotateAnimation.addEventListener('animationstart', () => {
        this.animationNotStarted = false;
      });
    }
    this.percentCompleteSub = this.searchService.percentComplete$.subscribe((percentComplete) => {
      if (percentComplete > 0 && percentComplete < 100) {
        this.percentComplete = percentComplete;
      }
    });
    this.searchCompleteSub = this.searchService.searchComplete$.subscribe((searchComplete) => {
      if (searchComplete) {
        this.progress = 90;
        bar?.style.setProperty('--progress', `${this.progress}%`);
        this.searchComplete = true;
      }
    });

    //wait for the simulated progress bar to reach 85% or the real time loading to reach roughly about 85% before navigating to results
    //if the search is complete before either of them, navigate to results (usually happens with cached queries)

    this.navigateToResults = setInterval(() => {
      if (this.percentComplete >= 80 && this.percentComplete <= 85) {
        this.router.navigate(['/results']);
      } else if (this.progress === 85) {
        this.router.navigate(['/results']);
      } else if (this.searchComplete === true) {
        this.router.navigate(['/results']);
      }
    }, 2000);

    this.searchErrorSub = this.searchService.error$.subscribe((error) => {
      if (error?.status === 'system' || error?.status === 'timeout') {
        this.router.navigate(['/error']);
      }
    });
  }

  shuffleVendors() {
    return this.vendors.sort(() => Math.random() - 0.5);
  }

  ngOnDestroy(): void {
    this.percentCompleteSub?.unsubscribe();
    this.searchCompleteSub?.unsubscribe();
    this.searchErrorSub?.unsubscribe();
    this.querySub?.unsubscribe();
    clearInterval(this.navigateToResults);
  }
}
