<ng-container *ngIf="!isMobile; else responsiveItemCard">
  <div class="item" [attr.data-item-id]="item.id" (click)="showDetails(item)" [class]="{ loading: isLoading }">
    <div class="label-container" *ngIf="showLabelControls">
      <button
        (click)="setLabel('vote', 1); $event.stopPropagation()"
        [class]="{ selected: searchService.getItemLabel(item.id, 'vote') == 1 }"
      >
        <img src="assets/images/thumbs-up-icon.svg" />
      </button>
      <button
        (click)="setLabel('vote', -1); $event.stopPropagation()"
        [class]="{ selected: searchService.getItemLabel(item.id, 'vote') == -1 }"
      >
        <img src="assets/images/thumbs-down-icon.svg" />
      </button>
    </div>
    <div class="item-content">
      <search-ui-badge *ngIf="!!match" [icon]="faSparkles" [label]="match.itemGroupValue" />
      <div class="item-image-container">
        <img
          [src]="
            item.mainImageUrl && item.mainImageUrl.length === 0 ? '/assets/images/placeholder-img.png' : item.mainImageUrl
          "
          onerror="this.onerror=null; this.src='/assets/images/placeholder-img.png'"
          class="item-image"
          alt="Image Coming Soon"
        />
      </div>
      <div class="inline-display">
        <div class="item-vendor-logo-all">
          <img [src]="item['vendor']['logoUrl']" *ngIf="!item['hideVendorLogo']" />
        </div>
        <div
          [ngClass]="{ 'other-vendors': true, 'multiple-vendors': vendorLogoKeys.length > 0 }"
          *ngIf="match && match.vendors.length > 0 && match.vendors.length <= 3"
          [limUiTooltip]="vendorTooltip"
        >
          <img
            *ngFor="let vendorKey of vendorLogoKeys"
            [src]="'assets/images/vendor-icons/icon-' + vendorKey + '.png'"
            class="vendor-icon"
          />
        </div>
        <div
          [ngClass]="{ 'other-vendors': true, 'multiple-vendors': vendorLogoKeys.length > 0 }"
          *ngIf="match && match.vendors.length > 3"
          [limUiTooltip]="vendorTooltip"
        >
          <img
            *ngFor="let vendorKey of vendorLogoKeys.slice(0, 2)"
            [src]="'assets/images/vendor-icons/icon-' + vendorKey + '.png'"
            class="vendor-icon"
          />
          <div class="more-vendors">{{ vendorLogoKeys.length - 2 }}+</div>
        </div>
      </div>
      <div class="title-container">
        <div class="title" id="title">
          <span [limUiTooltip]="itemTitleClamped ? item.title : ''" [placement]="'bottom'">{{ item.title }}</span>
        </div>
      </div>
      <div class="price-container">
        <div class="item-price">
          {{ item.price | currency }}
        </div>
        <div class="vendor">from {{ item.vendor.name }}</div>
      </div>
      <shipping-details [item]="item"></shipping-details>
      <div class="visit-site">
        <button
          (click)="openItemSite(item['productUrl']); $event.stopPropagation()"
          [ngClass]="view === 'irrelevant-results' ? 'irrelevant-results-btn' : 'main-page-btn'"
        >
          <span class="visit-site-badge">Visit Site</span>
        </button>
        <div class="comparison" *ngIf="match && match.count > 0">
          <span *ngIf="match && match.vendors.length == 1">
            Compare {{ match.count }} option{{ match.count > 1 ? 's' : '' }}
          </span>
          <span *ngIf="match && match.vendors.length > 1"> Compare {{ match.vendors.length }} vendors </span>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-template #responsiveItemCard>
  <div
    class="responsive-item-card"
    [attr.data-item-id]="item.id"
    (click)="showDetails(item)"
    [class]="{ loading: isLoading }"
  >
    <search-ui-badge *ngIf="!!match" [icon]="faSparkles" [label]="match.itemGroupValue" />
    <div class="inline-display">
      <div class="item-vendor-logo-all">
        <img [src]="item['vendor']['logoUrl']" *ngIf="!item['hideVendorLogo']" />
      </div>
      <div
        [ngClass]="{ 'other-vendors': true, 'multiple-vendors': vendorLogoKeys.length > 0 }"
        *ngIf="match && match.vendors.length > 0 && match.vendors.length <= 3"
        [limUiTooltip]="vendorTooltip"
      >
        <img
          *ngFor="let vendorKey of vendorLogoKeys"
          [src]="'assets/images/vendor-icons/icon-' + vendorKey + '.png'"
          class="vendor-icon"
        />
      </div>
      <div class="other-vendors" *ngIf="match && match.vendors.length > 3" [limUiTooltip]="vendorTooltip">
        <img
          *ngFor="let vendorKey of vendorLogoKeys.slice(0, 2)"
          [src]="'assets/images/vendor-icons/icon-' + vendorKey + '.png'"
          class="vendor-icon"
        />
        <div class="more-vendors">{{ match.vendors.length - 2 }}+</div>
      </div>
    </div>
    <div class="responsive-details">
      <div class="item-details">
        <div class="item-image-container">
          <img
            [src]="
              item.mainImageUrl && item.mainImageUrl.length === 0 ? '/assets/images/placeholder-img.png' : item.mainImageUrl
            "
            class="item-image"
            alt="Image Coming Soon"
            onerror="this.onerror=null; this.src='/assets/images/placeholder-img.png'"
          />
        </div>
        <div class="item-attributes">
          <div class="title">{{ item.title }}</div>
          <div>
            <div class="price-container">
              <div class="item-price">
                {{ item.price | currency }}
              </div>
              <div class="vendor">from {{ item.source }}</div>
            </div>
            <shipping-details [item]="item"></shipping-details>
          </div>
        </div>
      </div>
      <div class="visit-site">
        <button
          (click)="openItemSite(item['productUrl']); $event.stopPropagation()"
          [ngClass]="view === 'irrelevant-results' ? 'irrelevant-results-btn' : 'main-page-btn'"
        >
          <span class="visit-site-badge">Visit Site</span>
        </button>
        <div class="comparison" *ngIf="match && match.count > 0">
          <span *ngIf="match.vendors.length == 0"> Compare {{ match.count }} option{{ match.count > 1 ? 's' : '' }} </span>
          <span *ngIf="match.vendors.length > 1"> Compare {{ match.vendors.length }} vendors </span>
        </div>
      </div>
    </div>
  </div>
</ng-template>
