<lim-ui-dropdown [widthFitContent]="widthFitContent">
   <div class="dropdown-button" button>
      {{ label }}
      <lim-ui-icon icon="angleDown" iconSize="small" />
   </div>

   <ng-content header select="[header]"></ng-content>
   <ng-content menu select="[menu]"></ng-content>
   <ng-content footer select="[footer]"></ng-content>
</lim-ui-dropdown>
