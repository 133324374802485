import { NgClass, NgIf } from "@angular/common";
import { Component, Input } from "@angular/core";
import { LimbleHtmlDirective } from "../../directives/limbleHtml.directive";
import { IconComponent } from "../../icon/icon.component";
import { DropdownComponent } from "../dropdown.component";

@Component({
   selector: "lim-ui-form-dropdown-input",
   templateUrl: "./form-dropdown-input.component.html",
   styleUrls: ["./form-dropdown-input.component.scss"],
   standalone: true,
   imports: [DropdownComponent, NgClass, LimbleHtmlDirective, IconComponent, NgIf]
})
export class FormDropdownInputComponent {
   @Input() inputString: string = "";
   @Input() disabled?: boolean = false;
   @Input() hasError?: boolean = false;
   @Input() required?: boolean = false;
}
