<div
   class="limble-dropdown-item flex-spacing clickable-dropdown-item"
   (click)="handleItemClick()"
   [attr.close-dropdown-on-click]="closeDropdownOnClick ? true : null"
   [attr.data-log]="dataLogLabel"
>
   <span [limbleHtml]="displayString"></span>
   <lim-ui-icon
      *ngIf="hintString !== undefined"
      icon="circleQuestionRegular"
      limUiTooltip="{{ hintString }}"
      iconSize="small"
   />
   <ng-content></ng-content>
</div>
